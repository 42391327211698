import React from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { isMoments } from '../../../constants';
import Button from '../buttons/Button';

const frontendUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : isMoments
    ? 'https://veramoments.app'
    : 'https://veracommunity.app';
interface CheckoutFormProps {
  clientSecret: string;
  disabled: boolean;
  setDisabled: any;
  onSubmit: any;
  redirectQueryParams?: string;
  intentType?: string;
  containerClassName?: string;
  buttonClassName?: string;
}

export default function CheckoutForm({
  clientSecret,
  onSubmit,
  disabled,
  setDisabled,
  redirectQueryParams='',
  intentType='payment',
  containerClassName='',
  buttonClassName='',
}: CheckoutFormProps): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setDisabled(true);
    try {
      await onSubmit();
    } catch (error) {
      setDisabled(false);
      return;
    }
    let error;
    if(intentType === 'setup') {
      ({error} = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${frontendUrl}/payment/completion/${redirectQueryParams}`,
        },
      }));
    } else if (intentType === 'setupChange'){
      ({error} = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${frontendUrl}/subscriptions/manage/`,
        },
      }))
    }
    else {
      ({error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${frontendUrl}/payment/completion/${redirectQueryParams}`,
        },
      }));
    }
    if(error){
      if (error.type === 'card_error' || error.type === 'validation_error') {
        toast.error(error.message!);
      } else {
        toast.error(t('toast.error.unexpected'));
      }
    }
    setDisabled(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
    loader: 'always',
  } as StripePaymentElementOptions;

  return (
    <div data-testid="checkout-form" id="payment-form relative">
      <PaymentElement
        data-testid="stripe-form"
        className={twMerge('w-full my-4 mb-8 rounded-[20px] p-4', containerClassName)}
        options={paymentElementOptions}
      />
      <div className="justify-end flex">
        <Button variant="primary" className={buttonClassName} disabled={disabled} onClick={handleSubmit}>
          {intentType === 'setupChange' ? t('page.payment.confirm') : t('page.payment.payAndConfirm')}
        </Button>
      </div>
    </div>
  );
}
