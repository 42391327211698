import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { COLOR_PRIMARY_300, COLOR_PRIMARY_900, COLOR_SECONDARY, COLOR_SECONDARY_200 } from '../../../constants';
import { Event, EventParticipationAnswer } from '../../../types/event';
import { decimalToPercentageString } from '../../../utils/stringUtils';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';

type AnswerPercentageGraphProps = {
  events: Event[];
};

const COLOR_MAP = {
  [EventParticipationAnswer.YES]: COLOR_PRIMARY_300,
  [EventParticipationAnswer.NO]: COLOR_SECONDARY,
  [EventParticipationAnswer.MAYBE]: COLOR_PRIMARY_900,
  [EventParticipationAnswer.PENDING]: COLOR_SECONDARY_200,
};

export default function AnswerPercentageGraph({ events }: AnswerPercentageGraphProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.eventDetails.answerTitle' });
  const isMd = useIsScreenSize('md');

  const answers = events
    .flatMap((event) => event.participants.map((p) => p.answer || EventParticipationAnswer.PENDING));

  const answerData = _.chain(answers)
    .countBy(x => x)
    .map((v, k) => ({
      value: (v / answers.length),
      color: COLOR_MAP[k as EventParticipationAnswer],
      label: t(k.toLowerCase()),
    }))
    .value();

  return (
    <ResponsiveContainer minHeight={isMd ? 300 : undefined}>
      <PieChart margin={{ top: 20, bottom: 10 }}>
        <Pie
          data={answerData}
          dataKey="value"
          nameKey="label"
          innerRadius="80%"
          outerRadius="100%"
          paddingAngle={2}
          cornerRadius={5}
        >
          {answerData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip formatter={decimalToPercentageString} contentStyle={{ borderRadius: '10px' }} />
        <Legend
          content={RenderCustomLegend}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

type RenderCustomLegendProps = {
  payload?: { value: string, color?: string }[];
};

function RenderCustomLegend(props: RenderCustomLegendProps): JSX.Element {
  const { payload } = props;

  return (
    <ul className="flex flex-wrap justify-center items-center mt-4">
      {payload?.map((entry, index) => (
        <li className="px-1" key={`item-${index}`}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <svg width="14" height="14" style={{ marginRight: 4 }}>
              <circle cx="7" cy="7" r="6" fill={entry.color} stroke="#fff" strokeWidth="2" />
            </svg>
            {entry.value}
          </span>
        </li>
      ))}
    </ul>
  );
}
