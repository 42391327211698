import React, { useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { FaArrowLeft, FaArrowRight, FaUserSecret, FaCircleInfo } from 'react-icons/fa6';
import { HiUserGroup } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import ApplicationLayout from '../../components/layouts/ApplicationLayout/ApplicationLayout';
import Button from '../../components/buttons/Button';
import StepIndicator from '../../components/misc/StepIndicator';
import { isCommunity, isMoments } from '../../../constants';
import { BusinessType, CommunityType, MomentType } from '../../../types/business';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { CreateBusinessForm, CreateUserForm } from '../../../types/forms';
import ImageInput from '../../components/forms/ImageInput';
import NameInput from '../../components/forms/profile/NameInput';
import TermsAndConditions from '../../components/misc/TermsAndConditions';
import authService from '../../../services/authService';
import useAuth from '../../../hooks/account/useAuth';
import { businessTypeIcons } from '../../../utils/iconUtils';
import IconBadge from '../../components/icons/IconBadge';
import businessService from '../../../services/businessService';
import VeraPanel from '../../components/misc/VeraPanel';
import { setOnboarding, wipeSelectedBusiness } from '../../../redux/slices/applicationSlice';
import { store } from '../../../redux/store';
import useBusinesses from '../../../hooks/business/useBusinesses';
import { OnboardingStep } from '../../../types/misc';

export default function OnboardingPage(): JSX.Element {
  const [step, setStep] = useState<number>(0);
  const [business, setBusiness] = useState<CreateBusinessForm>({ businessDescription: '' });

  const auth = useAuth();
  const location = useLocation();
  const [params] = useSearchParams();
  const type = params.get('type') as BusinessType;

  const handleStep = (diff: 1 | -1) => setStep(step + diff);

  useLayoutEffect((): void => {
    if (location.state) {
      const data: CreateBusinessForm = location.state;

      setBusiness({ ...data, businessDescription: data.businessDescription || '' });
      setStep(isCommunity ? 3 : 2);
    } else if (type) {
      setBusiness((prevData: any) => ({ ...prevData, businessType: type }));
      setStep(2);
    }
  }, []);

  useEffect(() => {
    store.dispatch(wipeSelectedBusiness());
  }, []);

  const steps = [
    <IntroductionView setStep={handleStep} />,
    <BusinessTypeView
      selected={business.businessType}
      setSelected={(selected) => setBusiness({ ...business, businessType: selected })}
      setStep={handleStep}
    />,
    isCommunity && (
      <ConnectedMembersView business={business} setBusiness={setBusiness} setStep={handleStep} />
    ),
    <BusinessInfoStep business={business} setBusiness={setBusiness} setStep={handleStep} />,
    !auth && <LoginRegisterView business={business} setStep={handleStep} />,
  ].filter(Boolean);

  return (
    <ApplicationLayout collapsed>
      <div className="h-full w-full flex flex-col">
        <StepIndicator
          steps={steps.length}
          currentStep={step}
          onStepChange={setStep}
          className="mb-6"
        />
        {steps[step]}
      </div>
    </ApplicationLayout>
  );
}

interface ViewProps {
  setStep: (step: 1 | -1) => void;
}

function IntroductionView({ setStep }: ViewProps) {
  const { t } = useBusinessTranslation();
  return (
    <div className="flex h-full w-full flex-col">
      <div className="lg:w-[70%] w-full h-full mx-auto my-auto justify-center items-center gap-2 flex flex-col md:flex-row">
        <img
          alt="Vera Phone"
          src={`${process.env.PUBLIC_URL}/assets/figures/phone_hi.svg`}
          className="w-[50%] "
        />
        <div className="w-full flex flex-col gap-1">
          <h1 className="text-3xl font-serif font-semibold">
            {t('page.onboarding.introduction.title')}
          </h1>
          <p> {t('page.onboarding.introduction.subtitle')}</p>
        </div>
      </div>
      <div className="flex w-full justify-end gap-2 py-4 mt-4 border-t border-primary-300">
        <Button variant="primary" onClick={() => setStep(1)}>
          {t('general.continue')}
          <FaArrowRight className="w-5 h-5" />
        </Button>
      </div>
    </div>
  );
}

type BusinessTypeViewProps = {
  selected: BusinessType | undefined;
  setSelected: (type: BusinessType | undefined) => void;
} & ViewProps;

function BusinessTypeView({ selected, setSelected, setStep }: BusinessTypeViewProps) {
  const { t } = useBusinessTranslation();
  const typeOptions = (
    isMoments
      ? Object.keys(MomentType)
      : Object.keys(
          _.omit(CommunityType, [CommunityType.FAMILY, CommunityType.FRIENDS, CommunityType.WORK]),
        )
  ) as BusinessType[];

  return (
    <div className="flex flex-1 h-full w-full flex-col gap-4">
      <VeraPanel
        title={t('page.onboarding.type.title')}
        subtitle={t('page.onboarding.type.subtitle')}>
        {typeOptions.map((type) => (
          <div
            key={type}
            onClick={() => setSelected(type === selected ? undefined : type)}
            className={classNames(
              'rounded-[8px] cursor-pointer transition-all justify-between border-secondary-200 border px-4 py-2 w-full flex',
              { 'bg-secondary-200': selected === type },
            )}>
            <div className="flex gap-2">
              <IconBadge icon={businessTypeIcons[type]} className="rounded-[6px] p-1" />
              <div>
                <h3 className="font-serif text-lg">
                  {t(`page.onboarding.type.${type.toLowerCase().split('_')[0]}.name`)}
                </h3>
                <p> {t(`page.onboarding.type.${type.toLowerCase().split('_')[0]}.description`)}</p>
              </div>
            </div>
            <input type="checkbox" checked={selected === type} />
          </div>
        ))}
      </VeraPanel>
      <div className="flex w-full justify-end gap-2 py-4 mt-4 border-t border-primary-300">
        <Button variant="tertiary" onClick={() => setStep(-1)}>
          <FaArrowLeft className="w-5 h-5" />
          {t('general.back')}
        </Button>
        <Button variant="primary" disabled={!selected} onClick={() => setStep(1)}>
          {t('general.continue')}
          <FaArrowRight className="w-5 h-5" />
        </Button>
      </div>
    </div>
  );
}

type ConnectedMembersViewProps = {
  business: CreateBusinessForm;
  setBusiness: (business: CreateBusinessForm) => void;
} & ViewProps;

function ConnectedMembersView({ business, setBusiness, setStep }: ConnectedMembersViewProps) {
  const { t } = useBusinessTranslation();

  useLayoutEffect(() => setBusiness({ ...business, connectedMembers: true }), []);

  return (
    <div className="flex flex-1 h-full w-full flex-col gap-4">
      <VeraPanel
        title={t('page.onboarding.connectedMembers.title')}
        subtitle={t('page.onboarding.connectedMembers.subtitle')}
        className="gap-4">
        <div className="flex flex-row gap-4 flex-1">
          <div
            onClick={() => setBusiness({ ...business, connectedMembers: true })}
            className={classNames(
              'flex p-4 flex-col gap-2 border-2 w-full items-center justify-center rounded-[20px] cursor-pointer',
              {
                'border-primary-300 bg-secondary-200 text-secondary-50': business.connectedMembers,
                'border-secondary-200 text-secondary-200': !business.connectedMembers,
              },
            )}>
            <HiUserGroup className="w-12 h-12" />
            <p className="font-semibold">{t('page.onboarding.connectedMembers.yes')}</p>
          </div>
          <div
            onClick={() => setBusiness({ ...business, connectedMembers: false })}
            className={classNames(
              'flex p-4 flex-col gap-2 border-2 w-full items-center justify-center rounded-[20px] cursor-pointer',
              {
                'border-primary-300 bg-secondary-200 text-secondary-50': !business.connectedMembers,
                'border-secondary-200 text-secondary-200': business.connectedMembers,
              },
            )}>
            <FaUserSecret className="w-12 h-12" />
            <p className="font-semibold">{t('page.onboarding.connectedMembers.no')}</p>
          </div>
        </div>
        <div className="flex gap-2 max-w-lg w-full bg-primary-300 rounded-[20px] mx-auto p-2">
          <FaCircleInfo className="w-4 h-4 flex-shrink-0 items-start justify-start mt-1 text-secondary-50" />
          <p className="text-sm">
            {t(
              `page.onboarding.connectedMembers.${
                business.connectedMembers ? 'connected' : 'notConnected'
              }`,
            )}
          </p>
        </div>
      </VeraPanel>
      <div className="flex w-full justify-end gap-2 py-4 mt-4 border-t border-primary-300">
        <Button variant="tertiary" onClick={() => setStep(-1)}>
          <FaArrowLeft className="w-5 h-5" />
          {t('general.back')}
        </Button>
        <Button
          variant="primary"
          disabled={business.connectedMembers === undefined}
          onClick={() => setStep(1)}>
          {t('general.continue')}
          <FaArrowRight className="w-5 h-5" />
        </Button>
      </div>
    </div>
  );
}

type BusinessInfoStepProps = {
  business: CreateBusinessForm;
  setBusiness: (business: CreateBusinessForm) => void;
} & ViewProps;

function BusinessInfoStep({ business, setBusiness, setStep }: BusinessInfoStepProps) {
  const { t } = useBusinessTranslation();
  const auth = useAuth();
  const businesses = useBusinesses();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    await businessService.createBusiness(business);
    navigate('/contacts');
    if (!businesses?.length) dispatch(setOnboarding(OnboardingStep.INIT));
  };

  const handleNext = () => {
    if (business.communityLink && !business.communityLink.toLowerCase().includes('whatsapp')) {
      toast.error(t('toast.error.invalidWhatsapp'));
      return;
    }
    setStep(1);
  };

  return (
    <div className="flex flex-1 h-full w-full flex-col gap-4">
      <VeraPanel
        title={t(
          `page.onboarding.info.${business?.businessType?.toLowerCase().split('_')[0]}.title`,
        )}
        subtitle={t(
          `page.onboarding.info.${business?.businessType?.toLowerCase().split('_')[0]}.subtitle`,
        )}>
        <div className="relative w-full flex flex-col gap-1">
          <p className="ml-4">{t(`page.onboarding.info.name`)}</p>
          <input
            type="text"
            className="w-full"
            value={business.businessName}
            onChange={(e) => setBusiness({ ...business, businessName: e.target.value })}
          />
          <p className=" right-4 absolute top-0">{t('general.mandatory')}</p>
        </div>
        {isCommunity && (
          <>
            <div className="w-full flex flex-col gap-1">
              <p className="ml-4">{t(`page.onboarding.info.description`)}</p>
              <textarea
                className="w-full resize-none"
                value={business.businessDescription}
                onChange={(e) => setBusiness({ ...business, businessDescription: e.target.value })}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <p className="ml-4">{t(`general.whatsappLink`)}</p>
              <input
                type="text"
                className="w-full"
                value={business.communityLink ?? ''}
                onChange={(e) => setBusiness({ ...business, communityLink: e.target.value })}
              />
            </div>
          </>
        )}
      </VeraPanel>
      <div className="flex w-full justify-end gap-2 py-4 mt-4 border-t border-primary-300">
        <Button variant="tertiary" onClick={() => setStep(-1)}>
          <FaArrowLeft className="w-5 h-5" />
          {t('general.back')}
        </Button>
        {!auth ? (
          <Button variant="primary" disabled={!business.businessName} onClick={handleNext}>
            {t('general.continue')}
            <FaArrowRight className="w-5 h-5" />
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSubmit} disabled={!business.businessName}>
            {t('page.onboarding.confirmation.submit')}
          </Button>
        )}
      </div>
    </div>
  );
}

type LoginRegisterViewProps = {
  business: CreateBusinessForm;
} & ViewProps;

function LoginRegisterView({ business, setStep }: LoginRegisterViewProps) {
  const { t } = useBusinessTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [view, setView] = useState<'LOGIN' | 'REGISTER'>('REGISTER');
  const [formData, setFormData] = useState<CreateUserForm>({});
  const [terms, setTerms] = useState<boolean>(false);

  const handleRegister = async (): Promise<void> => {
    if (!formData.firstName || !formData.lastName || !formData.email) {
      toast.error(t('toast.error.allFields'));
      return;
    }
    if (!terms) {
      toast.error(t('toast.error.terms'));
      return;
    }
    await authService.instantRegister(formData, business);
    navigate('/contacts');
    dispatch(setOnboarding(OnboardingStep.INIT));
  };

  const handleLogin = async (): Promise<void> => {
    if (!formData.email) {
      toast.error(t('toast.error.allFields'));
      return;
    }
    await authService.generateMagicLink(formData.email, { business });
    toast.success(t('toast.success.sentMagicLink'));
  };

  return (
    <div className="flex flex-1 h-full w-full flex-col gap-4">
      <VeraPanel
        title={t(`page.onboarding.confirmation.${view.toLowerCase()}.title`)}
        subtitle={t(`page.onboarding.confirmation.${view.toLowerCase()}.subtitle`)}>
        <div className=" flex gap-1 text-sm ml-4 justify-end">
          {view === 'REGISTER' ? (
            <>
              {t('auth.alreadyHaveAccount')}
              <Button className="underline py-0 px-0" onClick={(): void => setView('LOGIN')}>
                {t('auth.login')}
              </Button>
            </>
          ) : (
            <>
              {t('auth.noAccountYet')}
              <Button className="underline py-0 px-0" onClick={(): void => setView('REGISTER')}>
                {t('auth.register')}
              </Button>
            </>
          )}
        </div>
        <div className="flex flex-col gap-4 w-full">
          {view === 'REGISTER' && (
            <NameInput
              alias={`${formData.firstName || ''}+${formData.lastName || ''}`}
              setAlias={(alias: string) => {
                const [firstName, lastName] = alias.split('+');
                setFormData({ ...formData, firstName, lastName });
              }}
            />
          )}
          <div className="w-full">
            <p className="pr-2 font-medium mb-1">Email</p>
            <input
              type="text"
              placeholder="Email"
              className="w-full"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </div>
          {view === 'REGISTER' && <TermsAndConditions state={terms} setState={setTerms} />}
          {view === 'LOGIN' && (
            <div className=" flex justify-end ">
              <Button variant="primary" onClick={handleLogin}>
                {t('auth.sendLink')}
              </Button>
            </div>
          )}
        </div>
      </VeraPanel>
      <div className="flex w-full justify-end gap-2 py-4 mt-4 border-t border-primary-300">
        <Button variant="tertiary" onClick={() => setStep(-1)}>
          <FaArrowLeft className="w-5 h-5" />
          {t('general.back')}
        </Button>
        <Button disabled={view === 'LOGIN'} variant="primary" onClick={handleRegister}>
          {t('page.onboarding.confirmation.submit')}
        </Button>
      </div>
    </div>
  );
}

interface ChoosePictureViewProps {
  picture: string | undefined;
  setPicture: (picture: string | undefined) => void;
}

function ChoosePictureView({ picture, setPicture }: ChoosePictureViewProps) {
  return (
    <div className="flex flex-1 h-full w-full flex-col gap-4">
      <div className="lg:w-[50%] w-full justify-center items-center gap-2 flex flex-col md:flex-row">
        <img
          alt="Vera Phone"
          src={`${process.env.PUBLIC_URL}/assets/figures/phone_hi.svg`}
          className="w-[50%] "
        />
        <div className="w-full flex flex-col gap-1">
          <h1 className="text-3xl font-serif font-semibold">Kies jouw type community</h1>
          <p>
            Bereid je voor op een geweldig moment! Wat wil je organiseren? Kies uit de opties
            hieronder en start je planning.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:w-[60%] mx-auto w-full items-center">
        <div className={`${picture ? 'w-fit' : 'w-full'} flex flex-col gap-1`}>
          <p className="">Upload a picture</p>
          <ImageInput
            image={picture}
            setImage={setPicture}
            className={`h-64 lg:h-72 ${picture ? 'w-fit' : 'w-full'}`}
            iconClassName="w-14 h-14"
          />
        </div>
      </div>
    </div>
  );
}
