import React from 'react';
import { IoWarning } from 'react-icons/io5';
import { Admin } from '../../../types/business';
import { adminRoleToString, capitalizeFirstLetter } from '../../../utils/stringUtils';
import AdminActions from '../dropdown/AdminActions';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import ProfilePicture from '../misc/ProfilePicture';
import IconBadge from '../icons/IconBadge';

interface AdminsTableProps {
  data: Admin[];
}

export default function AdminsTable({ data }: AdminsTableProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const headers = [t('general.name'), t('general.role'), ''];

  return (
    <table
      className="min-w-full border-y overflow-x-scroll border-primary-300 divide-y divide-primary-300 z-0">
      <thead className="z-0">
        <tr>
          {headers.map((header: string) => (
            <th key={header} className={thStyle}>
              {!header ? null : (
                <div>
                  <span>{header}</span>
                </div>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="z-0">
        {data.map((row) => (
          <tr key={row.adminId} className="group h-full">
            <td className={`min-w-fit flex flex-shrink-0 ${tdStyle}`}>
              <div className="flex items-center flex-shrink-0">
                <ProfilePicture
                  picture={row.picture}
                  alias={row.display.replace(' ', '+')}
                  className="h-10 w-10 text-lg mr-2"
                />
                <p className="block">{row.display.split('+').join(' ') || '-'}</p>
                {row.pending && (
                  <div className="h-fit w-fit relative">
                    <IconBadge icon={IoWarning} className="h-7 w-7 ml-1 peer" />
                    <div className="bg-primary-900 rounded-lg absolute opacity-0 peer-hover:opacity-100 duration-200 transition-all text-secondary-50 p-1 px-2 -bottom-7 -right-20 text-xs">
                      {t('general.pending')}
                    </div>
                  </div>
                )}
              </div>
            </td>

            <td className={`pr-4 md:pr-0 ${tdStyle}`}>
              {capitalizeFirstLetter(adminRoleToString(row.role))}
            </td>

            <td className={tdStyle}>
              <div className="flex flex-1 h-full items-center justify-center">
                <AdminActions admin={row} />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const thStyle =
  'py-3 pl-2 text-left text-sm font-medium text-primary-900 tracking-wider';

const tdStyle = 'pl-2 py-4 text-sm font-medium text-primary-900 whitespace-nowrap flex-justify-center';
