import { RootState } from '../redux/reducers';
import { store } from '../redux/store';
import {
  CreateEventModel,
  Event,
  EventParticipationAnswer,
  RecurrenceEditType,
  UpdateEventModel,
} from '../types/event';
import httpService from './httpService';
import {
  createEvent as createEventRedux,
  setEvents,
  deleteEvent as deleteEventRedux,
  updateEvent as updateEventRedux,
  setAttendance as setAttendanceRedux,
} from '../redux/slices/eventSlice';
import { addDateToEvent } from '../redux/transformers';

function createEvent(event: CreateEventModel): Promise<Event> {
  return httpService
    .post<Event>(`/business/events`, { ...event, participantsIds: [], inviteAll: true })
    .then(({ data }) => {
      const transformed = addDateToEvent(data);
      const { selectedBusiness } = (store.getState() as RootState).application;
      store.dispatch(createEventRedux({ businessId: selectedBusiness!, event: transformed }));
      return transformed;
    });
}

function getUpcomingEvents(): Promise<Event[]> {
  return httpService.get<Event[]>('/business/events/upcoming').then(({ data }) => {
    const events = data.map(addDateToEvent);
    const { selectedBusiness } = (store.getState() as RootState).application;
    store.dispatch(setEvents({ businessId: selectedBusiness!, events }));
    return events;
  });
}

function getEventAnalytics(from: Date, to: Date): Promise<Event[]> {
  return httpService.get<Event[]>('/business/events/analytics', { params: { from, to } }).then(({ data }) => {
    const events = data.map(addDateToEvent);
    const { selectedBusiness } = (store.getState() as RootState).application;
    store.dispatch(setEvents({ businessId: selectedBusiness!, events }));
    return events;
  });
}

async function prependPastEvents(): Promise<Event[]> {
  return httpService.get<Event[]>('/business/events/past')
    .then(({ data }) => {
      const pastEvents = data.map(addDateToEvent);
      const { selectedBusiness } = (store.getState() as RootState).application;
      const upcomingEvents = (store.getState() as RootState).events.eventMap[selectedBusiness!];
      const merged = [...pastEvents, ...upcomingEvents];
      store.dispatch(setEvents({ businessId: selectedBusiness!, events: merged }));
      return merged;
    });
}

function deleteEvent(
  eventId: number,
  deleteRecurrences: RecurrenceEditType = RecurrenceEditType.SINGLE,
): Promise<void> {
  return httpService.delete(`/events/${eventId}/${deleteRecurrences}`).then(() => {
    const { selectedBusiness } = (store.getState() as RootState).application;
    store.dispatch(deleteEventRedux({ businessId: selectedBusiness!, eventId }));
  });
}

function updateEvent(eventId: number, event: UpdateEventModel): Promise<Event> {
  return httpService.put<Event>(`/events/${eventId}`, event).then(({ data }) => {
    const transformed = addDateToEvent(data);
    const { selectedBusiness } = (store.getState() as RootState).application;
    store.dispatch(updateEventRedux({ businessId: selectedBusiness!, event: transformed }));
    return transformed;
  });
}

function setAttendance(
  eventId: number,
  participantId: number,
  answer: EventParticipationAnswer,
): Promise<void> {
  return httpService.post(`/events/${eventId}/attendance/${participantId}`, { answer }).then(() => {
    const { selectedBusiness } = (store.getState() as RootState).application;
    store.dispatch(setAttendanceRedux({ businessId: selectedBusiness!, participantId, answer }));
  });
}

function remindUserForEvent(eventId: number, participantId: number): Promise<void> {
  return httpService.post(`/events/${eventId}/remind/${participantId}`).then(() => {});
}

function deleteParticipant(eventId: number, participantId: number): Promise<Event> {
  const {
    application: { selectedBusiness },
    events: { eventMap },
  } = store.getState() as RootState;

  const participantIds = eventMap[selectedBusiness!]
    ?.find((e) => e.id === eventId)
    ?.participants.map((p) => p.id)
    .filter((id) => id !== participantId);

  if (!participantIds) throw new Error('Event Not Found');
  return updateParticipants(eventId, participantIds);
}

function updateParticipants(eventId: number, participantsIds: number[]): Promise<Event> {
  return httpService
    .put(`/events/${eventId}/participants`, { participantsIds })
    .then(() => fetchEvent(eventId));
}

function fetchEvent(eventId: number): Promise<Event> {
  return httpService.get<Event>(`/events/${eventId}`).then(({ data }) => {
    const transformed = addDateToEvent(data);
    const { selectedBusiness } = (store.getState() as RootState).application;
    store.dispatch(updateEventRedux({ businessId: selectedBusiness!, event: transformed }));
    return transformed;
  });
}

function pickDateOptions(eventId: number, dateOptionIds: number[]): Promise<Event[]> {
  return httpService
    .post(`/events/${eventId}/date-options/convert/bulk`, { dateOptionIds })
    .then(getUpcomingEvents);
}

export default {
  createEvent,
  getUpcomingEvents,
  prependPastEvents,
  getEventAnalytics,
  deleteEvent,
  updateEvent,
  setAttendance,
  remindUserForEvent,
  updateParticipants,
  fetchEvent,
  deleteParticipant,
  pickDateOptions,
};
