import { ProfileDataType, ProfileField } from '../types/Profile';

export const profileDataTypeOrder = [
  ProfileDataType.EMAIL,
  ProfileDataType.BUSINESSNAME,
  ProfileDataType.PHONENUMBER,
  ProfileDataType.ADDRESS,
  ProfileDataType.BIRTHDATE,
  ProfileDataType.SOCIAL,
];

export const sortProfileFields = (profileFields: ProfileField[]): ProfileField[] => {
  return profileFields.sort((a, b): number => {
    const aIndex = profileDataTypeOrder.indexOf(a.dataType);
    const bIndex = profileDataTypeOrder.indexOf(b.dataType);
    return aIndex - bIndex;
  });
};

export const sortProfileDataTypes = (profileDataTypes: ProfileDataType[]): ProfileDataType[] => {
  return profileDataTypes.sort((a, b): number => {
    const aIndex = profileDataTypeOrder.indexOf(a);
    const bIndex = profileDataTypeOrder.indexOf(b);
    return aIndex - bIndex;
  });
};
