import React from 'react';
import { HiTrash } from 'react-icons/hi';
import { toast } from 'react-toastify';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { businessTypeToString } from '../../../utils/stringUtils';
import QuestionCircle from '../../components/misc/QuestionCircle';
import DeleteBusinessModal from '../../components/modals/DeleteBusinessModal';
import businessService from '../../../services/businessService';
import { AdminRole } from '../../../types/business';
import communityService from '../../../services/communityService';
import { isCommunity } from '../../../constants';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import Button from '../../components/buttons/Button';
import ImageInput from '../../components/forms/ImageInput';
import useBusinessEffect from '../../../hooks/effects/useBusinessEffect';

export default function GeneralSettingsPage(): JSX.Element {
  const business = useSelectedBusiness();
  const { t } = useBusinessTranslation();

  const [deleteDialog, setDeleteDialog] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(business?.name!);
  const [communityLink, setCommunityLink] = React.useState<string | undefined>(
    business?.communityLink,
  );
  const [description, setDescription] = React.useState<string>(business?.description!);
  const [profileImageUrl, setProfileImageUrl] = React.useState<string | undefined>(
    business?.picture!,
  );
  const [profileImageAction, setProfileImageAction] = React.useState<'UPLOAD' | 'DELETE'>();

  useBusinessEffect(() => {
    setName(business?.name!);
    setDescription(business?.description!);
    setProfileImageUrl(business?.picture!);
    setCommunityLink(business?.communityLink);
  }, []);

  const handleSave = async (): Promise<void> => {
    try {
      if (isCommunity && communityLink !== business?.communityLink) {
        if (communityLink !== '' && !communityLink?.toLowerCase().includes('whatsapp')) {
          toast.error(t('toast.error.invalidWhatsapp'));
          return;
        }
        await communityService.changeLink(communityLink);
      }
      await businessService.updateBusiness(name, description);
      if (profileImageAction === 'UPLOAD' && profileImageUrl) {
        await businessService.setProfileImage(
          await fetch(profileImageUrl!).then((res) => res.blob() as Promise<File>),
        );
      } else if (profileImageAction === 'DELETE') {
        await businessService.deleteProfileImage();
      }

      toast.success(t('toast.success.businessSaved'));
      setProfileImageAction(undefined);
    } catch (error) {
      toast.error(t('toast.error.imageWrong'));
      setProfileImageAction(undefined);
    }
  };

  return (
    <>
      <div>
        {/* Header */}
        <div className="flex flex-col border-b border-primary-300 pb-8">
          <h1 className="font-semibold font-serif text-3xl mb-2">
            {t('page.settings.general.title')}
          </h1>
          <p className="lg:w-1/2">
            {t('page.settings.subtitle', { type: businessTypeToString(business?.type!) })}
          </p>
        </div>

        <div className="flex gap-6 h-fit flex-col lg:flex-row border-b border-primary-300 mb-8">
          {/* Image */}
          <div className="flex flex-col lg:py-8 pt-8 w-full">
            <div className="flex items-center">
              <h2 className="font-medium mt-1 text-xl mb-2 mr-1">
                {t('page.settings.general.image.title')}
              </h2>
              <QuestionCircle className="w-[300px] -left-10 top-7">
                {t('page.settings.general.image.explanation')}{' '}
              </QuestionCircle>
            </div>
            <ImageInput
              className="h-64 lg:h-72"
              iconClassName="w-14 h-14"
              image={profileImageUrl}
              setImage={setProfileImageUrl}
              setImageAction={setProfileImageAction}
            />
          </div>

          {/* General info */}
          <div className="flex flex-col border-panel-border py-8 w-full">
            <h2 className="font-medium mt-1 text-xl mb-2">
              {t('page.settings.general.generalData.title')}
            </h2>

            <div className="flex flex-col py-4">
              <label htmlFor="Profile name" className="pl-1 pb-1 pr-2 -mt-4 font-medium">
                {t('general.name')}
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                data-testid="alias-input"
                type="text"
                placeholder={t('page.settings.general.generalData.namePlaceholder')}
                readOnly={false}
              />
            </div>

            {isCommunity && (
              <div className="flex flex-col py-4">
                <label htmlFor="Profile description" className="pl-1 pb-1 pr-2 -mt-4 font-medium">
                  {t('general.description')}
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  data-testid="description-input"
                  className="h-28 "
                  placeholder={t('page.settings.general.generalData.descriptionPlaceholder')}
                  readOnly={false}
                />
              </div>
            )}

            {isCommunity && (
              <div className="flex flex-col">
                <div className="flex items-center gap-1">
                  <label className="font-medium">
                    {t('page.settings.general.generalData.communityLink')}
                  </label>
                  <QuestionCircle className="w-[400px]">
                    {t('page.settings.general.generalData.communityLinkExplanation')}
                  </QuestionCircle>
                </div>
                <input
                  value={communityLink || ''}
                  onChange={(e) => setCommunityLink(e.target.value)}
                  type="text"
                  placeholder={t('page.settings.general.generalData.communityLinkPlaceholder')}
                  readOnly={false}
                />
              </div>
            )}

            <div className="w-full flex flex-1 h-full justify-end items-end mt-10">
              <Button
                variant="primary"
                onClick={handleSave}
                className="px-8"
                disabled={
                  (description === business?.description &&
                    communityLink === business?.communityLink &&
                    name === business?.name &&
                    !profileImageAction) ||
                  !name
                }>
                {' '}
                {t('general.save')}
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full justify-end flex ">
          {business?.role === AdminRole.OWNER && (
            <Button
              onClick={() => {
                setDeleteDialog(true);
              }}
              className="underline">
              {t('page.settings.general.delete')}
              <HiTrash className="h-5 w-5 ml-1 " />
            </Button>
          )}
        </div>
      </div>
      <DeleteBusinessModal open={deleteDialog} setOpen={setDeleteDialog} />
    </>
  );
}
