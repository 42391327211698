import React from 'react';
import { twMerge } from 'tailwind-merge';

interface VeraPanelProps {
  image?: string;
  title: string;
  subtitle: string;
  children: React.ReactNode;
  className?: string;
}
export default function VeraPanel({
  image = `${process.env.PUBLIC_URL}/assets/figures/phone_hi.svg`,
  title,
  subtitle,
  children,
  className = '',
}: VeraPanelProps): JSX.Element {
  return (
    <>
      <div className="lg:w-[60%] w-full justify-center items-center gap-2 flex flex-col md:flex-row">
        <img alt="Vera" src={image} className="w-[28%] lg:-mr-5 " />
        <div className="w-full flex flex-col gap-1 lg:mt-10">
          <h1 className="text-3xl font-serif font-semibold">{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
      <div
        className={twMerge('flex flex-col h-full w-full max-w-[720px] mx-auto gap-2', className)}>
        {children}
      </div>
    </>
  );
}
