import { API_URL } from '../constants';
import {
  Connection,
  ConnectionType,
  addConnections,
  deleteConnections,
  setContactsToInviteSent,
  updateConnection,
  updateNote,
} from '../redux/slices/connectionsSlice';
import { store } from '../redux/store';
import { ProfileField } from '../types/Profile';
import httpService from './httpService';
import { CustomFieldResponses } from './model/inviteService.model';
import { GenerateFriendlyIdResponse, LocalContactInfo } from './model/localContactService.model';

const localContactService = {
  createLocalContacts: async (contactInfos: LocalContactInfo[]): Promise<Connection[]> => {
    if (!contactInfos.length) return Promise.resolve([]);
    const { data } = await httpService.post<Connection[]>('/business/local-contact', contactInfos);
    store.dispatch(addConnections(data));
    return data;
  },

  deleteLocalContacts: async (localContactIds: string[]): Promise<void> => {
    if (!localContactIds.length) return;
    await httpService
      .delete('/business/local-contact/', { data: { localContactIds } })
      .then(() =>
        store.dispatch(
          deleteConnections({ ids: localContactIds, type: ConnectionType.LOCAL_CONTACT }),
        ),
      );
  },

  fetchLocalContact: async (localContactId: string): Promise<LocalContactInfo> => {
    const { data } = await httpService.get(`/business/local-contact/${localContactId}`);
    return data;
  },

  updateLocalContact: async (
    localContactId: string,
    firstName: string,
    lastName: string,
    note: string,
    receivedData: ProfileField[],
    customFieldResponses: CustomFieldResponses,
  ): Promise<void> => {
    const { data } = await httpService.put(`/business/local-contact/${localContactId}`, {
      firstName,
      lastName,
      note,
      receivedData,
      customFieldResponses,
    });
    store.dispatch(updateConnection(data));
  },

  uploadProfilePicture: async (localContactId: string, image: File): Promise<void> => {
    const formData = new FormData();
    formData.append('avatar', image);
    const { data } = await httpService.post(
      `/business/image/local-contact/${localContactId}`,
      formData,
    );
    store.dispatch(updateConnection(data));
  },

  getProfilePictureUrl: async (localContactId: string): Promise<string> => {
    return httpService
      .get(`/business/image/local-contact/${localContactId}`)
      .then(({ data }): string => {
        return data.path;
      });
  },

  getProfilePictureAsFile: async (localContactId: string): Promise<File> => {
    return httpService
      .get(`/business/image/local-contact/${localContactId}`)
      .then(async ({ data }): Promise<File> => {
        const response = await httpService.get<Blob>(`${API_URL}/${data.path}`, {
          responseType: 'blob',
        });
        return new File([response.data], 'profilePicture');
      });
  },

  deleteProfilePicture: async (localContactId: string): Promise<void> => {
    await httpService.delete(`/business/image/local-contact/${localContactId}`);
  },

  updateNote: async (id: string, note: string): Promise<void> => {
    return httpService.put(`/business/local-contact/note`, { note, id }).then(() => {
      store.dispatch(updateNote({ id, type: ConnectionType.LOCAL_CONTACT, note }));
    });
  },

  inviteContacts: async (localContactIds: string[]): Promise<void> => {
    await httpService.post('/business/local-contact/invite', { localContactIds }).then(() => {
      store.dispatch(setContactsToInviteSent(localContactIds));
    });
  },

  generateFriendlyId(localContactId: number): Promise<string> {
    return httpService
      .post<GenerateFriendlyIdResponse>('/business/local-contact/friendly-id', { localContactId })
      .then(({ data: { friendlyId } }) => friendlyId);
  },
};

export default localContactService;
