import { setCommunityLink } from '../redux/slices/applicationSlice';
import { store } from '../redux/store';
import httpService from './httpService';

function changeLink(link: string): Promise<void> {
  return httpService.put<void>('/business/community/link', { link }).then((): void => {
    store.dispatch(setCommunityLink(link));
  });
}

const communityService = {
  changeLink,
};

export default communityService;
