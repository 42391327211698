export enum ProfileDataType {
  EMAIL = 'EMAIL',
  PHONENUMBER = 'PHONENUMBER',
  ADDRESS = 'ADDRESS',
  BIRTHDATE = 'BIRTHDATE',
  SOCIAL = 'SOCIAL',
  BUSINESSNAME = 'BUSINESSNAME',
  MOMENT = 'MOMENT',
}

export type ProfileField = {
  id?: string;
  label: string | Label;
  description: string;
  dataType: ProfileDataType;
  email?: string;
  primary?: boolean;
  businessName?: string;
  address?: Address;
  phoneNumber?: {
    prefix: string;
    suffix: string;
    countryCode: string;
  };
  birthDate?: string;
  moment?: {
    name: string;
    momentType: MomentType;
    date: string;
  };
  social?: SocialMedia;
};

export type Address = {
  street: string;
  houseNumber: string;
  houseNumberAddition: string;
  city: string;
  postCode: string;
  country: string;
  countryCode: string;
};

export interface SocialMedia {
  socialType: SocialType;
  link: string;
}
export enum MomentType {
  WEDDING = 'WEDDING',
  CHILDBIRTH = 'CHILDBIRTH',
}

export enum SocialType {
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  X = 'X',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
}

export enum Label {
  GENERAL = 'GENERAL',
  PRIVATE = 'PRIVATE',
  WORK = 'WORK',
  MASKED_EMAIL = 'MASKED_EMAIL',
}
