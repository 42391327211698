import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { ProfileDataType, ProfileField } from '../../../../types/Profile';
import useAuth from '../../../../hooks/account/useAuth';
import userService from '../../../../services/userService';
import Social from './Social';
import Moment from './Moment';
import Field from './Field';
import ProfileModal from '../../modals/ProfileModal';
import useProfileFields from '../../../../hooks/account/useProfileFields';
import Button from '../../buttons/Button';
import { BusinessInvitePropertyType } from '../../../../services/model/inviteService.model';

interface UserDataFormProps {
  selectedData: ProfileField[];
  setSelectedData: (selectedData: ProfileField[]) => void;
  nonRequestedFields?: BusinessInvitePropertyType[];
  mandatoryFields?: BusinessInvitePropertyType[];
  onePerDataType?: boolean;
  hasSelectAll?: boolean;
  onlyBirthDate?: boolean;
  fields?: ProfileField[];
  fetchData?: boolean;
}

export default function UserDataForm({
  selectedData,
  setSelectedData,
  nonRequestedFields = [],
  mandatoryFields = [],
  onePerDataType = false,
  hasSelectAll = false,
  onlyBirthDate = false,
  fields,
  fetchData = true,
}: UserDataFormProps): JSX.Element {
  const userFields = useProfileFields();
  const profileFields = fields || userFields;

  const { t } = useTranslation();
  const auth = useAuth();

  const nonRequestedFilter = (field: ProfileField): boolean =>
    !nonRequestedFields.includes(field.dataType);

  const emails = profileFields
    .filter((x) => x.dataType === ProfileDataType.EMAIL)
    .filter(nonRequestedFilter);
  const phoneNumbers = profileFields
    .filter((x) => x.dataType === ProfileDataType.PHONENUMBER)
    .filter(nonRequestedFilter);
  const addresses = profileFields
    .filter((x) => x.dataType === ProfileDataType.ADDRESS)
    .filter(nonRequestedFilter);
  const businessname = profileFields
    .filter(
      (x) =>
        !nonRequestedFields.includes(ProfileDataType.BUSINESSNAME) &&
        x.dataType === ProfileDataType.BUSINESSNAME,
    )
    .filter(nonRequestedFilter);
  const moments = profileFields
    .filter(
      (x) =>
        (!onlyBirthDate && x.dataType === ProfileDataType.MOMENT) ||
        x.dataType === ProfileDataType.BIRTHDATE,
    )
    .filter(nonRequestedFilter);
  const socialmedia = profileFields.filter(
    (x) =>
      x.dataType === ProfileDataType.SOCIAL &&
      x.social &&
      !nonRequestedFields.includes(x.social?.socialType),
  );

  const hasBusinessName =
    (!nonRequestedFields.includes(ProfileDataType.BUSINESSNAME) && !fields) || businessname.length !== 0;
  const hasEmail =
    (!nonRequestedFields.includes(ProfileDataType.EMAIL) && !fields) || emails.length !== 0;
  const hasPhoneNumber =
    (!nonRequestedFields.includes(ProfileDataType.PHONENUMBER) && !fields) ||
    phoneNumbers.length !== 0;
  const hasAddress =
    (!nonRequestedFields.includes(ProfileDataType.ADDRESS) && !fields) || addresses.length !== 0;
  const hasMoment =
    (!nonRequestedFields.includes(ProfileDataType.BIRTHDATE) && !fields) ||
    (onlyBirthDate && !nonRequestedFields.includes(ProfileDataType.BIRTHDATE) && (!fields || moments.length !== 0)) ||
    moments.length !== 0;

  const [modalDataType, setModalDataType] = useState<ProfileDataType>(ProfileDataType.EMAIL);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!auth || fields || !fetchData) return;
    userService.getUserData();
  }, [auth?.email, fetchData]);

  const handleSelectAll = (): void => {
    if (selectedData.length === profileFields.length) setSelectedData([]);
    else setSelectedData(profileFields);
  };

  const handleClick = (active: boolean, field: ProfileField): void => {
    if (active) {
      setSelectedData(selectedData.filter((x) => x.id !== field.id));
    } else if (onePerDataType && field.dataType !== ProfileDataType.SOCIAL)
      setSelectedData([...selectedData.filter((x) => x.dataType !== field.dataType), field]);
    else setSelectedData([...selectedData, field]);
  };

  const handleProfileAdd = (type: ProfileDataType): void => {
    setModalDataType(type);
    setModalOpen(true);
  };

  const renderField = (f: ProfileField): JSX.Element => (
    <Field
      field={f}
      handleClick={handleClick}
      active={selectedData.map((x) => x.id!).includes(f.id!)}
    />
  );

  const renderSocial = (field: ProfileField): JSX.Element => (
    <>
      {field.social && (
        <Social
          field={field}
          mandatory={mandatoryFields.includes(field.social.socialType!)}
          handleClick={handleClick}
          active={selectedData.map((x) => x.id!).includes(field.id!)}
        />
      )}
    </>
  );

  const renderMoment = (field: ProfileField) => (
    <Moment
      field={field}
      handleClick={handleClick}
      active={selectedData.map((x) => x.id!).includes(field.id!)}
    />
  );

  const addStar = (type: ProfileDataType): string => {
    if (mandatoryFields.includes(type)) return '*';
    return '';
  };

  return (
    <div className="w-full flex flex-col">
      <div className="text-sm">
        {hasSelectAll && (
          <div className="flex justify-end ">
            <div
              className="w-fit cursor-pointer gap-2 items-center flex mr-2"
              onClick={handleSelectAll}>
              <p className="text-primary">{t('form.userData.selectAll')}</p>
              <input type="checkbox" checked={selectedData.length === profileFields.length} />
            </div>
          </div>
        )}

        {hasBusinessName && (
          <div
            className={
              hasAddress || hasPhoneNumber || hasMoment || hasEmail
                ? 'border-secondary-200 border-b pb-4 mb-4'
                : ''
            }>
            <h2 className="font-medium text-lg">{t('form.userData.label.businessName')}{addStar(ProfileDataType.BUSINESSNAME)}</h2>
            <div className=" my-1 gap-2 flex flex-col">{businessname.map(renderField)}</div>
            {!fields
              && profileFields.filter((x) => x.dataType === ProfileDataType.BUSINESSNAME).length === 0
              && (
                <Button
                  variant="tertiary"
                  onClick={() => handleProfileAdd(ProfileDataType.BUSINESSNAME)}
                  className="mt-4">
                  <FaPlus className="inline-block mr-2 h-5 w-5 stroke-2" />
                  {t('form.userData.button.businessName')}
                </Button>
              )}
          </div>
        )}

        {hasEmail && (
          <div
            className={
              hasAddress || hasPhoneNumber || hasMoment
                ? 'border-secondary-200 border-b pb-4 mb-4'
                : ''
            }>
            <h2 className="font-medium text-lg">
              {t('form.userData.label.email')} {addStar(ProfileDataType.EMAIL)}
            </h2>
            <div className=" my-1 gap-2 flex flex-col">{emails.map(renderField)}</div>
            {!fields && (
              <Button
                variant="tertiary"
                onClick={() => handleProfileAdd(ProfileDataType.EMAIL)}
                className="mt-4">
                <FaPlus className="inline-block mr-2 h-5 w-5 stroke-2" />
                {t('form.userData.button.email')}
              </Button>
            )}
          </div>
        )}

        {hasPhoneNumber && (
          <div className={hasAddress || hasMoment ? 'border-secondary-200 border-b pb-4 mb-4' : ''}>
            <h2 className="font-medium text-lg">
              {t('form.userData.label.phoneNumber')} {addStar(ProfileDataType.PHONENUMBER)}
            </h2>
            <div className=" my-1 gap-2 flex flex-col">{phoneNumbers.map(renderField)}</div>
            {!fields && (
              <Button
                variant="tertiary"
                onClick={() => handleProfileAdd(ProfileDataType.PHONENUMBER)}
                className=" mt-4">
                <FaPlus className="inline-block mr-2 h-5 w-5 stroke-2" />
                {t('form.userData.button.phoneNumber')}
              </Button>
            )}
          </div>
        )}
        {hasAddress && (
          <div className={hasMoment ? 'border-secondary-200 border-b pb-4 mb-4' : ''}>
            <h2 className="font-medium text-lg">
              {t('form.userData.label.address')} {addStar(ProfileDataType.ADDRESS)}
            </h2>
            <div className=" my-1 gap-2 flex flex-col">{addresses.map(renderField)}</div>
            {!fields && (
              <Button
                variant="tertiary"
                onClick={() => handleProfileAdd(ProfileDataType.ADDRESS)}
                className=" mt-4">
                <FaPlus className="inline-block mr-2 h-5 w-5 stroke-2" />
                {t('form.userData.button.address')}
              </Button>
            )}
          </div>
        )}
        {hasMoment && (
          <div className={socialmedia.length > 0 ? 'border-secondary-200 border-b pb-4 mb-4' : ''}>
            <h2 className="font-medium text-lg">
              {onlyBirthDate ? t('form.userData.label.birthDate') : t('form.userData.label.moment')}
              {addStar(ProfileDataType.BIRTHDATE)}
            </h2>
            {moments.map(renderMoment)}
            {onlyBirthDate &&
              profileFields.filter((x) => x.dataType === ProfileDataType.BIRTHDATE).length === 0 &&
              !fields && (
                <Button
                  variant="tertiary"
                  onClick={() => handleProfileAdd(ProfileDataType.BIRTHDATE)}
                  className="mt-4">
                  <FaPlus className="inline-block mr-2 h-5 w-5 stroke-2" />
                  {t('form.userData.button.birthDate')}
                </Button>
              )}
          </div>
        )}
        {socialmedia.length > 0 && (
          <div>
            <h2 className="font-medium text-lg">{t('form.userData.label.social')}</h2>
            {socialmedia.map(renderSocial)}
          </div>
        )}
      </div>
      <ProfileModal
        modalType="CREATE"
        type={modalDataType}
        open={modalOpen}
        setOpen={setModalOpen}
      />
    </div>
  );
}
