import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightFill } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import authService from '../../../services/authService';
import PanelWithImageLayout from '../../components/layouts/PanelWithImageLayout';

/*
 * The magic login page
 * @returns {JSX.Element} - The magic login page.
 */
export default function MagicLoginPage(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const invalidMagicLinkError = t('toast.error.invalidMagicLink');

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('link');
    if (token === null) {
        toast.error(invalidMagicLinkError);
        navigate('/login');
        return <></>;
    }

    const redirectAfterLogin = useCallback(async () => {
        try {
            await authService.loginMagicLink(token);
        } catch (error) {
            toast.error(invalidMagicLinkError);
            navigate('/login');
        }

        const businessName = queryParams.get('businessName');
        const businessType = queryParams.get('businessType');
        const businessDescription = queryParams.get('businessDescription');
        const connectedMembers = queryParams.get('connectedMembers') === 'true';
        const communityLink = queryParams.get('communityLink');

        if (businessName && businessType) {
            navigate('/onboarding', {
                state: { businessName, businessType, businessDescription, connectedMembers, communityLink },
            });
        } else {
            navigate('/');
        }
    }, [token, navigate, location]);

    return (
        <PanelWithImageLayout image={`${process.env.PUBLIC_URL}/assets/misc/connect.svg`} border>
            <PanelWithImageLayout.Header>
                <h1 className="text-2xl text-primary-900 font-serif font-medium">{t('page.magicLogin.continue')}</h1>
            </PanelWithImageLayout.Header>
            <div className="flex flex-1 flex-col w-full h-full gap-4">
                <div className="flex gap-1 bg-secondary-200 px-2 rounded-full">
                    <button
                        type="button"
                        className={` px-4 py-2 text-sm font-bold rounded-b-md flex items-center p-1 w-full`}
                        onClick={() => {
                            redirectAfterLogin();
                        }}
                    >
                        <RiArrowRightFill className="w-5 h-5 mr-2 icon" />
                        <span className="whitespace-nowrap inline">{t('general.continue')}</span>
                    </button>
                </div>
            </div>
        </PanelWithImageLayout>
    );
}
