import React from 'react';
import { twMerge } from 'tailwind-merge';

interface LabelProps {
  label: string;
  children: React.ReactNode;
  className?: string;
}
export default function Labeled({ label, children, className }: LabelProps) {
  return (
    <div className={twMerge('flex flex-col w-full gap-1', className)}>
      <label className="font-medium">{label}</label>
      {children}
    </div>
  );
}
