import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbArrowRight, TbCreditCard, TbHeart, TbHeartBroken } from 'react-icons/tb';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { BiInfoCircle } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { SiMastercard, SiPaypal, SiSepa } from 'react-icons/si';
import { FaCcAmex, FaCcVisa } from 'react-icons/fa6';
import Button from '../../components/buttons/Button';
import useActiveSubscription from '../../../hooks/business/useActiveSubscription';
import CheckoutForm from '../../components/forms/CheckoutForm';
import ModalLayout from '../../components/layouts/ModalLayout';
import {
  getUsageLimit,
  memberCountPrices,
  subscriptionColors,
} from '../../../utils/subscriptionUtils';
import { InvoiceData, PaymentDetails } from '../../../types/payments';
import {
  GridTable,
  GridTableHeader,
  GridTableHelperText,
} from '../../components/tables/GridPaymentTable';
import { COLOR_SECONDARY_50, stripeAppearance } from '../../../constants';
import { SubscriptionType } from '../../../types/misc';
import InvoicesTable from '../../components/tables/InvoicesTable';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { formatDate } from '../../../utils/stringUtils';
import businessService from '../../../services/businessService';
import { subscriptionService } from '../../../services/subscriptionService';
import { useNonAdminRedirect } from '../../../hooks/account/useNonAdminRedirect';

const STRIPE_KEY: string = process.env.REACT_APP_STRIPE_KEY as string;

const stripe = loadStripe(STRIPE_KEY);
export default function ManageSubscriptionPage(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });
  const { t: subscriptionT } = useTranslation('', { keyPrefix: 'page.settings.subscriptions' });
  const activeSubscription = useActiveSubscription();
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [editPaymentModalOpen, setEditPaymentModalOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails | null>();
  const [lastInvoices, setLastInvoices] = useState<InvoiceData[]>();
  const [upcomingInvoice, setUpcomingInvoice] = useState<InvoiceData>();
  const [userCount, setUserCount] = useState(0);
  const [limitUsers, setLimitUsers] = useState(0);
  const [endDate, setEndDate] = useState<Date | null>();
  const selectedBusiness = useSelectedBusiness();
  const navigate = useNavigate();

  useEffect(() => {
    businessService.fetchBusinesses();
  }, []);

  useNonAdminRedirect();

  useEffect(() => {
    subscriptionService
      .getPaymentDetails()
      .then(setPaymentDetails)
      .catch(() => {
        setPaymentDetails(null);
      });
    subscriptionService.getLastInvoices(3).then(setLastInvoices);
    subscriptionService.getUpcomingInvoice().then(setUpcomingInvoice);
    subscriptionService.getSubscriptionUsage().then((count) => {
      setUserCount(count);
      setLimitUsers(getUsageLimit(activeSubscription, count).max);
    });
    subscriptionService
      .getEndDate()
      .then((x: number) => setEndDate(new Date(x * 1000)))
      .catch(() => setEndDate(null));
  }, [activeSubscription]);

  useEffect(() => {
    if (editPaymentModalOpen && clientSecret === undefined) {
      subscriptionService.createSetupIntent().then((x) => setClientSecret(x.clientSecret));
    }
  }, [editPaymentModalOpen]);

  const options = {
    appearance: { ...stripeAppearance },
    clientSecret,
  } as StripeElementsOptions;

  return (
    <>
      <div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-4 items-center">
            <h2 className="font-serif text-2xl">{t('title')}</h2>
            <span className="text-lg font-semibold px-4 py-0.5 border-2 border-primary-900 rounded-xl uppercase">
              {subscriptionT(`${activeSubscription.toLowerCase()}.title`)}
            </span>
          </div>
          <p className="text-gray-500 w-1/3">{t('subtitle')}</p>
        </div>
      </div>
      <div className="flex items-center justify-between bg-secondary-200 bg-opacity-50 px-8 py-3 my-4 rounded-xl">
        <div className="flex flex-col">
          <p className="font-semibold">{t('yourSubscription')}</p>
          <h3 className="text-2xl font-semibold flex items-center gap-2 mt-2">
            {subscriptionT(`${activeSubscription.toLowerCase()}.title`)}
            {selectedBusiness?.subscription?.willRenew === false && (
              <>
                <TbArrowRight />
                {subscriptionT(`free.title`)}
              </>
            )}
          </h3>
          <p className="text-gray-500 text-xs">{t('subtitle')}</p>
        </div>
        <div className="flex flex-col gap-2 items-end justify-evenly">
          <div className="flex items-center gap-1">
            {userCount}/{limitUsers === Infinity ? '∞' : limitUsers} {t('membersAvailable')}
            <div className="group relative">
              <BiInfoCircle />
              <ExplanationPopup limitUsers={limitUsers} />
            </div>
          </div>
          <Button variant="primary" onClick={() => navigate('/settings/subscriptions')}>
            {t('changeSubscription')}
          </Button>
        </div>
      </div>
      <InvoicesTable invoices={lastInvoices || []} loading={lastInvoices === undefined} />
      <Button
        onClick={() => navigate('/subscriptions/invoices')}
        variant="primary"
        className="self-end mr-8 mt-2">
        {t('showAllInvoices')}
      </Button>
      <GridTable>
        <GridTableHeader>
          <div>{t('nextPaymentDue')}</div>
          <div>{t('total')}</div>
          <div>{t('status')}</div>
        </GridTableHeader>
        <GridTableHelperText>{t('nextPaymentHelper')}</GridTableHelperText>
        {upcomingInvoice && (
          <div className="ml-8 col-span-3 grid grid-cols-3 items-center border-b-gray-400 border-b">
            <p>{formatDate(new Date(upcomingInvoice.date))}</p>
            <p>&euro;{upcomingInvoice.amount.toFixed(2)}</p>
            <div className="flex capitalize items-center mb-1 px-2 py-1.5 bg-primary-300 text-secondary-50 rounded-lg w-fit">
              {upcomingInvoice.status}
            </div>
          </div>
        )}
        {selectedBusiness?.subscription?.willRenew === false && endDate && (
          <p className="ml-8 col-span-3 text-secondary font-bold py-1 px-2 border border-secondary rounded-md">
            {t('cancelledText', { date: formatDate(endDate) })}
          </p>
        )}
      </GridTable>
      <GridTable className="grid-cols-1 mt-8">
        <GridTableHeader className="col-span-1">
          <div>{t('paymentMethod')}</div>
        </GridTableHeader>
        <GridTableHelperText>{t('paymentMethodHelper')}</GridTableHelperText>
        {paymentDetails === undefined ? (
          <div className="animate-pulse h-12 w-full bg-secondary-200 rounded-xl" />
        ) : (
          <PaymentDetailsRow
            paymentDetails={paymentDetails}
            openPaymentModal={() => setEditPaymentModalOpen(true)}
          />
        )}
      </GridTable>
      <Feedback />
      <div className="flex gap-2 justify-end mt-2 mr-8">
        {selectedBusiness?.subscription?.willRenew === true && (
          <Button
            onClick={() => {
              subscriptionService.cancelSubscription().then(() => {
                businessService.fetchBusinesses();
              });
            }}
            variant="danger">
            <TbHeartBroken />
            {t('cancel')}
          </Button>
        )}
        {selectedBusiness?.subscription?.willRenew === false && (
          <Button
            onClick={() => {
              subscriptionService.reactivateSubscription().then(() => {
                businessService.fetchBusinesses();
              });
            }}
            variant="danger">
            <TbHeart />
            {t('reactivate')}
          </Button>
        )}
      </div>
      <ModalLayout
        open={editPaymentModalOpen}
        setOpen={setEditPaymentModalOpen}
        closeButton
        closeButtonClassName={subscriptionColors[activeSubscription].text}>
        <div
          className={`${subscriptionColors[activeSubscription].bg} px-10 py-6 rounded-xl w-[40rem]`}>
          <h3 className={`${subscriptionColors[activeSubscription].text} pt-4 text-xl font-bold`}>
            {t('newPaymentMethodTitle')}
          </h3>
          {clientSecret && stripe ? (
            <Elements options={options} stripe={stripe}>
              <CheckoutForm
                clientSecret={clientSecret}
                disabled={false}
                setDisabled={() => false}
                intentType="setupChange"
                onSubmit={() => {}}
                redirectQueryParams=""
                containerClassName="bg-secondary-50"
                buttonClassName="bg-secondary-50 text-primary-900"
              />
            </Elements>
          ) : (
            <></>
          )}
        </div>
      </ModalLayout>
    </>
  );
}

type PaymentDetailsRowProps = {
  paymentDetails: PaymentDetails | null;
  openPaymentModal: () => void;
};

function PaymentDetailsRow({ paymentDetails, openPaymentModal }: PaymentDetailsRowProps) {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });

  return (
    <div className="ml-8 flex justify-between items-center border-b-gray-400 border-b">
      <div className="flex gap-2 items-center py-2">
        {paymentDetails ? (
          paymentDetails.type === 'card' ? (
            <>
              <div className="pr-2">
                {paymentDetails.brand === 'visa' ? (
                  <FaCcVisa size={36} />
                ) : paymentDetails.brand === 'mastercard' || paymentDetails.brand === 'maestro' ? (
                  <SiMastercard size={30} />
                ) : paymentDetails.brand === 'amex' ? (
                  <FaCcAmex size={30} />
                ) : (
                  <TbCreditCard size={30} />
                )}
              </div>
              <div>
                <p>
                  {t('card')} {t('endingIn')} {paymentDetails.last4}
                </p>
                <p className="text-gray-500">
                  {t('expiryDate')}: {paymentDetails.exp_month}/{paymentDetails.exp_year}
                </p>
              </div>
            </>
          ) : paymentDetails.type === 'sepa_debit' ? (
            <>
              <div>
                <SiSepa size={30} />
              </div>
              <p>
                {paymentDetails.name} - {paymentDetails.bank_code} {t('endingIn')}{' '}
                {paymentDetails.last4}
              </p>
            </>
          ) : (
            <>
              <div>
                <SiPaypal size={30} />
              </div>
              <p>
                {paymentDetails.name && `${paymentDetails.name}, `}
                {paymentDetails.email}
              </p>
            </>
          )
        ) : (
          <p>{t('noPaymentMethod')}</p>
        )}
      </div>
      <Button onClick={openPaymentModal} className="mb-1" variant="primary">
        {t('editPaymentMethod')}
      </Button>
    </div>
  );
}

function Feedback(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });

  return (
    <div className="flex gap-4 py-2 px-8 mt-4">
      <ContactCard
        veraType="vera_smile"
        title={t('feedback.title')}
        subtitle={t('feedback.subtitle')}
        ctaText={t('feedback.ctaText')}
        href="/feedback"
      />
      <ContactCard
        veraType="vera_sad"
        title={t('contact.title')}
        subtitle={t('contact.subtitle')}
        ctaText={t('contact.ctaText')}
        href="/feedback"
      />
    </div>
  );
}

type ContactCardProps = {
  veraType: 'vera_smile' | 'vera_sad';
  title: string;
  subtitle: string;
  ctaText: string;
  href: string;
};

function ContactCard({ veraType, title, subtitle, ctaText, href }: ContactCardProps): JSX.Element {
  return (
    <div className="flex gap-4 items-center p-2 bg-secondary-200 bg-opacity-50 rounded-xl">
      <div className="w-16 h-16 p-2 flex justify-center items-center bg-secondary rounded-xl aspect-square">
        <img
          src={`${process.env.PUBLIC_URL}/assets/${veraType}.svg`}
          alt="Verified"
          className="w-9"
        />
      </div>
      <div>
        <h4 className="text-lg font-semibold">{title}</h4>
        <p className="text-sm text-gray-500">
          {subtitle}{' '}
          <Link className="text-secondary underline" to={href}>
            {ctaText}
          </Link>
        </p>
      </div>
    </div>
  );
}

type ExplanationPopupProps = { limitUsers: number };

function ExplanationPopup({ limitUsers }: ExplanationPopupProps) {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });
  const activeSubscription = useActiveSubscription();

  return (
    <div className="absolute p-2 min-w-96 z-10 text-secondary-50 bg-primary-900 hidden group-hover:block right-0 top-0 rounded-md">
      <BiInfoCircle className="absolute right-0 top-0" color={COLOR_SECONDARY_50} />
      <h4 className="mb-2">{t('priceDescription.title')}</h4>
      {activeSubscription === SubscriptionType.FREE ? (
        <div className="flex items-center gap-1">
          <div className="w-5 h-5 bg-secondary-200 flex justify-center items-center rounded-full">
            <div className="w-3 h-3 bg-primary-300 rounded-full" />
          </div>
          <p className="text-secondary-50">{t('priceDescription.free')}</p>
        </div>
      ) : (
        <ul>
          {memberCountPrices[activeSubscription].map(({ min, max, fee }) => (
            <li className="flex items-center gap-1" key={`sub_type_${min}_${max}_${fee}`}>
              <div className="w-5 h-5 bg-secondary-200 flex justify-center items-center rounded-full">
                {limitUsers === max && <div className="w-3 h-3 bg-primary-300 rounded-full" />}
              </div>
              {max === Infinity ? (
                <p className="text-secondary-50">
                  {t('priceDescription.moreThan', { x: min - 1 })}:{' '}
                  <Link className="underline" to="/contact">
                    {t('priceDescription.contactUs')}
                  </Link>
                </p>
              ) : (
                `${t('priceDescription.fromTo', { from: min, to: max })}: €${fee}`
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
