import React from 'react';
import { twMerge } from 'tailwind-merge';

export function GridTableHeader({ className, children }: { className?: string, children: React.ReactNode }): JSX.Element {
  return (
    <div
      className={twMerge('bg-opacity-50 bg-secondary-200 rounded-full col-span-3 grid grid-cols-3 pl-8 py-1', className)}>
      {children}
    </div>
  );
}

export function GridTableHelperText({ className, children }: { className?: string, children: React.ReactNode }): JSX.Element {
  return <p className={twMerge('col-span-3 text-gray-500 text-sm py-2 ml-8', className)}>{children}</p>;
}

export function GridTable({ className, children }: { className?: string, children: React.ReactNode }): JSX.Element {
  return <div className={twMerge('grid grid-cols-3 mx-8 mt-4', className)}>{children}</div>;
}
