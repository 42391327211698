import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import CountUpCard from '../../components/cards/CountUpCard';
import Card from '../../components/cards/Card';
import AnswerPercentageGraph from '../../components/graphs/AnswerPercentageGraph';
import AttendancePerPersonGraph from '../../components/graphs/AttendancePerPersonGraph';
import AttendancePerEventGraph from '../../components/graphs/AttendancePerEventGraph';
import DatePicker from '../../components/forms/DatePicker';
import useEvents from '../../../hooks/business/useEvents';
import { EventParticipationAnswer, EventType, EventViewType } from '../../../types/event';
import eventService from '../../../services/eventService';
import PageHeader from '../../components/misc/PageHeader';
import Labeled from '../../components/misc/Labeled';
import PodiumGraph from '../../components/graphs/PodiumGraph';
import Button from '../../components/buttons/Button';
import { downloadAnalytics } from '../../../utils/fileUtils';
import { formatDateForFileName } from '../../../utils/stringUtils';
import { setEvents } from '../../../redux/slices/eventSlice';
import PrimaryOverlay from '../../components/overlays/PrimaryOverlay';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import PremiumRequiredCard from '../../components/cards/PremiumRequiredCard';
import useActiveSubscription from '../../../hooks/business/useActiveSubscription';
import { SubscriptionType } from '../../../types/misc';

const MOCK_EVENTS = [
  {
    id: 1,
    businessId: 101,
    title: "Networking Meetup",
    memberCount: 15,
    description: "A local networking event for professionals.",
    location: "San Francisco",
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: "John",
        lastName: "Doe",
        answer: EventParticipationAnswer.YES,
        description: "Software engineer with 10 years of experience.",
        picture: "https://example.com/john_doe.jpg"
      },
      {
        id: 2,
        firstName: "Jane",
        lastName: "Smith",
        answer: EventParticipationAnswer.MAYBE,
        description: "Marketing specialist with a passion for branding.",
        picture: "https://example.com/jane_smith.jpg"
      }
    ]
  },
  {
    id: 2,
    businessId: 102,
    title: "Tech Conference",
    memberCount: 100,
    description: "Annual tech conference for startups and innovators.",
    location: "New York",
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: "Alice",
        lastName: "Johnson",
        answer: EventParticipationAnswer.YES,
        description: "Entrepreneur and founder of a tech startup.",
        picture: "https://example.com/alice_johnson.jpg"
      },
      {
        id: 2,
        firstName: "Bob",
        lastName: "Brown",
        answer: EventParticipationAnswer.NO,
        description: "Investor looking for promising startups.",
        picture: "https://example.com/bob_brown.jpg"
      },
      {
        id: 3,
        firstName: "Charlie",
        lastName: "White",
        answer: EventParticipationAnswer.PENDING,
        description: "Product manager with a focus on user experience.",
        picture: "https://example.com/charlie_white.jpg"
      }
    ]
  },
  {
    id: 3,
    businessId: 103,
    title: "Marketing Workshop",
    memberCount: 30,
    description: "A workshop for learning modern marketing strategies.",
    location: "Los Angeles",
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: "David",
        lastName: "Garcia",
        answer: EventParticipationAnswer.MAYBE,
        description: "Digital marketing expert specializing in social media.",
        picture: "https://example.com/david_garcia.jpg"
      }
    ]
  },
  {
    id: 4,
    businessId: 104,
    title: "Startup Pitch Night",
    memberCount: 50,
    description: "An event for startups to pitch their ideas to investors.",
    location: "Boston",
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: "Eve",
        lastName: "Martinez",
        answer: EventParticipationAnswer.YES,
        description: "Startup founder and CEO of an AI company.",
        picture: "https://example.com/eve_martinez.jpg"
      },
      {
        id: 2,
        firstName: "Frank",
        lastName: "Clark",
        answer: EventParticipationAnswer.NO,
        description: "Venture capitalist interested in tech startups.",
        picture: "https://example.com/frank_clark.jpg"
      },
      {
        id: 3,
        firstName: "Grace",
        lastName: "Hall",
        answer: EventParticipationAnswer.YES,
        description: "Engineer working on sustainable energy solutions.",
        picture: "https://example.com/grace_hall.jpg"
      }
    ]
  },
  {
    id: 5,
    businessId: 105,
    title: "Design Thinking Seminar",
    memberCount: 25,
    description: "A seminar on applying design thinking in business.",
    location: "Chicago",
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: "Hannah",
        lastName: "Lopez",
        answer: EventParticipationAnswer.YES,
        description: "Designer with expertise in user-centered design.",
        picture: "https://example.com/hannah_lopez.jpg"
      },
      {
        id: 2,
        firstName: "Ian",
        lastName: "Kim",
        answer: EventParticipationAnswer.MAYBE,
        description: "Business strategist focused on innovation.",
        picture: "https://example.com/ian_kim.jpg"
      }
    ]
  }
];

export default function EventReportsPage() {
  const { t } = useTranslation();
  const events = useEvents(EventViewType.ALL_REGULAR);
  const dispatch = useDispatch();
  const activeSubscription = useActiveSubscription();
  const hasSubscription = true; // activeSubscription !== SubscriptionType.FREE;
  const business = useSelectedBusiness();

  const [startDate, setStartDate] = useState<Date | undefined>(
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 90), // today - 90 days
  );
  const [endDate, setEndDate] = useState<Date | undefined>(new Date(Date.now()));

  useEffect(() => {
    if (!business || !business.businessId) return;

    if (!hasSubscription) {
      dispatch(setEvents({ events: MOCK_EVENTS as any, businessId: business.businessId }));
      return;
    }

    if (!startDate || !endDate) return;

    eventService.getEventAnalytics(startDate, endDate);
  }, [startDate, endDate, hasSubscription, business]);

  console.log(events);

  const handleDownload = () => {
    if (!startDate || !endDate) return;

    downloadAnalytics(
      events,
      `${t('page.agenda.report.title')}_${t('general.from')}_${formatDateForFileName(startDate)}_${t('general.to')}_${formatDateForFileName(endDate)}`,
    );
  };

  return (
    <main className={classNames('flex flex-col gap-2 relative', {
      'overflow-hidden h-[80vh]': !hasSubscription,
    })}>
      {!hasSubscription && (
        <PrimaryOverlay>
          <PremiumRequiredCard />
        </PrimaryOverlay>
      )}
      <PageHeader
        className="pb-6 mb-1 border-b border-primary-300"
        title={t('page.agenda.report.title')}
        subtitle={t('page.agenda.report.subtitle')}
      />
      <div className="flex gap-2 justify-between items-center flex-wrap">
        <div className="flex gap-4">
          <Labeled className="w-auto" label={t('page.agenda.report.startDate')}>
            <DatePicker date={startDate} setDate={setStartDate} includeTime={false} />
          </Labeled>
          <Labeled className="w-auto" label={t('page.agenda.report.endDate')}>
            <DatePicker date={endDate} setDate={setEndDate} includeTime={false} />
          </Labeled>
        </div>
        <Button className="ml-auto" onClick={handleDownload} variant="primary">{t('page.agenda.report.exportButton')}</Button>
      </div>

      {events.length === 0 ? (
        <div className="px-8 py-2 mt-4 bg-secondary rounded-lg self-center">
          <p className="flex gap-2 items-center">
            <FaInfoCircle />
            {t('page.agenda.report.noEvents')}
          </p>
        </div>
      ) : (
        <>
          <section className="flex gap-2 max-md:flex-col">
            <CountUpCard
              className="bg-secondary-200 flex-[1] min-h-[300px] aspect-square max-md:aspect-auto max-md:min-h-0"
              title={t(`page.agenda.report.event${events.length === 1 ? '' : 's'}`).toLowerCase()}
              count={events.length}
            />
            <Card className="bg-secondary-200 min-h-[200px] flex-[1]">
              <PodiumGraph events={events} />
              <h3 className="font-serif text-xl">{t('page.agenda.report.podiumTitle')}</h3>
            </Card>
            <Card className="bg-secondary-200 min-h-[200px] flex-[1]">
              <h3 className="font-serif text-xl">{t('page.agenda.report.answersTitle')}</h3>
              <AnswerPercentageGraph events={events} />
            </Card>
          </section>
          <section className="flex gap-2 min-h-[50vh]">
            <Card className="bg-secondary-200 w-full">
              <h3 className="font-serif text-xl">{t('page.agenda.report.answersPerEvent')}</h3>
              <AttendancePerEventGraph events={events} />
            </Card>
          </section>
          <section className="flex gap-2 min-h-[50vh]">
            <Card className="bg-secondary-200 w-full">
              <h3 className="font-serif text-xl">{t('page.agenda.report.answersPerUser')}</h3>
              <AttendancePerPersonGraph events={events} />
            </Card>
          </section>
        </>
      )}
    </main>
  );
}
