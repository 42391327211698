import React from 'react';
import { twMerge } from 'tailwind-merge';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  className?: string;
}

export default function PageHeader({ title, subtitle, className }: PageHeaderProps): JSX.Element {
  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      <h1 className="font-semibold font-serif text-3xl">{title}</h1>
      {subtitle && <p className="lg:w-1/2">{subtitle}</p>}
    </div>
  );
}
