import React from 'react';
import ConfigureIntegrationsPanel from '../components/panels/ConfigureIntegrationsPanel';

export default function ConfigureIntegrationsPage(): JSX.Element {
  return (
    <div data-testid="configure-integration-panel" className="overflow-y-scroll">
      <ConfigureIntegrationsPanel />
    </div>
  );
}
