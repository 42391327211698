import React, { useEffect, useState } from 'react';
import { Id, toast } from 'react-toastify';
import { HiLink, HiXMark } from 'react-icons/hi2';
import { BiRefresh } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import userService from '../../../services/userService';
import { crmTypeToTitleCase } from '../../../utils/stringUtils';
import {
  CRMAuthStatus,
  CRMConnnectionMechanism,
  CRMIntegration,
} from '../../../types/CRMIntegration';
import Button from '../buttons/Button';
import AuthParamsModal from '../modals/integrations/AuthParamsModal';
import AdditionalDataModal from '../modals/integrations/AdditionalDataModal';
import inviteService from '../../../services/inviteService';
import {
  BusinessInvite,
  BusinessInvitePropertyType,
} from '../../../services/model/inviteService.model';
import { createEmptyBusinessInvite } from '../../../utils/formatUtils';

export default function IntegrationsPanel(): JSX.Element {
  const [integrations, setIntegrations] = useState<CRMIntegration[]>([]);
  const [openAuthModals, setOpenAuthModals] = useState<{ [key: string]: boolean }>({});
  const [openAdditionalModals, setOpenAdditionalModals] = useState<{ [key: string]: boolean }>({});

  const navigate = useNavigate();

  useEffect((): void => {
    userService
      .getIntegrations()
      .then((res: CRMIntegration[]): void => {
        setIntegrations(res);
      })
      .catch((err: Error): void => {
        console.log('Error getting integrations', err);
      });
  }, []);

  const handleAdd = (crmIntegration: CRMIntegration): void => {
    if (crmIntegration.connectionMechanism === CRMConnnectionMechanism.MANUAL) {
      setOpenAuthModals({ [crmIntegration.crm]: true });
    } else {
      const loading: Id = toast.loading(`Connecting to ${crmTypeToTitleCase(crmIntegration.crm)}`);
      userService
        .connectToCRM(crmIntegration)
        .then((): void => {
          toast.success(`Successfully connected to ${crmIntegration.crm}`);
          setIntegrations(
            integrations.map((integration): CRMIntegration => {
              return {
                ...integration,
                connected: integration.crm === crmIntegration.crm ? true : integration.connected,
              };
            }),
          );
        })
        .finally((): void => toast.dismiss(loading));
    }
  };

  const handleAuthSubmitted = (crmIntegration: CRMIntegration, auth: unknown): void => {
    if (crmIntegration.connected) {
      const loading: Id = toast.loading(
        `Updating ${crmTypeToTitleCase(crmIntegration.crm)} authentication`,
      );
      userService
        .updateCRMIntegration(crmIntegration, { auth })
        .then((): void => {
          toast.success(`Successfully updated ${crmIntegration.crm} authentication`);
          setIntegrations(
            integrations.map((integration): CRMIntegration => {
              return {
                ...integration,
                authStatus:
                  integration.crm === crmIntegration.crm
                    ? CRMAuthStatus.VALID
                    : integration.authStatus,
              };
            }),
          );
          setOpenAuthModals({ [crmIntegration.crm]: false });
        })
        .finally((): void => toast.dismiss(loading));
    } else {
      const loading: Id = toast.loading(`Connecting to ${crmTypeToTitleCase(crmIntegration.crm)}`);
      userService
        .connectToCRM(crmIntegration, auth)
        .then((): void => {
          toast.success(`Successfully connected to ${crmIntegration.crm}`);
          setIntegrations(
            integrations.map((integration): CRMIntegration => {
              return {
                ...integration,
                connected: integration.crm === crmIntegration.crm ? true : integration.connected,
              };
            }),
          );
          setOpenAuthModals({ [crmIntegration.crm]: false });
          setInviteRequestedFields();
        })
        .finally((): void => toast.dismiss(loading));
    }
  };

  const setInviteRequestedFields = async (): Promise<void> => {
    const invite: BusinessInvite = await inviteService.fetchInvite();
    if (invite) {
      console.log('invite', invite);
      const updatedInvite = {
        ...invite,
        mandatoryFields: [
          BusinessInvitePropertyType.EMAIL,
          BusinessInvitePropertyType.ADDRESS,
          BusinessInvitePropertyType.PHONENUMBER,
        ],
        optionalFields: [
          ...invite.optionalFields.filter(
            (f) =>
              f !== BusinessInvitePropertyType.EMAIL &&
              f !== BusinessInvitePropertyType.ADDRESS &&
              f !== BusinessInvitePropertyType.PHONENUMBER,
          ),
          ...invite.mandatoryFields.filter(
            (f) =>
              f === BusinessInvitePropertyType.BUSINESSNAME ||
              f === BusinessInvitePropertyType.BIRTHDATE,
          ),
        ].filter((field, index, self) => self.indexOf(field) === index), // Remove duplicates
        nonRequestedFields: invite.nonRequestedFields.filter(
          (f) =>
            f !== BusinessInvitePropertyType.EMAIL &&
            f !== BusinessInvitePropertyType.ADDRESS &&
            f !== BusinessInvitePropertyType.PHONENUMBER,
        ),
      };
      console.log('updatedInvite', updatedInvite);

      inviteService.updateInvite(updatedInvite);
    } else {
      const emptyInvite = createEmptyBusinessInvite();
      const newInvite = {
        ...emptyInvite,
        mandatoryFields: [
          BusinessInvitePropertyType.EMAIL,
          BusinessInvitePropertyType.ADDRESS,
          BusinessInvitePropertyType.PHONENUMBER,
        ],
        optionalFields: [],
        nonRequestedFields: [
          BusinessInvitePropertyType.BIRTHDATE,
          BusinessInvitePropertyType.BUSINESSNAME,
          BusinessInvitePropertyType.X,
          BusinessInvitePropertyType.TIKTOK,
          BusinessInvitePropertyType.INSTAGRAM,
          BusinessInvitePropertyType.LINKEDIN,
          BusinessInvitePropertyType.FACEBOOK,
        ],
      };
      inviteService.createInvite(newInvite);
    }
  };

  const handleAdditionalSubmitted = (crmIntegration: CRMIntegration, additional: unknown): void => {
    const loading: Id = toast.loading(`Updating ${crmIntegration.crm} additional data`);
    userService
      .updateCRMIntegration(crmIntegration, { additional })
      .then((): void => {
        toast.success(`Successfully updated ${crmIntegration.crm} additional data`);
        setOpenAdditionalModals({ [crmIntegration.crm]: false });
      })
      .finally((): void => toast.dismiss(loading));
  };

  return (
    <div className="place-items-center min-h-[calc(100vh-65px)] pt-6">
      <div className="flex flex-col lg:flex-row gap-6">
        {integrations.length > 0 &&
          integrations.map(
            (integration: CRMIntegration): JSX.Element => (
              <div
                key={integration.crm}
                data-testid={`${integration.crm}-card`}
                className="w-full flex flex-col bg-secondary-200 border-2 border-secondary-200 shadow-[0px_4px_4px_0px_#EDD5C940] bg-opacity-50 rounded-[20px] px-4 py-5 gap-3">
                <div className="h-32">
                  <img
                    data-testid={`${integration.crm}-logo`}
                    className="rounded-t-lg w-full h-full object-contain"
                    src={`crms/${integration.crm.toString().toLowerCase()}.png`}
                    alt=""
                  />
                </div>

                <div className="p-5">
                  <h2 className="font-serif text-[20px]">{crmTypeToTitleCase(integration.crm)}</h2>
                  <p className="text-[17px] font-medium">
                    {integration.connected
                      ? `Manage your connection with ${crmTypeToTitleCase(integration.crm)}.`
                      : `Integrate your Vera account with ${crmTypeToTitleCase(integration.crm)}.`}
                  </p>
                </div>
                {integration.connected ? (
                  <Button
                    variant="primary"
                    className="text-[15px] ml-auto mt-auto"
                    onClick={() => navigate('/integrations/manage', { state: { integration } })}>
                    {' Configure'}
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    className="text-[15px] ml-auto mt-auto"
                    onClick={(): void => handleAdd(integration)}>
                    <HiLink className="h-5 w-5 inline-block" />
                    {' Connect'}
                  </Button>
                )}
                {integration.connectionMechanism === CRMConnnectionMechanism.MANUAL && (
                  <AuthParamsModal
                    crmIntegration={integration}
                    open={openAuthModals[integration.crm] ?? false}
                    setOpen={(open) => setOpenAuthModals({ [integration.crm]: open })}
                    onSubmitted={(auth) => handleAuthSubmitted(integration, auth)}
                  />
                )}
                {integration.connected && (
                  <AdditionalDataModal
                    crmIntegration={integration}
                    open={openAdditionalModals[integration.crm] ?? false}
                    setOpen={(open) => setOpenAdditionalModals({ [integration.crm]: open })}
                    initialData={integration.additional}
                    onSubmitted={(additional) => handleAdditionalSubmitted(integration, additional)}
                  />
                )}
              </div>
            ),
          )}
        <div className="w-full flex flex-col bg-secondary-200 border-2 border-secondary-200 shadow-[0px_4px_4px_0px_#EDD5C940] bg-opacity-50 rounded-[20px] px-4 py-5 gap-3">
          <div className="h-32">
            <img
              src={`${process.env.PUBLIC_URL}/assets/figures/question.svg`}
              alt="Vera Question"
              className="rounded-t-lg w-full h-full object-contain"
            />
          </div>

          <div className="p-5">
            <h2 className="font-serif text-[20px]">Missing your CRM system?</h2>
            <p className="text-[17px] font-medium">
              Contact our team to see what we can do for you
            </p>
          </div>
          <Link to="mailto:?to=info@veraconnect.nl">
            <Button variant="primary" className="text-[15px] ml-auto mt-auto">
              Contact Vera Team
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
