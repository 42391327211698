import httpService from './httpService';
import { getSelectedBusiness } from '../hooks/business/useSelectedBusiness';
import { InvoiceData, PaymentDetails } from '../types/payments';

export const SUBSCRIPTIONS_BASE_ROUTE = '/business/subscriptions';

export const subscriptionService = {
  prepareSubscription: async () : Promise<{
    status: 'subscription_exists' | 'subscription_prepared';
    clientSecret?: string;
    customerId?: string;
  }> => {
    const res = await httpService.post(`${SUBSCRIPTIONS_BASE_ROUTE}/prepare`);
    return res.data;
  },

  createSubscription: async (communityId: number, priceId: string, customerId: string) : Promise<{ clientSecret: string, subscriptionId: string }> => {
    const res = await httpService.post(`${SUBSCRIPTIONS_BASE_ROUTE}/`, { priceId, customerId })
    return res.data;
  },

  createSetupIntent: async () : Promise<{ clientSecret: string}> => {
    const res = await httpService.post(`${SUBSCRIPTIONS_BASE_ROUTE}/setup-intents`);
    return res.data
  },

  getSubscriptionUsage: async (): Promise<number> =>{
    const res = await httpService.get(`${SUBSCRIPTIONS_BASE_ROUTE}/usage`)
    return res.data
  },

  upgradeSubscription: async (priceId: string): Promise<void> => {
    await httpService.put(`${SUBSCRIPTIONS_BASE_ROUTE}/`, {priceId})
  },

  cancelSubscription: async (): Promise<void> => {
    await httpService.delete(`${SUBSCRIPTIONS_BASE_ROUTE}/`);
  },

  getPaymentDetails: async (): Promise<PaymentDetails> => {
    const res = await httpService.get(`${SUBSCRIPTIONS_BASE_ROUTE}/payment-details`);
    return res.data;
  },

  getLastInvoices: async (limit: number): Promise<InvoiceData[]> => {
    const res = await httpService.get(`${SUBSCRIPTIONS_BASE_ROUTE}/invoices?limit=${limit}`);
    return res.data
  },

  getUpcomingInvoice: async (): Promise<InvoiceData> => {
    const res = await httpService.get(`${SUBSCRIPTIONS_BASE_ROUTE}/invoices/upcoming`);
    return res.data;
  },

  fetchInvoicePdf: async (invoiceId: string): Promise<Blob> => {
    return httpService
      .get(`${SUBSCRIPTIONS_BASE_ROUTE}/invoices/pdfs/${invoiceId}`, { responseType: 'blob' })
      .then((res) => res.data);
  },

  getEndDate: async (): Promise<number> => {
    return httpService.get(`${SUBSCRIPTIONS_BASE_ROUTE}/end-date`).then((res) => res.data);
  },

  reactivateSubscription: async () => {
    return httpService.put(`${SUBSCRIPTIONS_BASE_ROUTE}/reactivate`);
  }
}
