import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MdClose, MdOutlineUploadFile } from 'react-icons/md';
import { toast } from 'react-toastify';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import ModalLayout from '../layouts/ModalLayout';
import Button from '../buttons/Button';
import CancelSaveButton from '../misc/CancelSaveButton';
import { useDebounceEffect } from '../../../utils/useDebounceEffect';
import { canvasPreview } from '../../../utils/canvasPreview';

interface UploadPostcardModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onClose?: (data: UploadData) => void;
}

export interface UploadData {
  imgUrl: string;
  imgFile: File | null;
}

export default function UploadPostcardModal({
  showModal,
  setShowModal,
  onClose = (): void => {},
}: UploadPostcardModalProps): JSX.Element {
  const { t } = useTranslation();
  const printOneMaxSize = 200 * 1024; // 200KB in bytes
  const maxWidth = 4096;
  const maxHeight = 4096;
  const aspect = 1;
  const rotate = 0
  const scale = 1

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef('');
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const handleClearImage = (): void => {
    setSelectedImage(null);
    setImgSrc('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      processFile(file);
    }
  };

  const processFile = (file: File) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        // Check if image size is less than 4096x4096 pixels
        if (img.naturalWidth <= maxWidth && img.naturalHeight <= maxHeight) {
          setSelectedImage(file);
          setImgSrc(reader.result?.toString() || '');
        } else {
          toast.error(t('toast.error.maxImageSize'));
        }
      };
    });
    reader.readAsDataURL(file);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
  };

  async function getCropImg(image: HTMLImageElement, previewCanvas: HTMLCanvasElement) {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    if (!completedCrop) {
      throw new Error('No crop');
    }

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    // { type: "image/jpeg", quality: 0 } reduce image size
    const blob = await offscreen.convertToBlob({
      type: selectedImage?.type,
      quality: 0.8,
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);

    const myFile = new File([blob], selectedImage?.name as string, {
      type: selectedImage?.type,
    });
    setSelectedImage(myFile);

    setShowModal(false);
    setSelectedImage(null);
    return myFile;
  }

  const handleSubmit = async (): Promise<void> => {
    if (selectedImage) {
      const image = imgRef.current;
      const previewCanvas = previewCanvasRef.current;
      if (!image || !previewCanvas || !completedCrop) {
        throw new Error('Crop canvas does not exist');
      }
      const myFile = await getCropImg(image, previewCanvas);
      onClose({ imgUrl: URL.createObjectURL(myFile), imgFile: myFile });
      handleClearImage();
    }
  };

  const handleOnCancel = () => {
    setShowModal(false);
    handleClearImage();
  };

  function centerAspectCrop(mediaWidth: number, mediaHeight: number, cAspect: number) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        cAspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      const file = e.target.files[0];
      if (file.size > printOneMaxSize) {
        toast.error(t('toast.error.maxImageFileSize'));
        return;
      }
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      );
      reader.readAsDataURL(file)
      processFile(file);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {

        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  return (
    <ModalLayout
      open={showModal}
      setOpen={setShowModal}
      className="bg-secondary-50 min-w-[400px] w-2/5 pb-4 pt-5  z-50 rounded-[20px] py-6">
      <div className="flex flex-col px-10 justify-center items-center">
        <div className="w-full">
          <p className="font-semibold text-lg ">{t('component.modal.uploadPostcard.title')}</p>
        </div>

        <div
          className="w-full mb-1 flex-col border-2 cursor-pointer border-primary-900 border-dashed hover:border-opacity-80 hover:bg-primary-300 hover:bg-opacity-10 h-fit p-4 flex items-center justify-center rounded-lg"
          onDrop={handleDrop}
          onDragOver={handleDragOver}>
          <label htmlFor="file-upload">
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={onSelectFile}
              ref={fileInputRef}
            />
            {!imgSrc && (
              <div className="w-full mb-1 flex-col h-fit p-4 flex items-center justify-center rounded-lg">
                <MdOutlineUploadFile className="w-14 h-14 text-primary-900 mb-1" />
                <span className="font-medium text-sm text-primary-900 text-center items-center justify-center">
                  <Trans i18nKey="component.modal.importLocalContacts.import.importButton" />
                </span>
              </div>
            )}
          </label>

          {imgSrc && (
            <div className="w-full relative">
              <Button
                onClick={handleClearImage}
                className="absolute -top-8 -right-6 bg-error text-white rounded-full p-2">
                <MdClose className="w-4 h-4" />
              </Button>
              {!!imgSrc && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={1}
                  maxWidth={maxWidth}
                  maxHeight={maxHeight}>
                  <img
                    ref={imgRef}
                    alt="Preview"
                    src={imgSrc}
                    className="w-full object-contain rounded-lg"
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
            </div>
          )}
          {!!completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: '1px solid black',
                    objectFit: 'contain',
                    width: 0,
                    height: 0,
                    visibility: 'hidden',
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="flex justify-between w-full mt-4">
          <div className="flex flex-col w-full mt-4">
            <span className="text-xs font-medium text-primary-900">
              {t('component.modal.uploadPostcard.fileSupported')}
            </span>
            <span className="text-xs font-medium text-primary-900">
              {t('component.modal.uploadPostcard.fileSize')}
            </span>
          </div>
          <CancelSaveButton
            disabled={selectedImage === null}
            onSave={handleSubmit}
            onCancel={handleOnCancel}
          />
        </div>
      </div>
    </ModalLayout>
  );
}
