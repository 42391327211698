import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useDelayedEffect from '../../../../hooks/effects/useDelayedEffect';

interface CommunicationNameInputProps {
  name: string;
  setName: (name: string) => void;
  mandatory?: boolean;
  validate?: boolean;
  className?: string;
}

export default function CommunicationNameInput({
  name,
  setName,
  mandatory = false,
  validate = false,
  className,
}: CommunicationNameInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  useDelayedEffect(() => {
    if (!validate) return;
    if (!name) {
      setError(!mandatory ? '' : t('invalid.empty'));
      return;
    }
    setError('');
  }, [name, validate]);

  return (
    <div className={twMerge('relative flex w-full flex-col', className)}>
      <label className="mb-1 pr-2 font-medium">
        {t('form.communicationName.communicationName')} {mandatory ? '*' : ''}
      </label>
      <input
        placeholder={t('form.communicationName.placeholder')}
        className={classNames(
          'h-10 w-full rounded-lg border px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
          {
            '!border-error': error,
          },
        )}
        name="email"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <p className="absolute right-1 top-0 text-sm text-error transition-all">{error}</p>
    </div>
  );
}
