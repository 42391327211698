import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PersonalConnection } from '../../types/misc';
import { ProfileField } from '../../types/Profile';

export interface UserState {
  connections: PersonalConnection[];
  fields: ProfileField[];
  profilePicture: string;
}

const initialState: UserState = {
  connections: [],
  fields: [],
  profilePicture: '',
};

/**
 * Redux slice for user
 */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPersonalConnections(state, action: PayloadAction<PersonalConnection[]>): void {
      state.connections = action.payload;
    },
    setProfileFields(state, action: PayloadAction<ProfileField[]>): void {
      state.fields = action.payload;
    },
    setProfilePicture(state, action: PayloadAction<string>): void {
      state.profilePicture = action.payload;
    },
  },
});

const userReducer = userSlice.reducer;

export const { setPersonalConnections, setProfileFields, setProfilePicture } = userSlice.actions;
export default userReducer;
