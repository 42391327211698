import React from 'react';
import ClientListPanel from '../components/panels/ClientListPanel';
import connectionService from '../../services/connectionService';
import tagService from '../../services/tagService';
import useAdmins from '../../hooks/business/useAdmins';
import adminService from '../../services/adminService';
import usePersonalConnections from '../../hooks/account/usePersonalConnections';
import userService from '../../services/userService';
import inviteService from '../../services/inviteService';
import useBusinessEffect from '../../hooks/effects/useBusinessEffect';

/*
 * This page is used to display the dashboard, the main page
 */
export default function ContactsPage(): JSX.Element {
  const admins = useAdmins();
  const personalConnections = usePersonalConnections();

  useBusinessEffect((): void => {
    connectionService.getConnections();
    tagService.fetchTags();
    inviteService.fetchInvite();
    if (admins.length === 0) {
      adminService.getAllAdmins();
      adminService.getAllAdminInvites();
    }
    if (personalConnections.length === 0) {
      userService.getConnections();
    }
  }, []);

  return <ClientListPanel />;
}
