import React from 'react';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { isMoments } from '../../../constants';
import InvitationLink from '../misc/InvitationLink';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import inviteService from '../../../services/inviteService';
import Button from '../buttons/Button';

export default function InvitationLinkPanel() {
  return isMoments ? <MomentsInvitationLinkPanel /> : <CommunityInvitationLinkPanel />;
}

function CommunityInvitationLinkPanel(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div data-testid="invite-panel" className="bg-secondary-200 rounded-[20px] p-4 flex-grow">
      <div className="mb-2 pl-2 pb-2">
        <h2 className="text-xl font-serif">{t('page.dashboard.invite.title')}</h2>
      </div>
      <div className="pl-2">
        <InvitationLink />
      </div>
    </div>
  );
}

function MomentsInvitationLinkPanel(): JSX.Element {
  const { t } = useTranslation();
  const link = useSelectedBusiness()?.inviteFields?.friendlyId;

  return (
    <div data-testid="invite-panel" className="bg-secondary-200 rounded-[20px] p-4 flex-grow">
      {link ? (
        <>
          <div className="mb-2 pl-2 pb-2">
            <h2 className="text-xl font-serif">{t('page.dashboard.invite.title')}</h2>
          </div>
          <div className="pl-2">
            <InvitationLink link={link} />
          </div>
        </>
      ) : (
        <>
          <div className="mb-2 pl-2 pb-2">
            <h2 className="text-xl font-serif">{t('page.dashboard.invite.title')}</h2>
            <p className="">{t('page.dashboard.invite.moments.description')}</p>
          </div>
          <div className="pl-2">
            <Button variant="primary" onClick={inviteService.createInviteLink}>
              <p className="text-secondary-50">{t('page.dashboard.invite.moments.button')}</p>
              <FaWandMagicSparkles className="w-5 h-5 text-secondary-50" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
