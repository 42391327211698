import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { store } from '../redux/store';
import { deleteBusiness, wipeSelectedBusiness } from '../redux/slices/applicationSlice';
import { API_URL, APPLICATION_VERSION } from '../constants';
import { JwtData } from '../types/misc';

const httpService: AxiosInstance = axios.create({
  baseURL: API_URL,
});

export function getUserId(): number {
  const token = localStorage.getItem('token') ?? '';
  const data = jwtDecode<JwtData>(token);
  return data.payload.userId;
}

export const attachToken = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = localStorage.getItem('token');
  request.headers.Authorization = token ? `Bearer ${token}` : '';
  const { selectedBusiness } = store.getState().application;
  request.headers['x-business-id'] = selectedBusiness;
  request.headers['x-business-version'] = APPLICATION_VERSION;
  return request;
};

export const attachVersion = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  // eslint-disable-next-line global-require
  request.headers['App-Version'] = `moments-${require('../../package.json').version}`;
  return request;
};

export const attachNgrok = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (process.env.REACT_APP_NGROK) {
    request.headers['ngrok-skip-browser-warning'] = 'true';
  }
  return request;
};

httpService.interceptors.request.use(attachToken);
httpService.interceptors.request.use(attachVersion);
httpService.interceptors.request.use(attachNgrok);

export const handleUnAuthorizedError = async (error: any): Promise<any> => {
  if (error.response?.data?.code === 10001) {
    const originalRequest = error.config;

    const refresh = localStorage.getItem('refreshToken');
    if (!refresh) {
      return Promise.reject(error);
    }
    try {
      const response = await axios.post(`${API_URL}/auth/refreshtoken`, { refreshToken: refresh });
      const { accessToken, refreshToken } = response.data;

      setToken(accessToken);
      setRefreshToken(refreshToken);
      if (!originalRequest) return await Promise.reject(error);
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      return await httpService(originalRequest);
    } catch (refreshError) {
      return Promise.reject(error);
    }
  }
  if (error.response?.data.code === 40001) {
    store.dispatch(deleteBusiness(store.getState().application.selectedBusiness));
    store.dispatch(wipeSelectedBusiness());
  }
  return Promise.reject(error);
};

httpService.interceptors.response.use(null, handleUnAuthorizedError);

export const handleError = (error: any): Promise<void> => {
  if (!error.response.data.print) return Promise.reject(error);
  const errorMessage = error.response.data.error;

  toast.error(errorMap[errorMessage as keyof typeof errorMap] || errorMessage);

  return Promise.reject(error);
};

httpService.interceptors.response.use(null, handleError);

export const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};
export const setRefreshToken = (token: string): void => {
  localStorage.setItem('refreshToken', token);
};

export const wipeToken = (): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};

export default httpService;

const errorMap = {
  'Requested a new magic link too soon.':
    'Je hebt al een mail ontvangen. Probeer het later nog eens.',
  'There is no user with such primary email!': 'Gebruiker bestaat niet',
  'User already exists!': 'Gebruiker bestaat al',
  'Can not update invite when there are connections': 'Je hebt al connecties',
};
