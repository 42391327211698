import { Address } from "./Profile";

export type CRMIntegration = {
  crm: CRMType;
  connectionMechanism: CRMConnnectionMechanism;
  connected: boolean;
  authStatus?: CRMAuthStatus;
  additional?: unknown;
  loginUrl?: string;
};

export enum CRMType {
  E_BOEKHOUDEN = 'E_BOEKHOUDEN',
}

export enum CRMConnnectionMechanism {
  MANUAL = 'MANUAL',
}

export enum CRMAuthStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export interface CrmUser {
  connectionId: number;
  name: string;
  address?: Address;
  email?: string;
  phonenumber?: {
    prefix: string;
    suffix: string;
    countryCode: string;
  };
  additional: Record<string, string>;
}