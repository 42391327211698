import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import Button from '../buttons/Button';

interface CancelSaveButtonProps {
  onCancel: () => void;
  onSave: () => void;
  buttonText?: string;
  className?: string;
  disabled?: boolean;
  saveClassName?: string;
  cancelClassName?: string;
}

export default function CancelSaveButton({
  onCancel,
  onSave,
  buttonText,
  className = '',
  disabled = false,
  saveClassName = '',
  cancelClassName = '',
}: CancelSaveButtonProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={twMerge('flex w-fit', className)}>
      <Button
        onClick={onCancel}
        className={twMerge('hover:text-opacity-60', cancelClassName)}>
        {t('general.cancel')}
      </Button>
      <Button variant="secondary" disabled={disabled} onClick={onSave} className={saveClassName}>
        {buttonText || t('general.save')}
      </Button>
    </div>
  );
}
