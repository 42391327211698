import React from 'react';
import { CgSpinner } from 'react-icons/cg';
import { TbDownload } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { GridTable, GridTableHeader, GridTableHelperText } from './GridPaymentTable';
import { formatDate } from '../../../utils/stringUtils';
import Button from '../buttons/Button';
import { InvoiceData } from '../../../types/payments';
import { downloadInvoice } from '../../../utils/subscriptionUtils';

type InvoiceTableProps = { invoices: InvoiceData[]; loading: boolean };

export default function InvoicesTable({ invoices, loading }: InvoiceTableProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.invoices' });
  const { t: manageT } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });
  const [idsDownloading, setIdsDownloading] = React.useState<Set<string>>(new Set());

  const handleDownload = async (id: string) => {
    setIdsDownloading((prev) => new Set(prev).add(id));
    downloadInvoice(id)
      .finally(() => setIdsDownloading((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      }));
  };

  return (
    <GridTable>
      <GridTableHeader>
        <div>{manageT('lastPayments')}</div>
        <div>{manageT('total')}</div>
        <div>{manageT('status')}</div>
      </GridTableHeader>
      <GridTableHelperText>{t('helper')}</GridTableHelperText>
      {loading && <div className="animate-pulse col-span-3 h-12 w-full bg-secondary-200 rounded-xl" />}
      {!loading && invoices.map((invoice) => (
        <div className="ml-8 col-span-3 grid grid-cols-3 items-center border-b-gray-400 border-b">
          <p>{formatDate(new Date(invoice.date * 1000))}</p>
          <p>&euro;{(invoice.amount / 100).toFixed(2)}</p>
          <div className="flex items-center">
            <p className="uppercase">{invoice.status}</p>
            <Button className="disabled:opacity-75 hover:opacity-75" disabled={idsDownloading.has(invoice.id)}
                    onClick={() => handleDownload(invoice.id)}>
              {idsDownloading.has(invoice.id) ? <CgSpinner size={20} className="animate-spin" /> :
                <TbDownload size={20} />}
            </Button>
          </div>
        </div>
      ))}
    </GridTable>
  );
}
