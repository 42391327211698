import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/reducers';

export const useNonAdminRedirect = () => {
  const { role } = useSelector((state: RootState) => state.application);
  const navigate = useNavigate();

  useEffect(() => {
    if (role && role !== 'ADMIN') {
      navigate('/');
    }
  }, [role]);
};
