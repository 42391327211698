import { store } from '../redux/store';
import httpService, { setToken, wipeToken, setRefreshToken } from './httpService';
import Business from '../types/business';
import { CreateBusinessForm, CreateUserForm } from '../types/forms';
import {
  login,
  setSelectedBusiness,
  logout as logoutDispatch,
} from '../redux/slices/applicationSlice';
import { RegisterRequest, RegisterResponseBody } from './model/authService.model';

/*
 * The auth service, contains all logic for authentication
 */
const authService = {
  /*
   * Registers a new user
   * @param {UserType} user - The user to register
   * @returns {AxiosResponse} - The response from the server
   */
  register: async (alias: string, email: string): Promise<void> => {
    const body: RegisterRequest = { alias, email };

    return httpService
      .post<RegisterResponseBody>('/auth/register', body)
      .then(({ data: { userId, accessToken, refreshToken } }): void => {
        setToken(accessToken);
        setRefreshToken(refreshToken);
        const [firstName, lastName] = alias.split('+');

        store.dispatch(
          login({
            userId,
            firstName,
            lastName,
            email,
          }),
        );
      });
  },

  instantRegister: async (user: CreateUserForm, business: CreateBusinessForm): Promise<void> => {
    const {
      data: { accessToken, refreshToken, userId, userAlias, userEmail },
    } = await httpService.post<RegisterResponseBody>('/auth/business/instant-register', {
      ...user,
      ...business,
    });

    const [firstName, lastName] = userAlias.split('+');
    setToken(accessToken);
    setRefreshToken(refreshToken);
    const { data: businesses } = await httpService.get<Business[]>('/business');
    store.dispatch(
      login({
        userId,
        firstName,
        lastName,
        email: userEmail,
        businesses,
      }),
    );
    if (businesses[0].businessId) store.dispatch(setSelectedBusiness(businesses[0].businessId));
  },

  generateMagicLink: async (
    email: string,
    options?: { business?: CreateBusinessForm; adminToken?: string },
  ): Promise<void> => {
    const { business, adminToken } = options || {};
    await httpService.post('/auth/business/magic-link', { email, ...business, adminToken });
  },

  /*
   * Logs in a user from a magic link.
   *
   * @param {token} string - The user to login
   * @returns {void}
   */
  loginMagicLink: async (magicLinkId: string): Promise<void> => {
    const {
      data: { accessToken, refreshToken, userId, userAlias, userEmail, userRole: role },
    } = await httpService.post('/auth/loginMagic', { magicLinkId });
    const [firstName, lastName] = userAlias.split('+');
    setToken(accessToken);
    setRefreshToken(refreshToken);
    const { data: businesses } = await httpService.get<Business[]>('/business');
    store.dispatch(logoutDispatch());
    store.dispatch(
      login({
        userId,
        firstName,
        lastName,
        email: userEmail,
        businesses,
        role,
      }),
    );
  },

  /*
   * Logs out a user
   * @returns {void}
   */
  logout: (): void => {
    store.dispatch(logoutDispatch());
    wipeToken();
    localStorage.clear();
  },
};

export default authService;
