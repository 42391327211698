import { Menu, Transition } from '@headlessui/react';
import { HiTrash } from 'react-icons/hi';
import { FaFilter, FaTrash } from 'react-icons/fa';
import React, { Fragment, useEffect } from 'react';
import { TagState } from '../../../types/Tag';
import tagService from '../../../services/tagService';

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @typedef {object} FilterOnTagsProps
 * @property {TagState[]} tags - The tags for the dropdown.
 * @property {function} onTagClick - The function to handle the click on a tag.
 * @property {string[]} filterTags - The tags that are currently filtered on.
 */
interface FilterOnTagsProps {
  tags: TagState[];
  onTagClick: (id: string) => void;
  filterTags: string[];
}

/**
 * Returns the dropdown to filter on tags.
 * @param {TagState[]} tags - The tags for the dropdown.
 * @param {function} onTagClick - The function to handle the click on a tag.
 * @param {string[]} filterTags - The tags that are currently filtered on.
 * @returns the dropdown to filter on tags.
 */
function FilterOnTags({ tags, onTagClick, filterTags }: FilterOnTagsProps): JSX.Element {
  const [visibleTags, setVisibleTags] = React.useState<TagState[]>(tags);
  const [inputValue, setInputValue] = React.useState<string>('');

  useEffect(() => {
    if (inputValue === '') {
      setVisibleTags(tags);
    }
  }, [tags]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
    setVisibleTags(
      tags.filter((tag: TagState): boolean => tag.name.toLowerCase().includes(e.target.value)),
    );
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onTagClick(visibleTags[0].tagId);
    } else if (e.key === ' ') {
      e.preventDefault();
      setInputValue(`${inputValue} `);
    }
  };

  return (
    <Menu as="div" className="relative ml-2 mt-0.5">
      <div>
        <Menu.Button data-testid="filter-on-tags-dropdown">
          <FaFilter />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 z-[50] mt-2 w-48 h-fit origin-top-right rounded-md bg-secondary-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item key="filter-on-tags" data-testid="filter-on-tags">
            {({ active }): JSX.Element => (
              <p className="block px-4 py-2 text-sm font-bold text-gray-700">Filteren op labels</p>
            )}
          </Menu.Item>
          <Menu.Item key="add-tag" data-testid="add-tag">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={(e): void => handleKeyPress(e)}
              onClick={(e): void => e.preventDefault()}
              placeholder="Zoek op label"
            />
          </Menu.Item>
          {visibleTags.map(
            (tag: TagState): JSX.Element => (
              <Menu.Item key={tag.tagId}>
                {({ active }): JSX.Element => (
                  <div
                    data-testid={`filter-on-tag-${tag.tagId}`}
                    onClick={(e: React.MouseEvent<HTMLParagraphElement, MouseEvent>): void => {
                      onTagClick(tag.tagId);
                      e.preventDefault();
                    }}
                    className={`${
                      filterTags.includes(tag.tagId)
                        ? 'bg-secondary-200 text-primary-900 font-bold hover:bg-opacity-80'
                        : 'hover:bg-secondary-200'
                    } block px-4 py-2 text-sm text-primary-900 relative cursor-pointer z-0 `}>
                    {tag.name}
                    <button
                      data-testid={`delete-tag-${tag.tagId}`}
                      type="button"
                      className="inline-flex absolute right-3 bottom-1 items-center ml-3 p-2 rounded-md"
                      onClick={(e): void => {
                        tagService.deleteTag(tag.tagId);
                        e.stopPropagation();
                      }}>
                      <HiTrash className="text-primary-900 h-4 w-4" />
                    </button>
                  </div>
                )}
              </Menu.Item>
            ),
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default FilterOnTags;
