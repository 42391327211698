import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import classNames from 'classnames';
import { FaArrowUpFromBracket } from 'react-icons/fa6';
import { ExcelColumn, ExcelColumnType } from '../../../../types/misc';
import { LocalContactInfo } from '../../../../services/model/localContactService.model';
import { BusinessInvite, CustomFieldType } from '../../../../services/model/inviteService.model';
import { ProfileDataType } from '../../../../types/Profile';
import {
  formatLocalContactInfosfromColumns,
  fromInviteToExcelColumnTypes,
  fromStringtoExcelColumnType,
} from '../../../../utils/formatUtils';
import { filterCustomLabelFields } from '../../../../utils/filterUtils';
import { isValidLocalContact } from '../../../../utils/validationUtils';
import localContactService from '../../../../services/localContactService';
import { excelColumnTypeToString } from '../../../../utils/stringUtils';
import { COLOR_PRIMARY, COLOR_PRIMARY_300, COLOR_PRIMARY_900, COLOR_SECONDARY_50 } from '../../../../constants';
import CancelSaveButton from '../../misc/CancelSaveButton';
import IconBadge from '../../icons/IconBadge';

interface SelectColumnsHeaderProps {
  fileName: string;
}

export function SelectColumnsHeader({ fileName }: SelectColumnsHeaderProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'component.modal.importLocalContacts.selectColumn' });

  return (
    <div className="flex items-center mb-2">
      <IconBadge icon={FaArrowUpFromBracket} />

      <div className="flex flex-col ml-4 w-full">
        <h1 className="text-3xl font-serif">
          {t('title', { fileName })}
        </h1>
        <p>{t('subtitle')}</p>
      </div>
    </div>
  );
}

interface SelectColumnsViewProps {
  columns: ExcelColumn[];
  setColumns: (columns: ExcelColumn[]) => void;
  setLocalContacts: (contacts: LocalContactInfo[]) => void;
  invite: BusinessInvite;
  onSubmitSuccessful?: () => void;
  onSubmitFailed?: () => void;
  onMissingCountry?: () => void;
}

export function SelectColumnsView({
  columns,
  setColumns,
  setLocalContacts,
  invite,
  onSubmitSuccessful,
  onSubmitFailed,
  onMissingCountry,
}: SelectColumnsViewProps): JSX.Element {
  const { t } = useTranslation();

  const [columnTypes, setColumnTypes] = useState<(ExcelColumnType | string)[]>([]);

  const includeColumnType = (type: ExcelColumnType | string) => {
    const dataRequest = [...invite.mandatoryFields, ...invite.optionalFields];
    switch (type) {
      case ExcelColumnType.EMAIL:
        return dataRequest.includes(ProfileDataType.EMAIL);
      case ExcelColumnType.PHONENUMBER:
        return dataRequest.includes(ProfileDataType.PHONENUMBER);
      case ExcelColumnType.STREET:
      case ExcelColumnType.POSTAL_CODE:
      case ExcelColumnType.CITY:
      case ExcelColumnType.COUNTRY:
        return dataRequest.includes(ProfileDataType.ADDRESS);
      case ExcelColumnType.BIRTHDATE:
        return dataRequest.includes(ProfileDataType.BIRTHDATE);
      case ExcelColumnType.BUSINESSNAME:
        return dataRequest.includes(ProfileDataType.BUSINESSNAME);
      case ExcelColumnType.COMMUNICATION_NAME:
        return invite.customFields.find((f) => f.type === CustomFieldType.COMMUNICATION_NAME);
      default:
        return true;
    }
  };

  useEffect(() => {
    const newColumnTypes = [...columnTypes];
    columns.forEach((c, i) => {
      const type = fromStringtoExcelColumnType(c.name)
        || filterCustomLabelFields(invite.customFields)
          .find((f) => f.label.toLowerCase() === c.name.toLowerCase())?.label;

      const isInvalid = type === undefined || Object.values(columnTypes).includes(type);

      if (isInvalid) return;

      if (includeColumnType(type)) {
        newColumnTypes[i] = type;
      }
    });
    setColumnTypes(newColumnTypes);
  }, []);

  const deleteColumn = (index: number) => {
    const newColumns = [...columns];
    newColumns.splice(index, 1);
    const newColumnTypes = { ...columnTypes };
    delete newColumnTypes[index];
    Object.keys(newColumnTypes).forEach((k) => {
      if (+k > index) {
        newColumnTypes[+k - 1] = newColumnTypes[+k];
        delete newColumnTypes[+k];
      }
    });
    setColumnTypes(newColumnTypes);
    setColumns(newColumns);
  };

  const handleSelect = (v: any, index: number) => {
    //set all other columns equal to v.value in columnTypes to undefined
    const newColumnTypes = { ...columnTypes };
    Object.keys(columnTypes).forEach((k) => {
      if (columnTypes[+k] === v?.value) delete newColumnTypes[+k];
    });
    newColumnTypes[index] = v?.value as ExcelColumnType;
    setColumnTypes(newColumnTypes);
  };

  const handleSubmit = async () => {
    const typeValues = Object.values(columnTypes);
    if (
      typeValues.includes(ExcelColumnType.INFIX) &&
      !typeValues.includes(ExcelColumnType.LAST_NAME)
    ) {
      toast.error(t('toast.error.infixWithoutLastName'));
      return;
    }

    if (
      typeValues.includes(ExcelColumnType.FULL_NAME) &&
      (typeValues.includes(ExcelColumnType.FIRST_NAME) ||
        typeValues.includes(ExcelColumnType.LAST_NAME))
    ) {
      toast.error(t('toast.error.fullNameWithFirstNameOrLastName'));
      return;
    }

    const contacts = formatLocalContactInfosfromColumns(columns, columnTypes, invite.customFields);

    if (
      (typeValues.includes(ExcelColumnType.STREET) || typeValues.includes(ExcelColumnType.POSTAL_CODE) || typeValues.includes(ExcelColumnType.CITY))
      && !typeValues.includes(ExcelColumnType.COUNTRY)
    ) {
      setLocalContacts(contacts);
      onMissingCountry?.();
      return;
    }

    if (contacts.every((c) => isValidLocalContact(c, invite))) {
      await localContactService.createLocalContacts(contacts);
      toast.success(t('toast.success.importLocalContacts'));
      onSubmitSuccessful?.();
    } else setLocalContacts(contacts);
  };

  const options = fromInviteToExcelColumnTypes(invite)
    .map((k) => ({
      label: excelColumnTypeToString(k as ExcelColumnType),
      value: k as string,
    }))
    .concat(
      filterCustomLabelFields(invite.customFields).map((f) => ({
        label: f.label,
        value: f.label,
      })),
    );

  return (
    <div className="w-full flex flex-wrap gap-4">
      {columns.map(
        (c: ExcelColumn, i: number): JSX.Element => (
          <div key={i} className="w-72 h-fit border border-primary rounded-[20px]">
            <div className="flex flex-col bg-secondary-200 p-4 rounded-t-[20px]">
              <Select
                key={i}
                styles={{
                  control: (baseStyles: any, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
                    borderRadius: '9999px',
                    color: COLOR_PRIMARY_900,
                    backgroundColor: COLOR_SECONDARY_50,
                    boxShadow: 'none',
                    height: '40px',
                    '&:hover': {
                      borderColor: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
                    },
                  }),
                  option: (styles: any, { isSelected }) => {
                    return {
                      ...styles,
                      backgroundColor: isSelected ? COLOR_PRIMARY : COLOR_SECONDARY_50,
                      color: isSelected ? COLOR_SECONDARY_50 : COLOR_PRIMARY_900,
                      '&:hover': {
                        backgroundColor: isSelected ? COLOR_PRIMARY_300 : COLOR_PRIMARY_300,
                        color: COLOR_PRIMARY_900,
                      },
                      height: '40px',
                    };
                  },
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  dropdownIndicator: (baseStyles: any, state) => ({
                    ...baseStyles,
                    color: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
                  }),
                }}
                options={options}
                value={
                  columnTypes[i]
                    ? {
                      label: excelColumnTypeToString(columnTypes[i] as ExcelColumnType),
                      value: columnTypes[i] as string,
                    }
                    : null
                }
                onChange={(v: any) => handleSelect(v, i)}
                className="mb-1"
              />
              <p className="text-xs text-gray-500">
                {t('component.modal.importLocalContacts.selectColumn.columnName')}
              </p>
              <p className="bg-vera-green-lightest text-sm rounded-lg px-1 font-medium w-fit">
                “{c.name}”
              </p>
            </div>

            {/* Columns */}
            <div className="flex flex-col border-y border-primary overflow-scroll no-scrollbar max-h-32 shadow-inner">
              {c.rows.map((r, j) => (
                <div
                  key={j}
                  className={classNames('w-full px-4 h-8 block text-sm ', {
                    'bg-secondary-200': j % 2 !== 0,
                  })}>
                  {r || '-'}
                </div>
              ))}
            </div>
            <div className="flex bg-secondary-200 justify-end p-4 rounded-b-[20px]">
              <p
                onClick={() => deleteColumn(i)}
                className="font-semibold underline underline-offset-4 text-sm cursor-pointer">
                {t('component.modal.importLocalContacts.selectColumn.dontImport')}
              </p>
            </div>
          </div>
        ),
      )}

      {/* Buttons */}
      <div className="flex justify-end w-full ">
        <CancelSaveButton
          onCancel={() => onSubmitFailed?.()}
          onSave={handleSubmit}
          disabled={Object.keys(columnTypes).length !== columns.length}
        />
      </div>
    </div>
  );
}
