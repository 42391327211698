import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MomentType, ProfileDataType, ProfileField } from '../../../../types/Profile';
import { momentTypeIcons } from '../../../../utils/iconUtils';

interface MomentProps {
  field: ProfileField;
  handleClick: (active: boolean, field: ProfileField) => void;
  active: boolean;
}
export default function Moment({ field, handleClick, active }: MomentProps): JSX.Element {
  const { t } = useTranslation();
  const [year, month, day] = field.moment?.date.split('-') || field.birthDate?.split('-') || [];
  return (
    <div
      key={field.id!}
      onClick={() => handleClick(active, field)}
      className={classNames(
        'flex items-center border border-secondary-200 justify-between cursor-pointer w-full transition-all rounded-lg px-2 mr-6 py-1',
        {
          'bg-secondary-200': active,
        },
      )}>
      <div className="flex items-center gap-4">
        <div className="bg-secondary text-secondary-50 rounded-[6px] flex items-center justify-center p-1">
          {
            momentTypeIcons[
              (field.moment?.momentType || MomentType.CHILDBIRTH) as keyof typeof momentTypeIcons
            ]
          }
        </div>
        <div>
          <p>
            {field.moment?.momentType === MomentType.WEDDING
              ? t('form.userData.wedding')
              : field.dataType === ProfileDataType.BIRTHDATE
              ? t('form.userData.birthDateOwner')
              : t('form.userData.birthDateOther', { name: field.moment?.name })}
          </p>
          <p>{`${day}-${month}-${year}`}</p>
        </div>
      </div>
      <input
        type="checkbox"
        className="form-checkbox border-primary bg-secondary-50 h-5 w-5 text-primary-300 accent-primary-900 rounded-full focus:ring-0 focus:ring-transparent"
        checked={active}
      />
    </div>
  );
}
