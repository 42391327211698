import { useTranslation } from 'react-i18next';
import { FaHeartCrack } from 'react-icons/fa6';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import IconBadge from '../../icons/IconBadge';
import { LocalContactInfo } from '../../../../services/model/localContactService.model';
import { BusinessInvite, CustomField } from '../../../../services/model/inviteService.model';
import { invalidCustomFieldResponses, IsValidField, isValidLocalContact } from '../../../../utils/validationUtils';
import { ProfileDataType } from '../../../../types/Profile';
import { formatCustomFieldResponsesFromCustomFields } from '../../../../utils/formatUtils';
import localContactService from '../../../../services/localContactService';
import NameInput from '../../forms/profile/NameInput';
import ProfileFieldForm from '../../forms/ProfileFieldForm';
import CustomFieldsForm from '../../forms/CustomFieldsForm';
import Button from '../../buttons/Button';

export function RectifyContactViewHeader(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="flex items-center pb-2 mb-2">
      <IconBadge icon={FaHeartCrack} />

      <div className="flex flex-col ml-4 w-full ">
        <h1 className="text-3xl font-serif">
          {' '}
          {t('component.modal.importLocalContacts.rectify.title')}
        </h1>
        <p>{t('component.modal.importLocalContacts.rectify.subtitle')}</p>
      </div>
    </div>
  );
}

interface RectifyContactViewProps {
  localContacts: LocalContactInfo[];
  setLocalContacts: (contacts: LocalContactInfo[]) => void;
  invite: BusinessInvite;
  fileName: string;
  onSubmitSuccessful?: () => void;
}

export function RectifyContactView({
  localContacts,
  setLocalContacts,
  invite,
  fileName,
  onSubmitSuccessful,
}: RectifyContactViewProps): JSX.Element {
  const index = localContacts.findIndex((c) => !isValidLocalContact(c, invite));
  const invalidContacts = localContacts.filter((c) => !isValidLocalContact(c, invite));
  const contact = localContacts[index];

  const aliasInvalid = useRef<boolean>(!contact.firstName || !contact.lastName);
  const invalidCustomFields = useRef<CustomField[]>(
    invalidCustomFieldResponses(invite.customFields, contact.customFieldResponses),
  );
  const invalidFields = useRef<ProfileDataType[]>(
    contact.receivedData.filter((f) => !IsValidField(f)).map((f) => f.dataType),
  );
  const { t } = useTranslation();
  const [contactInfo, setContactInfo] = useState<LocalContactInfo>({
    ...contact,
    customFieldResponses: formatCustomFieldResponsesFromCustomFields(
      invite.customFields,
      contact.customFieldResponses,
    ),
  });

  const handleSubmit = async (include: boolean = true): Promise<void> => {
    const submission = [...localContacts];
    if (!include) submission.splice(index, 1);
    else submission[index] = contactInfo;

    await localContactService.createLocalContacts(submission);
    toast.success(t('toast.success.importLocalContacts'));
    onSubmitSuccessful?.();
  };

  const handleSave = () => {
    const newContacts = [...localContacts];
    newContacts[index] = contactInfo;
    setLocalContacts(newContacts);
  };

  const handleDelete = () => {
    const newContacts = [...localContacts];
    newContacts.splice(index, 1);
    setLocalContacts(newContacts);
  };

  const invalidIndex = invalidContacts.indexOf(localContacts[index]) + 1;
  const invalidTotal = invalidContacts.length;

  return (
    <>
      <div className="flex flex-col ">
        <h1 className="text-xl font-semibold mt-2">
          {t('component.modal.importLocalContacts.rectify.incorrectContact')}
          {`${invalidIndex}/${invalidTotal}`}
        </h1>
        <p className="text-xs mb-4">
          {t('component.modal.importLocalContacts.rectify.rowNumber')}{' '}
          <span className="bg-vera-green-lightest rounded-lg px-1">{fileName}</span>:{' '}
          <span className="font-semibold">{localContacts.indexOf(localContacts[index]) + 2}</span>
        </p>
        {aliasInvalid.current && (
          <h3 className="text-md font-semibold">
            {t('component.modal.importLocalContacts.rectify.incorrectFields')}
          </h3>
        )}

        <div className="flex flex-col gap-4">
          <NameInput
            alias={`${contactInfo?.firstName || ''}+${contactInfo?.lastName || ''}`}
            validate
            setAlias={(a) => {
              const [firstName, lastName] = a.split('+');
              setContactInfo({
                ...contactInfo,
                firstName: firstName || '',
                lastName: lastName || '',
              });
            }}
          />
          {!aliasInvalid.current && (
            <h3 className="text-md font-semibold">
              {t('component.modal.importLocalContacts.rectify.incorrectFields')}
            </h3>
          )}
          {contactInfo.receivedData
            .filter((f) => invalidFields.current.includes(f.dataType))
            .map((f) => (
              <ProfileFieldForm
                field={f}
                validate
                canBeEmpty
                onlyFull
                setField={(n) =>
                  setContactInfo({
                    ...contactInfo,
                    receivedData: contactInfo?.receivedData.map((x) =>
                      x.dataType === n.dataType ? n : x,
                    ),
                  })
                }
              />
            ))}
        </div>

        <CustomFieldsForm
          fields={invalidCustomFields.current}
          response={contactInfo.customFieldResponses}
          setResponse={(r) =>
            setContactInfo({
              ...contactInfo,
              customFieldResponses: r,
            })
          }
        />

        <div className="flex justify-center w-full border-t border-secondary-200 mt-4 pt-4">
          <p className="text-secondary-200 text-sm -mt-2">
            {t('component.modal.importLocalContacts.rectify.contactOf', {
              index: index + 1,
              count: localContacts.length,
            })}
          </p>
        </div>
        <div className="flex justify-end">
          <Button
            onClick={invalidIndex === invalidTotal ? () => handleSubmit(false) : handleDelete}
            className="underline">
            {invalidIndex === invalidTotal ? 'Delete and import' : t('general.delete')}
          </Button>
          <Button
            variant="primary"
            disabled={!isValidLocalContact(contactInfo, invite)}
            onClick={invalidIndex === invalidTotal ? () => handleSubmit(true) : handleSave}>
            {invalidIndex === invalidTotal ? 'Finish import' : t('general.next')}
          </Button>
        </div>
      </div>
    </>
  );
}
