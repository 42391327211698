import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-crop/dist/ReactCrop.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import PiwikPro from '@piwikpro/react-piwik-pro';
import Router from './routes/Router';
import { store, persistor } from './redux/store';
import { setConnections } from './redux/slices/connectionsSlice';
import { setTags } from './redux/slices/tagsSlice';
import { setState } from './redux/slices/applicationSlice';
import { getSelectedBusiness } from './hooks/business/useSelectedBusiness';
import businessService from './services/businessService';
import { setAdminInvites, setAdmins } from './redux/slices/adminSlice';
import i18n from './locales/i18n';
import inviteService from './services/inviteService';
import ScrollToTop from './hooks/effects/ScrollToTop';
import { setPersonalConnections, setProfileFields } from './redux/slices/userSlice';
import connectionService from './services/connectionService';
import OnboardingRide from './views/components/misc/OnboardingRide';


if (process.env.NODE_ENV !== 'development')
  PiwikPro.initialize(
    '9c636452-6ef2-4904-b9fd-08b50695e11b',
    'https://veraconnect.containers.piwik.pro',
  );

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <Router />
            <ScrollToTop />
            <div>
              <OnboardingRide />
              <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
                className="w-full mx-auto"
                progressClassName="invisible"
                style={{
                  width: 'full',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                toastStyle={{
                  backgroundColor: 'black',
                  color: 'white',
                  width: 'fit-content',
                  borderRadius: '1.5rem',
                  fontWeight: '600',
                }}
              />
            </div>
          </BrowserRouter>
        </LocalizationProvider>
      </PersistGate>
    </I18nextProvider>
  </Provider>,
);

//disable eslint
// eslint-disable-next-line
window.addEventListener('beforeunload', () => {
  if (!window.location.pathname.includes('payment')) store.dispatch(setConnections([]));
  store.dispatch(setTags([]));
  store.dispatch(setAdmins([]));
  store.dispatch(setAdminInvites([]));
  store.dispatch(setProfileFields([]));
  store.dispatch(setPersonalConnections([]));
  const business = getSelectedBusiness();
  store.dispatch(
    setState({ ...store.getState().application, businesses: business ? [business] : [] }),
  );
});

window.addEventListener('load', () => {
  businessService.fetchBusinesses().then(() => {
    if (getSelectedBusiness()) {
      inviteService.fetchInvite();
      connectionService.getConnections();
    }
  });
});
