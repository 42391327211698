import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../buttons/Button';

export default function PremiumRequiredCard() {
  const navigate = useNavigate();

  return (
    <div
      className="bg-white rounded-lg py-6 px-20 shadow-lg flex flex-col items-center justify-center gap-2 max-w-lg">
      <img src="/assets/figures/heart.svg" alt="Alt" className="max-w-md" />
      <h1 className="font-semibold font-serif text-center text-2xl flex items-center gap-1">
        Subscription required
      </h1>
      <p className="text-center py-4">To see attendance details, your community needs to have a Base or a Pro
        subscription.</p>
      <Button onClick={() => navigate('/settings/subscriptions')} variant="secondary" className="text-lg rounded-full font-bold px-6">
        Upgrade now
      </Button>
    </div>
  );
}
