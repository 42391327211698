import { createSlice } from '@reduxjs/toolkit';
import { TagState } from '../../types/Tag';

const initialState: TagState[] = [];

/**
 * Redux slice for tags
 */
const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    /**
     * Adds a tag to the state
     * @param state the current state
     * @param action holds the new tag
     */
    addTag: (state, action): void => {
      const newState = [...state, action.payload];
      // alphabetize tags
      newState.sort((a, b): number => a.name.localeCompare(b.name));
      state.splice(0, state.length, ...newState);
    },
    /**
     * Removes a tag from the state
     * @param state the current state
     * @param action holds the id of the tag to remove
     */
    removeTag: (state, action): void => {
      const index: number = state.findIndex((tag): boolean => tag.tagId === action.payload);
      if (index !== -1) state.splice(index, 1);
    },
    /**
     * Updates a tag in the state
     * @param state the current state
     * @param action holds the updated tag and its id
     * Currently not used but may be used in the future
     */
    updateTag: (state, action): void => {
      const index = state.findIndex((tag): boolean => tag.tagId === action.payload.tagId);
      state[index] = action.payload;
      // alphabetize tags
      state.sort((a, b): number => a.name.localeCompare(b.name));
    },
    /**
     * Sets the tags in the state
     * @param state the current state
     * @param action the tags to set
     * Sets the state to be the fetched tags
     */
    setTags: (state, action): void => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { addTag, removeTag, updateTag, setTags } = tagSlice.actions;
export default tagSlice.reducer;
