import React from 'react';
import { IconType } from 'react-icons';
import { twMerge } from 'tailwind-merge';
import Icon from './Icon';

interface IconBadgeProps {
  icon: IconType;
  className?: string;
  iconClassName?: string;
}

export default function IconBadge({ icon, className, iconClassName }: IconBadgeProps) {
  return (
    <div
      className={twMerge(
        'bg-secondary flex-shrink-0 text-secondary-50 rounded-[6px] w-10 h-10 flex',
        className,
      )}>
      <Icon
        icon={icon}
        className={twMerge('inline-block mx-auto my-auto w-5 h-5', iconClassName)}
        aria-hidden
      />
    </div>
  );
}
