import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';
import { BUTTON_STYLE, BUTTON_STYLE_COLLAPSE, LABEL_STYLE, LABEL_STYLE_COLLAPSE } from './style';
import HoverButton from '../../../buttons/HoverButton';

interface NavigationProps {
  id?: string;
  icon: JSX.Element;
  name: string;
  path: string;
  textClassName?: string;
  open?: boolean;
  state?: object;
}

export default function Navigation({
  id = '',
  icon,
  name,
  path,
  textClassName = '',
  open = false,
  state,
}: NavigationProps): JSX.Element {
  const location = useLocation();
  return (
    <HoverButton id={id} className="w-full" clickable={false}>
      <HoverButton.Button>
        <Link
          to={path}
          key={path}
          state={state}
          className={twMerge(
            BUTTON_STYLE,
            !open && BUTTON_STYLE_COLLAPSE,
            classNames({
              'bg-primary-300 hover:bg-opacity-100': location.pathname === path,
            }),
          )}>
          {icon}
          <p
            className={twMerge(
              LABEL_STYLE,
              !open && LABEL_STYLE_COLLAPSE,
              'font-semibold',
              textClassName,
            )}>
            {name}
          </p>
        </Link>
      </HoverButton.Button>
      {!open && (
        <HoverButton.Div className="left-[150%] group-[.collapsed]:block hidden transition-50 rounded-[6px] top-[50%] -translate-y-[50%] px-3 py-2 bg-primary-900">
          <p className="text-sm whitespace-nowrap text-secondary-50">{name}</p>
          <div className="h-[15px]  w-[15px] rotate-45 bg-primary-900 absolute top-[50%] -translate-y-[50%] -left-1 rounded-[2px]" />
        </HoverButton.Div>
      )}
    </HoverButton>
  );
}
