import { combineReducers } from 'redux';
import pendingConnectionReducer, { PendingConnectionsType } from './slices/pendingConnectionsSlice';
import connectionReducer, { Connection } from './slices/connectionsSlice';
import tagReducer from './slices/tagsSlice';
import applicationReducer, { ApplicationType } from './slices/applicationSlice';
import adminReducer, { AdminState } from './slices/adminSlice';
import { TagState } from '../types/Tag';
import userReducer, { UserState } from './slices/userSlice';
import eventReducer, { EventState } from './slices/eventSlice';

export type RootState = {
  connections: Connection[];
  pendingConnections: PendingConnectionsType[];
  application: ApplicationType;
  tags: TagState[];
  admins: AdminState;
  user: UserState;
  events: EventState;
};

const combinedReducer = combineReducers<RootState>({
  connections: connectionReducer,
  pendingConnections: pendingConnectionReducer,
  tags: tagReducer,
  application: applicationReducer,
  admins: adminReducer,
  user: userReducer,
  events: eventReducer,
});

const rootReducer = (state: any, action: any): any => {
  if (action.type === 'application/logout') {
    localStorage.removeItem('persist:root');
    state = undefined;
  }
  if (action.type === 'application/wipeSelectedBusiness') {
    state.profile = undefined;
    state.pendingConnections = undefined;
    state.tags = undefined;
    state.connections = undefined;
    state.admins = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
