import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import nl from './nl.json';
import en from './en.json';
import { store } from '../redux/store';
import { RootState } from '../redux/reducers';
import { APPLICATION_VERSION } from '../constants';

i18n.use(initReactI18next).init({
  fallbackLng: 'nl',
  debug: false,
  resources: {
    nl,
    en,
  },
  ns: 'common',
  defaultNS: 'common',
  interpolation: { escapeValue: false },
});

const versionNamespace = APPLICATION_VERSION.toLowerCase();

i18n.addResourceBundle('nl', 'common', i18n.getResourceBundle('nl', versionNamespace), true, true);

i18n.addResourceBundle('en', 'common', i18n.getResourceBundle('en', versionNamespace), true, true);

store.subscribe((): void => {
  const state = store.getState() as RootState;
  if (i18n.language !== state.application.language) i18n.changeLanguage(state.application.language);
});

export default i18n;
