import React, { useEffect, type ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { RootState } from '../redux/reducers';
import businessService from '../services/businessService';
import Business from '../types/business';

/*
 * If the user is not authenticated, they will be redirected to the path provided.
 * Otherwise, the user will be able to access the route.
 *
 * @param {boolean} isAuthenticated - Whether the user is authenticated.
 * @param {string} path - The path to redirect to if the user is not authenticated.
 * @returns {ReactElement} - The react-router-dom <Outlet />
 * component if the user is authenticated, otherwise a <Navigate /> component.
 */
export default function AuthenticatedRoute(): ReactElement {
  const [loading, setLoading] = React.useState<boolean>(true);
  const businesses = useSelector((state: RootState): Business[] => state.application.businesses!);
  const isAuthenticated = useSelector(
    (state: RootState): boolean => state.application.isAuthenticated!,
  );

  useEffect((): void => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }
    const fetchBusinesses = async (): Promise<void> => {
      if (!businesses) await businessService.fetchBusinesses();
      setLoading(false);
    };
    fetchBusinesses();
  }, []);

  if (loading) return <div />;
  if (!isAuthenticated) return <Navigate to="/" />;
  return <Outlet />;
}
