import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import ModalLayout from '../layouts/ModalLayout';
import inviteService from '../../../services/inviteService';
import { ProfileDataType, ProfileField } from '../../../types/Profile';
import { CustomFieldResponses } from '../../../services/model/inviteService.model';
import userService from '../../../services/userService';
import UserDataForm from '../forms/userData/UserDataForm';
import CustomFieldsForm from '../forms/CustomFieldsForm';
import Button from '../buttons/Button';
import connectionService from '../../../services/connectionService';

interface SharedWithCommunityModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function SharedWithCommunityModal({
  open,
  setOpen,
}: SharedWithCommunityModalProps): JSX.Element {
  const { inviteFields: invite, businessId } = useSelectedBusiness() ?? {};
  const { t } = useTranslation();

  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>({});

  useEffect(() => {
    if (!open || !businessId) return;
    Promise.all([
      inviteService.fetchInvite(),
      userService.getSharedDataWithBusiness(businessId),
      userService.getUserData(),
    ]).then(([{ customFields }, sharedData, userData]) => {
      const { propertyIds, customFieldResponses: cfr, dataForMembers } = sharedData;
      const sharedDataWithTarget = userData.filter((field) => propertyIds.includes(field.id!));
      const sharedCustomFields = customFields.reduce((acc, field) => {
        acc[field.customFieldId!] = cfr[field.customFieldId!];
        return acc;
      }, {} as CustomFieldResponses);
      setCustomFieldResponses(sharedCustomFields);
      setSelectedData(sharedDataWithTarget);
    });
  }, [open]);

  const handleSubmit = () => {
    if (!invite) return;
    const { mandatoryFields } = invite;
    const mandatoryCustom = invite.customFields.filter((f) => f.mandatory);
    if (
      mandatoryCustom.some((f) => !customFieldResponses[f.customFieldId!]) ||
      mandatoryFields.some((f) => !selectedData.find((d) => d.dataType === f))
    ) {
      toast.error(t('toast.error.mandatoryStar'));
      return;
    }
    userService
      .updateBusinessConnection(businessId!, {
        propertyIds: selectedData.map((f) => f.id!),
        customFieldResponses,
        dataForMembers: selectedData.map((f) => f.id!),
      })
      .then(() => {
        setOpen(false);
        connectionService.getConnections(true);
      });
  };


  if (open && invite !== undefined
    && !invite.mandatoryFields.length && !invite.optionalFields.length && !invite.customFields.length) {
    setOpen(false);
    toast.warn(t('page.settings.requested.noFields'));
    return <></>;
  }

  return (
    <ModalLayout
      className="bg-secondary-50 min-w-[300px] w-[45rem] p-8  z-50 rounded-2xl relative gap-6 flex flex-col"
      open={open}
      setOpen={setOpen}>
      <UserDataForm
        fetchData={false}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        onePerDataType
        onlyBirthDate
        mandatoryFields={invite?.mandatoryFields ?? []}
        nonRequestedFields={[
          ...(invite?.nonRequestedFields ?? []),
          ProfileDataType.SOCIAL,
          ProfileDataType.MOMENT,
        ]}
      />
      <CustomFieldsForm
        fields={invite?.customFields ?? []}
        response={customFieldResponses}
        includeMandatory
        setResponse={setCustomFieldResponses}
      />
      <Button onClick={handleSubmit} variant="primary" className="w-full justify-center">
        {t('general.save')}
      </Button>
    </ModalLayout>
  );
}
