import React from 'react';
import classNames from 'classnames';
import { ProfileField } from '../../../../types/Profile';
import { profileDataTypeIcons } from '../../../../utils/iconUtils';
import { profileFieldToString } from '../../../../utils/stringUtils';

interface FieldProps {
  field: ProfileField;
  handleClick: (active: boolean, field: ProfileField) => void;
  active: boolean;
}

export default function Field({ field, handleClick, active }: FieldProps): JSX.Element {
  return (
    <div
      key={field.id!}
      onClick={() => handleClick(active, field)}
      className={classNames(
        'flex items-center border border-secondary-200 justify-between cursor-pointer w-full transition-all rounded-lg px-2 mr-6',
        {
          'bg-secondary-200': active,
        },
      )}>
      <div className="flex gap-2 items-start py-2 ">
        <div className="bg-secondary text-secondary-50 rounded-[6px] flex items-center justify-center p-1">
          {profileDataTypeIcons[field.dataType as keyof typeof profileDataTypeIcons]}
        </div>
        <label
          className="text-sm text-normal text-primary-900 h-full my-auto">
          {profileFieldToString(field)
            .split('\n')
            .map((x) => (
              <p>{x}</p>
            ))}
        </label>
      </div>
      <input
        type="checkbox"
        className="form-checkbox border-primary bg-secondary-50 h-5 w-5 text-primary-300 accent-primary-900 rounded-full focus:ring-0 focus:ring-transparent"
        checked={active}
      />
    </div>
  );
}
