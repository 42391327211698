import httpService from './httpService';
import { ProfileField } from '../types/Profile';
import { PersonalConnection } from '../types/misc';
import {
  setPersonalConnections,
  setProfileFields,
  setProfilePicture,
} from '../redux/slices/userSlice';
import { store } from '../redux/store';
import { RootState } from '../redux/reducers';
import {
  SharedBusinessData,
  SharedBusinessDataResponse,
  SharedDataResponse,
  UpdateBusinessConnectionOptions,
} from './model/userService.model';
import { CRMIntegration, CRMType, CrmUser } from '../types/CRMIntegration';

/**
 * The user service, contains all logic for user actions
 */
const userService = {
  /**
   * Deletes the user account from the server
   * @param password The password of the user
   * @returns A promise that resolves when the account is deleted
   * @throws An error if the password is incorrect
   */
  deleteAccount: async (password: string): Promise<void> => {
    const config = {
      headers: {
        'X-Password': password,
      },
    };
    await httpService.delete('/business/account', config);
  },

  /**
   * Changes the password of the account from the server
   * @param password The current password of the user
   * @param newPasswordArgument The new password of the user
   * @returns A promise that resolves when the account is deleted
   * @throws An error if the password is incorrect
   */
  changePassword: async (password: string, newPasswordArgument: string): Promise<number> => {
    const config = {
      currentPassword: password,
      newPassword: newPasswordArgument,
    };
    const response = await httpService.put('/business/account/password', config);
    const { status }: { status: number } = response;
    return status;
  },

  /**
   * Gets the list of CRM integrations for the current user's business.
   * @async
   * @returns {Promise<{ crm: CRMType; connected: boolean }[]>} A Promise that resolves with an array of CRM integrations.
   * @throws {Error} If there was an error getting the CRM integrations.
   */
  getIntegrations: async (): Promise<CRMIntegration[]> => {
    const response = await httpService.get('/business/crm');
    const { data }: { data: CRMIntegration[] } = response;
    return data;
  },

  /**
   * Gets the list of CRM integrations for the current user's business.
   * @async
   * @returns {Promise<{ crm: CRMType; connected: boolean }[]>} A Promise that resolves with an array of CRM integrations.
   * @throws {Error} If there was an error getting the CRM integrations.
   */
  getUsers: async (crmIntegration: CRMIntegration): Promise<CrmUser[]> => {
    if (!crmIntegration.connected) return Promise.resolve([]);

    return httpService
      .post<CrmUser[]>('/business/crm/users', { crm: crmIntegration.crm })
      .then(({ data }) => data);
  },

  inviteCrmUsers: (crm: CRMType, crmUserIds: string[]): Promise<void> => {
    return httpService.post<void>('/business/crm/invite', { crm, crmUserIds }).then(() => {});
  },

  /**
   * Connects the current user's business to a CRM.
   * @async
   * @param {CRMIntegration} crmIntegration - The crmIntegration object containing the CRM to connect to.
   * @returns {Promise<void>} A Promise that resolves when the connection has been established.
   * @throws {Error} If there was an error connecting to the CRM.
   */
  connectToCRM: async (crmIntegration: CRMIntegration, authParam: unknown = {}): Promise<void> => {
    if (crmIntegration.connected) return;
    if (crmIntegration.loginUrl) {
      window.location.href = crmIntegration.loginUrl;
    } else {
      await httpService.post<void>('/business/crm', { crm: crmIntegration.crm, auth: authParam });
    }
  },

  /**
   * Updates the current user's business CRM integration.
   * @async
   * @param crmIntegration - The CRM integration to update.
   * @param data - The data to update the CRM integration with.
   * @returns A Promise that resolves when the CRM integration has been updated.
   * @throws {Error} If there was an error connecting to the CRM.
   */
  updateCRMIntegration: async (
    crmIntegration: CRMIntegration,
    data: { auth?: unknown; additional?: unknown },
  ): Promise<void> => {
    if (!crmIntegration.connected) return;

    await httpService.patch<void>('/business/crm', { crm: crmIntegration.crm, ...data });
  },

  handleCRMCallback: async (): Promise<void> => {
    await httpService.post<void>(
      '/auth/oauth/callback?code=na1-f946-c177-4628-9534-e55f7ff6aace&state=1218==DHUBSPOT',
    );
  },

  /**
   * Disconnects the current user's business from a CRM.
   * @async
   * @param {CRMIntegration} crmIntegration - The crmIntegration object containing the CRM to disconnect from.
   * @returns {Promise<void>} A Promise that resolves when the connection has been disconnected.
   * @throws {Error} If there was an error disconnecting from the CRM.
   */
  disconnectFromCRM: async (crmIntegration: CRMIntegration): Promise<void> => {
    if (!crmIntegration.connected) return;
    await httpService.delete('/business/crm', { data: { crm: crmIntegration.crm } });
  },

  getProfilePicture: async (): Promise<string> => {
    return httpService
      .get('/users/@me/avatar')
      .then(({ data }): string => {
        store.dispatch(setProfilePicture(data.path));
        return data.path;
      })
      .catch(() => '');
  },

  getConnections(): Promise<PersonalConnection[]> {
    return httpService.get<PersonalConnection[]>('/connection/general').then(({ data }) => {
      store.dispatch(setPersonalConnections(data));
      return data;
    });
  },

  getUserData(): Promise<ProfileField[]> {
    return httpService.get<ProfileField[]>('/getData').then(({ data }): ProfileField[] => {
      store.dispatch(setProfileFields(data));
      return data;
    });
  },
  setFields(field: ProfileField[]): Promise<ProfileField[]> {
    return httpService.post<ProfileField[]>('/setData', field).then(({ data }): ProfileField[] => {
      const difference = data.filter(
        (nf) => !(store.getState() as RootState).user.fields?.map((f) => f.id).includes(nf.id),
      );
      store.dispatch(setProfileFields(data));
      return difference;
    });
  },

  getSharedDataFromUser(userId: number): Promise<ProfileField[]> {
    return httpService
      .get<SharedDataResponse>(`/getAllSharedInfo/${userId}`)
      .then(({ data }): ProfileField[] => data.sharedDataByTarget);
  },

  updateBusinessConnection(
    businessId: number,
    args: SharedBusinessData,
    options: UpdateBusinessConnectionOptions = {},
  ): Promise<void> {
    const body = {
      ...args,
      ...options,
    };

    return httpService.put<void>(`/b2c-connection/${businessId}`, body).then();
  },
  getSharedDataWithBusiness(businessId: number): Promise<SharedBusinessData> {
    return httpService.get<SharedBusinessDataResponse>(`/b2c-connection/shared/${businessId}`).then(
      ({ data }): SharedBusinessData => ({
        ...data,
        propertyIds: data.propertyIds.map((pId: number): string => pId.toString()),
        dataForMembers: data.dataForMembers.map((d: number): string => d.toString()),
      }),
    );
  },
};

export default userService;
