import React from 'react';
import { CRMIntegration, CRMType } from '../../../../types/CRMIntegration';
import EBoekhoudenAdditionalDataModal, { AdditionalData as EBoekhoudenAdditionalData } from './eboekhouden/AdditionalDataModal';

interface AdditionalDataModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    crmIntegration: CRMIntegration;
    onSubmitted: (additional: unknown) => void;
    initialData?: unknown;
}  

export default function AdditionalDataModal({ open, setOpen, crmIntegration, onSubmitted, initialData }: AdditionalDataModalProps): JSX.Element {
    if (crmIntegration.crm === CRMType.E_BOEKHOUDEN) {
        return <EBoekhoudenAdditionalDataModal open={open} setOpen={setOpen} onSubmitted={onSubmitted} initialData={initialData as EBoekhoudenAdditionalData} />
    }

    return <></>;
}
  