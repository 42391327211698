import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Connection = {
  id: string;
  userId?: number;
  fields: {
    FIRST_NAME: string;
    LAST_NAME: string;
    [key: string]: string;
  };
  tags: string[];
  type: ConnectionType;
  note: string;
  verificationLabel: string;
  inviteSent?: boolean;
};

export enum ConnectionType {
  LOCAL_CONTACT = 'LOCAL_CONTACT',
  B2C_CONNECTION = 'B2C_CONNECTION',
  PERSONAL_MOMENT_CONNECTION = 'PERSONAL_MOMENT_CONNECTION',
}

const initalState: Connection[] = [];

const connectionsSlice = createSlice({
  name: 'connections',
  initialState: initalState,
  reducers: {
    /*
     * Sets the connections in the store
     * @param {ConnectionType[]} state - The current state
     * @param {PayloadAction<ConnectionType[]>} action - The action containing the new state
     * @returns {void}
     */
    setConnections: (state, action: PayloadAction<Connection[]>): void => {
      state.splice(0, state.length, ...action.payload);
    },

    deleteConnections: (
      state,
      action: PayloadAction<{ ids: string[]; type: ConnectionType }>,
    ): void => {
      const { ids, type } = action.payload;
      const newState = state.filter(
        (connection) => !(ids.includes(connection.id) && connection.type === type),
      );
      state.splice(0, state.length, ...newState);
    },

    assignTagToConnections: (
      state,
      action: PayloadAction<{ tag: string; connections: string[]; type: ConnectionType }>,
    ): void => {
      const { tag, connections, type } = action.payload;
      connections.forEach((id): void => {
        const index = state.findIndex(
          (connection): boolean => connection.id === id && connection.type === type,
        );
        if (index !== -1 && !state[index].tags.includes(tag)) state[index].tags.push(tag);
      });
    },

    removeTagFromConnection(
      state,
      action: PayloadAction<{ tag: string; connection: string; type: ConnectionType }>,
    ): void {
      const { tag, connection, type } = action.payload;
      const index = state.findIndex(
        (c): boolean => c.id === connection && c.type === type && c.tags.includes(tag),
      );
      if (index !== -1) state[index].tags.splice(state[index].tags.indexOf(tag), 1);
    },

    removeTagFromAllConnections: (state, action: PayloadAction<string>): void => {
      const tag = action.payload;
      state.forEach((connection): void => {
        const index = connection.tags.findIndex((t): boolean => t === tag);
        if (index !== -1) connection.tags.splice(index, 1);
      });
    },

    addConnections: (state, action: PayloadAction<Connection[]>): void => {
      state.push(...action.payload);
    },

    updateConnection: (state, action: PayloadAction<Connection>): void => {
      const index = state.findIndex(
        (connection): boolean =>
          connection.id === action.payload.id && connection.type === action.payload.type,
      );
      if (index !== -1) state[index] = action.payload;
    },

    updateNote(
      state,
      action: PayloadAction<{ id: string; type: ConnectionType; note: string }>,
    ): void {
      const { id, type, note } = action.payload;
      const index = state.findIndex(
        (connection): boolean => connection.id === id && connection.type === type,
      );
      if (index !== -1) state[index].note = note;
    },
    setContactsToInviteSent: (state, action: PayloadAction<string[]>): void => {
      const ids = action.payload;
      ids.forEach((id): void => {
        const index = state.findIndex(
          (connection): boolean =>
            connection.id === id && connection.type === ConnectionType.LOCAL_CONTACT,
        );
        if (index !== -1) state[index].inviteSent = true;
      });
    },
  },
});

export const {
  setConnections,
  deleteConnections,
  assignTagToConnections,
  removeTagFromAllConnections,
  addConnections,
  updateConnection,
  removeTagFromConnection,
  updateNote,
  setContactsToInviteSent,
} = connectionsSlice.actions;
export default connectionsSlice.reducer;
