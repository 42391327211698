import React from 'react';
import ModalLayout from '../layouts/ModalLayout';

/**
 * @typedef {object} QrProps
 * @property {boolean} show - Whether or not to show the modal
 * @property {string} qrCode - The QR code to display
 * @property {() => void} onCancel - Callback to close the modal
 */
type QrProps = {
  open: boolean;
  qrCode: string;
  setOpen: (open: boolean) => void;
};

export default function QrModal({ qrCode, open, setOpen }: QrProps): JSX.Element {
  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="rounded-[20px] bg-secondary-200  max-w-[600px]  p-6">
      <h1 className="text-3xl font-semibold font-serif text-center mb-4">QR Code</h1>
      <div className="flex justify-center">
        <img src={qrCode} alt="QR Code" className="w-80 rounded-[20px]" data-testid="qr-code" />
      </div>
    </ModalLayout>
  );
}
