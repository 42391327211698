import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Admin, AdminInvite, AdminRole } from '../../types/business';
import { AdminInviteData } from '../../services/model/adminService.model';

export interface AdminState {
  admins: Admin[];
  invites: AdminInvite[];
}
const initialState: AdminState = {
  admins: [],
  invites: [],
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdmins: (state, action: PayloadAction<Admin[]>): void => {
      state.admins = action.payload;
    },
    setAdminInvites: (state, action: PayloadAction<AdminInvite[]>): void => {
      state.invites = action.payload;
    },

    deleteAdmins: (state, action: PayloadAction<number[]>): void => {
      state.admins = state.admins.filter((admin) => !action.payload.includes(admin.adminId!));
    },

    deleteAdminInvites: (state, action: PayloadAction<number[]>): void => {
      state.invites = state.invites.filter((invite) => !action.payload.includes(invite.inviteId!));
    },

    updateRole: (state, action: PayloadAction<{ adminId: number; role: AdminRole }>): void => {
      const admin = state.admins.find((a) => a.adminId === action.payload.adminId);
      if (!admin) return;
      admin.role = action.payload.role;
    },
  },
});

export const { setAdmins, setAdminInvites, deleteAdminInvites, deleteAdmins, updateRole } = adminSlice.actions;
export default adminSlice.reducer;
