import { CustomField, CustomFieldType, CustomLabelField } from '../services/model/inviteService.model';

export function filterCustomLabelFields(fields: CustomField[]): CustomLabelField[] {
  if (!fields) return [];
  return fields.filter((field) =>
    [CustomFieldType.MULTIPLE_CHOICE, CustomFieldType.TEXT].includes(field.type),
  ) as CustomLabelField[];
}

export function filterBySearch<T extends Record<string, any>>(items: T[], search: string, ...propertiesToCompare: (keyof T)[]): T[] {
  if (!search) return items;
  const searchLower = search.toLowerCase();

  return items.filter((item) => {
    return propertiesToCompare.some((property) => {
      const itemString = item[property].toLowerCase();
      return itemString.includes(searchLower);
    });
  });
}
