import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useEffectAfterFirstRender from '../../../../hooks/effects/useEffectAfterFirstRender';
import useDelayedEffect from '../../../../hooks/effects/useDelayedEffect';

interface NameInputProps {
  alias: string;
  setAlias: (alias: string) => void;
  mandatory?: boolean;
  validate?: boolean;
}
export default function NameInput({
  alias,
  setAlias,
  mandatory = false,
  validate = false,
}: NameInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  let firstName = '';
  let lastName = '';
  if (alias.includes('+')) [firstName, lastName] = alias.split('+');

  useDelayedEffect(() => {
    if (!validate) return;
    if (!firstName && !lastName) {
      setError(t('invalid.empty'));
      return;
    }
    if (!firstName) {
      setError(t('invalid.firstName'));
      return;
    }
    if (!lastName) {
      setError(t('invalid.lastName'));
      return;
    }
    setError('');
  }, [alias, validate]);

  useEffectAfterFirstRender(() => {
    if (!firstName && !lastName) setAlias('');
  }, [firstName, lastName]);

  return (
    <div className="relative flex w-full flex-row gap-2">
      <div className="flex w-full flex-col">
        <label className="mb-1 pr-2 font-medium">
          {t('general.firstName')} {mandatory && '*'}
        </label>
        <input
          type="text"
          name="firstName"
          placeholder={t('general.firstName')}
          className={classNames(
            'h-10 w-full rounded-full border px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
            {
              '!border-error': error,
            },
          )}
          value={firstName}
          onChange={(e) => setAlias(`${e.target.value}+${lastName}`)}
        />
      </div>
      <div className="flex w-full flex-col">
        <label className="mb-1 pr-2 font-medium">
          {t('general.lastName')} {mandatory && '*'}
        </label>
        <input
          type="text"
          name="lastName"
          placeholder={t('general.lastName')}
          value={lastName}
          className={classNames(
            'h-10 w-full rounded-full border px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
            {
              '!border-error': error,
            },
          )}
          onChange={(e) => setAlias(`${firstName}+${e.target.value}`)}
        />
      </div>
      <p className="absolute right-1 top-0 text-sm text-error transition-all">{error}</p>
    </div>
  );
}
