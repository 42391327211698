import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlineCalendar, HiUserGroup } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { FaRegClock, FaWhatsapp } from 'react-icons/fa';
import PageHeader from '../components/misc/PageHeader';
import Avatar from '../components/misc/Avatar';
import { Event } from '../../types/event';
import useSelectedBusiness from '../../hooks/business/useSelectedBusiness';
import IconBadge from '../components/icons/IconBadge';
import Button from '../components/buttons/Button';
import connectionService from '../../services/connectionService';
import { RootState } from '../../redux/reducers';
import {
  PendingBadge,
  UnverifiedBadge,
  VerifiedBadge,
} from '../components/icons/ConnectionTypeBadge';
import { ConnectionType } from '../../redux/slices/connectionsSlice';
import ApiImage from '../components/misc/ApiImage';
import eventService from '../../services/eventService';
import useEvents from '../../hooks/business/useEvents';
import eventUtils from '../../utils/eventUtils';
import { isCommunity } from '../../constants';

export default function OverviewPage() {
  const business = useSelectedBusiness();
  const { t } = useTranslation();
  const {
    connections,
    application: { onboardingStep },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const event = useEvents()
    .filter((e) => !e.isDatePicker)
    .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())[0] as
    | Event
    | undefined;

  if (!business) return <></>;

  useEffect(() => {
    console.log(onboardingStep);
    if (onboardingStep !== undefined) navigate('/contacts');
  }, []);

  useEffect(() => {
    connectionService.getConnections();
    eventService.getUpcomingEvents();
  }, []);

  return (
    <>
      <PageHeader
        title={t('page.overview.title')}
        className="mb-6 pb-4 border-b border-primary-300"
      />
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="w-full flex flex-col bg-secondary-200 border-2 border-secondary-200 shadow-[0px_4px_4px_0px_#EDD5C940] bg-opacity-50 rounded-[20px] px-4 py-5 gap-2">
          <h2 className="font-serif text-[20px]">{t('page.overview.information')}</h2>
          <div className="flex gap-4 items-center text-wrap">
            <Avatar
              src={business.picture ?? ''}
              className="w-[75px] h-[75px] text-2xl"
              alias={business?.name}
            />
            <p className="text-[17px] font-medium">{business.name}</p>
          </div>
          <p className="text-[15px] font-medium">{business.description}</p>
          {business.communityLink && (
            <div className="flex gap-2 items-center">
              <IconBadge icon={FaWhatsapp} className="w-7 h-7" />
              <p className="text-[15px] font-medium">{business.communityLink}</p>
            </div>
          )}
          <Link to="/settings" className="ml-auto mt-auto">
            <Button variant="primary" className="text-[15px] mt-2">
              {t('page.overview.more')}
            </Button>
          </Link>
        </div>
        <div className="w-full flex flex-col bg-secondary-200 border-2 border-secondary-200 shadow-[0px_4px_4px_0px_#EDD5C940] bg-opacity-50 rounded-[20px] px-4 py-5 gap-3">
          <h2 className="font-serif text-[20px]">Contacts</h2>
          <div className="flex gap-2 items-center">
            <IconBadge icon={HiUserGroup} className="w-7 h-7" />
            <p className="text-[15px] font-medium">
              {connections.length} {t('general.total')}
            </p>
          </div>

          <div className="flex gap-1 items-center text-[15px] font-medium">
            <VerifiedBadge />
            {
              connections.filter((c) =>
                [ConnectionType.B2C_CONNECTION, ConnectionType.PERSONAL_MOMENT_CONNECTION].includes(
                  c.type,
                ),
              ).length
            }{' '}
            {t('component.verifiedDisplay.verified')}
          </div>
          <div className="flex gap-1 items-center text-[15px] font-medium">
            <PendingBadge />
            {
              connections.filter((c) => c.type === ConnectionType.LOCAL_CONTACT && c.inviteSent)
                .length
            }{' '}
            {t('component.verifiedDisplay.pending')}
          </div>
          <div className="flex gap-1 items-center text-[15px] font-medium">
            <UnverifiedBadge />
            {
              connections.filter((c) => c.type === ConnectionType.LOCAL_CONTACT && !c.inviteSent)
                .length
            }{' '}
            {t('component.verifiedDisplay.unverified')}
          </div>
          <Link to="/contacts" className="ml-auto mt-auto">
            <Button variant="primary" className="text-[15px]">
              {t('page.overview.more')}
            </Button>
          </Link>
        </div>
        {isCommunity && (
          <div className="w-full flex flex-col bg-secondary-200 border-2 gap-4 border-secondary-200 shadow-[0px_4px_4px_0px_#EDD5C940] bg-opacity-50 rounded-[20px] px-4 py-5">
            <div className="flex items-center justify-between gap-2">
              <h2 className="font-serif text-[20px]">{t('page.overview.events')}</h2>
              {event && (
                <p className="text-opacity-50 text-right text-[13px]">{t('page.overview.nextEvent')}</p>
              )}
            </div>
            {event ? (
              <>
                <div className="flex gap-4 items-center -mt-2">
                  {event?.picture && (
                    <ApiImage path={event.picture} className="w-[75px] h-[75px] rounded-[8px]" />
                  )}
                  <p className="text-[17px] font-medium">{event?.title}</p>
                </div>
                <p className="text-[15px] font-medium -mt-2">{event.description}</p>
                <div className="flex gap-2 items-center">
                  <IconBadge icon={HiOutlineCalendar} className="w-7 h-7" />
                  <p className="text-[15px] font-medium">{eventUtils.formatDate(event, 'date')}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <IconBadge icon={FaRegClock} className="w-7 h-7" />
                  <p className="text-[15px] font-medium">{eventUtils.formatDate(event, 'time')}</p>
                </div>
              </>
            ) : (
              <>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/figures/drop_sad.svg`}
                  alt="Vera"
                  className="w-[25%] mx-auto mt-2"
                />
                <p className="mx-auto text-[13px] font-medium">{t('page.overview.noEvents')}</p>
              </>
            )}
            <Link to="/events" className="ml-auto mt-auto">
              <Button variant="primary" className="text-[15px] mt-2">
                {t('page.overview.more')}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
