import { createTransform } from 'redux-persist';
import { EventState } from './slices/eventSlice';
import { Event } from '../types/event';

export const eventTransform = createTransform(
  (incoming: EventState): EventState => incoming,
  (outgoing: EventState): EventState => {
    const eventMap = Object.fromEntries(
      Object.entries(outgoing.eventMap).map(([key, value]) => {
        return [key, value.map(addDateToEvent)];
      }),
    );
    return { ...outgoing, eventMap };
  },

  { whitelist: ['events'] },
);

export const addDateToEvent = (event: Event): Event => {
  const { startTime, endTime, deadline, dateOptions } = event;
  return {
    ...event,
    startTime: new Date(startTime),
    endTime: endTime ? new Date(endTime) : undefined,
    deadline: deadline ? new Date(deadline) : undefined,
    dateOptions: dateOptions?.map((o) => ({
      ...o,
      startTime: new Date(o.startTime),
      endTime: o.endTime ? new Date(o.endTime) : undefined,
    })),
  };
};
