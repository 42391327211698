import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaRegPaperPlane } from 'react-icons/fa';
import ModalLayout from '../layouts/ModalLayout';
import { RootState } from '../../../redux/reducers';
import { Connection, ConnectionType } from '../../../redux/slices/connectionsSlice';
import localContactService from '../../../services/localContactService';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import IconBadge from '../icons/IconBadge';
import CancelSaveButton from '../misc/CancelSaveButton';
import ConnectionsList from '../misc/ConnectionsList';

interface LocalContactInviteModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function LocalContactInviteModal({
  open,
  setOpen,
}: LocalContactInviteModalProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const connections = useSelector((state: RootState) => state.connections).filter(
    (c: Connection) => c.type === ConnectionType.LOCAL_CONTACT && c.fields.EMAIL && !c.inviteSent,
  );
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) return;
    setSelected([]);
  }, [open]);

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    try {
      await localContactService.inviteContacts(selected);
      toast.success(t('toast.success.inviteSent', { count: selected.length }));
      setOpen(false);
    } catch (error) {
      toast.error(t('toast.error.unexpected'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="bg-secondary-50 min-w-[450px] flex flex-col w-fit pb-4 pt-5 mx-6 max-w-[700px] z-50 rounded-[20px] py-6 px-10 h-a">
      <div className="flex w-full">
        <IconBadge icon={FaRegPaperPlane} />

        <div className="flex flex-col ml-4 mb-6 w-full ">
          <h1 className="text-2xl font-serif">{t('component.modal.localContactInvite.title')}</h1>
          <p>{t('component.modal.localContactInvite.subtitle')}</p>
        </div>
      </div>

      <ConnectionsList
        connections={connections.map(({ id, fields: { FIRST_NAME, LAST_NAME } }) => ({
          id,
          alias: `${FIRST_NAME}+${LAST_NAME}`,
        }))}
        selected={selected}
        setSelected={setSelected}
      />

      <div className="w-full mt-6 justify-end flex">
        <CancelSaveButton
          disabled={!selected.length || loading}
          onCancel={() => setOpen(false)}
          onSave={handleSubmit}
          buttonText={loading ? t('component.modal.addAdmin.loading') : t('component.modal.addAdmin.invite')}
        />
      </div>
    </ModalLayout>
  );
}
