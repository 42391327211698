import httpService from './httpService';
import * as tagSlice from '../redux/slices/tagsSlice';
import { store } from '../redux/store';
import { TagState } from '../types/Tag';
import {
  ConnectionType,
  assignTagToConnections,
  removeTagFromAllConnections,
  removeTagFromConnection,
} from '../redux/slices/connectionsSlice';
import { removeTag } from '../redux/slices/tagsSlice';

/**
 * @description Tag service
 * Handles all the tag related requests
 */
const tagService = {
  // disable lint warning for unused variables
  /* eslint-disable @typescript-eslint/no-unused-vars */
  /**
   * @description Creates a tag with the given name
   * @param tag - String: Name of the tag
   * Add the tag to the store
   */
  addTag: async (name: string, color: string): Promise<TagState> => {
    return httpService
      .post<TagState>('business/tags', { name, color })
      .then(({ data }): TagState => {
        store.dispatch(tagSlice.setTags([data, ...store.getState().tags]));
        return data;
      });
  },
  /**
   * @description Fetches all the tags from the backend
   * Add the tags to the store
   */
  fetchTags: async (): Promise<void> => {
    await httpService.get('/business/tags').then(({ data }): void => {
      store.dispatch(tagSlice.setTags(data));
    });
  },
  /**
   * @description Deletes a tag with the given id
   * @param id - String: Id of the tag
   */
  deleteTag: async (id: string): Promise<void> => {
    await httpService.delete<TagState>(`/business/tags/${id}`).then((): void => {
      store.dispatch(removeTag(id));
      store.dispatch(removeTagFromAllConnections(id));
    });
  },
  /**
   * @description Removes a tag from a connection
   * @param connectionId - String: the id of the connection
   * @param tagId - String: the id of the tag
   * Finally, fetches all the connections again such that the labels are updated
   */
  removeTagFromConnection: async (connectionId: string, tagId: string): Promise<void> => {
    await httpService
      .delete<TagState>(`/business/tags/connection/${connectionId}/${tagId}`)
      .then(async (): Promise<void> => {
        store.dispatch(
          removeTagFromConnection({
            connection: connectionId,
            tag: tagId,
            type: ConnectionType.B2C_CONNECTION,
          }),
        );
      });
  },

  removeTagFromLocalContact: async (localContactId: string, tagId: string): Promise<void> => {
    await httpService
      .delete<TagState>(`/business/tags/local-contacts/${localContactId}/${tagId}`)
      .then(async (): Promise<void> => {
        store.dispatch(
          removeTagFromConnection({
            connection: localContactId,
            tag: tagId,
            type: ConnectionType.LOCAL_CONTACT,
          }),
        );
      });
  },

  /**
   * @description Assigns a tag to multiple connections
   * @param connectionIds - String[]: the ids of the connections to assign the tag to
   * @param tagId - String: the id of the tag to assign
   * Finally, fetches all the connections again such that the labels are updated
   */
  assignTagToConnections: async (connectionIds: string[], tagId: string): Promise<void> => {
    if (connectionIds.length === 0) return;
    await httpService
      .post<TagState>('/business/tags/connections', { tagId, connectionIds })
      .then(() => {
        store.dispatch(
          assignTagToConnections({
            connections: connectionIds,
            tag: tagId,
            type: ConnectionType.B2C_CONNECTION,
          }),
        );
      });
  },

  /**
   * @description Assigns a tag to a local contact
   * @param localContactIds - String: the ids of the connections to assign the tag to
   * @param tagId - String: the id of the tag to assign
   */
  assignTagToLocalContacts: async (localContactIds: string[], tagId: string): Promise<void> => {
    if (localContactIds.length === 0) return;
    await httpService
      .post<TagState>('/business/tags/local-contacts', { tagId, localContactIds })
      .then(() => {
        store.dispatch(
          assignTagToConnections({
            connections: localContactIds,
            tag: tagId,
            type: ConnectionType.LOCAL_CONTACT,
          }),
        );
      });
  },

  assignTagToMomentConnections: async (
    momentConnectionIds: string[],
    tagId: string,
  ): Promise<void> => {
    if (momentConnectionIds.length === 0) return;
    await httpService
      .post<TagState>('/business/tags/connection/personal', { tagId, momentConnectionIds })
      .then(() => {
        store.dispatch(
          assignTagToConnections({
            connections: momentConnectionIds,
            tag: tagId,
            type: ConnectionType.PERSONAL_MOMENT_CONNECTION,
          }),
        );
      });
  },

  removeTagFromMomentConnection: async (
    momentConnectionId: string,
    tagId: string,
  ): Promise<void> => {
    await httpService
      .delete<TagState>(`/business/tags/connection/personal`, {
        data: { momentConnectionIds: [momentConnectionId], tagId },
      })
      .then(async (): Promise<void> => {
        store.dispatch(
          removeTagFromConnection({
            connection: momentConnectionId,
            tag: tagId,
            type: ConnectionType.PERSONAL_MOMENT_CONNECTION,
          }),
        );
      });
  },
};

export default tagService;
