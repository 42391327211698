import React, { useState } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import SearchBar from '../forms/SearchBar';
import ProfilePicture from './ProfilePicture';
import { ConnectionsListConnection } from '../../../types/misc';

interface ConnectionsListProps<T> {
  connections: ConnectionsListConnection[];
  selected: T[];
  setSelected: (selected: T[]) => void;
  includePicture?: boolean;
  className?: string;
  containerClassName?: string;
  hasSearch?: boolean;
  search?: string;
  equalityFunction?: (a: ConnectionsListConnection, b: T) => boolean;
}

// eslint-disable-next-line no-empty-pattern
export default function ConnectionsList<T>({
  connections,
  selected,
  setSelected,
  includePicture = false,
  className = '',
  containerClassName = '',
  hasSearch = true,
  search: predefinedSearch,
  equalityFunction,
}: ConnectionsListProps<T>): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const isInArray = (array: any[], elementToCheck: any) =>
    _.some(array, (item) =>
      equalityFunction
        ? equalityFunction(item, elementToCheck)
        : _.isObject(item) ? _.isEqual(item, elementToCheck) : item === elementToCheck,
    );

  const handleSelectAll = () => {
    if (selected.length === connections.filter((c) => !c.disabled).length) {
      setSelected([]);
    } else {
      setSelected(connections.filter((c) => !c.disabled).map((c) => c.id));
    }
  };

  const handleSelect = (id: any) => {
    if (isInArray(selected, id)) {
      setSelected(selected.filter((s) => equalityFunction ? !equalityFunction(id, s) : !_.isEqual(s, id)));
    } else {
      setSelected([...selected, id]);
    }
  };
  const filtered = connections.filter((c) =>
    c.alias.toLowerCase().includes((predefinedSearch ?? search).toLowerCase()),
  );

  return (
    <div
      className={twMerge('w-full flex flex-col gap-4 border-y border-primary-300 py-4', className)}>
      {connections.length > 4 && hasSearch && !predefinedSearch && (
        <SearchBar search={search} setSearch={setSearch} />
      )}
      {filtered.length > 2 && (
        <div className="flex justify-end">
          <div
            className="w-fit cursor-pointer gap-2 items-center flex mr-3"
            onClick={handleSelectAll}>
            <p className="">{t('form.userData.selectAll')}</p>
            <input
              type="checkbox"
              checked={selected.length === connections.filter((c) => !c.disabled).length}
            />
          </div>
        </div>
      )}
      <div
        className={twMerge(
          'flex flex-col gap-2 overflow-y-scroll no-scrollbar max-h-[400px]',
          containerClassName,
        )}>
        <p className={classNames('pl-2', { hidden: filtered.length })}>
          {t('component.connectionSelector.noConnections')}
        </p>
        {filtered.map((c, i) => (
          <div
            key={i}
            onClick={() => !c.disabled && handleSelect(c.id)}
            className={classNames(
              'flex cursor-pointer items-center border px-3 py-2 justify-between border-secondary-200 rounded-[8px]',
              {
                'bg-secondary-200': isInArray(selected, c.id),
                'opacity-20': c.disabled,
              },
            )}>
            <div className="flex gap-2 items-center">
              {includePicture && (
                <ProfilePicture alias={c.alias} picture={c.picture} className="h-9 w-9" />
              )}
              <p className="font-medium">{c.alias.replace('+', ' ')}</p>
            </div>
            <input type="checkbox" checked={isInArray(selected, c.id)} />
          </div>
        ))}
      </div>
    </div>
  );
}
