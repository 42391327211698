import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type PendingConnectionsType = {
  connectionId: string;
  alias: string;
  verificationLabel: string;
  numberOfConnections: number;
  numberOfReports: number;
};

const initalState: PendingConnectionsType[] = [];

const pendingConnectionsSlice = createSlice({
  name: 'pendingConnections',
  initialState: initalState,
  reducers: {
    setPendingConnections: (state, action: PayloadAction<PendingConnectionsType[]>): void => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setPendingConnections } = pendingConnectionsSlice.actions;
export default pendingConnectionsSlice.reducer;
