import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware } from 'redux-state-sync';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { eventTransform } from './transformers';

// redux-persist is used to make sure state is retained even after reloading page
const persistConfig = {
  key: 'root',
  storage,
  transforms: [eventTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [
    thunk,
    createStateSyncMiddleware({ whitelist: ['application/login', 'application/logout'] }),
  ],
});

export const persistor = persistStore(store);
