import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaHandshake } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../redux/reducers';
import introductionService from '../../../services/introductionService';
import ModalLayout from '../layouts/ModalLayout';
import { getUserId } from '../../../services/httpService';
import IconBadge from '../icons/IconBadge';
import CancelSaveButton from '../misc/CancelSaveButton';
import ConnectionsList from '../misc/ConnectionsList';

interface IntroductionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  userId: number;
  alias: string;
}
export default function IntroductionModal({
  open,
  setOpen,
  userId,
  alias,
}: IntroductionModalProps) {
  const connections = useSelector((state: RootState) => state.connections);
  const { t } = useTranslation();
  const [compatible, setCompatible] = useState<number[]>([]);
  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    if (!open) return;
    setSelected([]);
    introductionService
      .getCompatibleUserids(
        userId,
        connections.map((c) => c.userId).filter((c) => c && c !== userId) as number[],
      )
      .then(setCompatible);
  }, [open]);

  const handleChange = (changedId: number) => {
    if (selected.includes(changedId)) setSelected(selected.filter((i) => i !== changedId));
    else setSelected([...selected, changedId]);
  };

  const handleSubmit = () => {
    introductionService.createIntroductions(userId, selected);
    toast.success('Introductions sent!');
    setOpen(false);
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="bg-secondary-50 min-w-[450px] flex flex-col w-fit pb-4 pt-5 mx-6 max-w-[700px] z-50 rounded-[20px] py-6 px-10 h-a">
      <div className="flex w-full">
        <IconBadge icon={FaHandshake} />

        <div className="flex flex-col ml-4 mb-6 w-full ">
          <h1 className="text-2xl font-serif">{t('component.modal.introduction.title')}</h1>
          <span>
            {t('component.modal.introduction.subtitle', {
              name: alias.replace('+', ' '),
              firstName: alias.split('+')[0],
            })}
          </span>
        </div>
      </div>
      {open && (
        <ConnectionsList
          connections={connections
            .filter((c) => c.userId && userId !== c.userId && c.userId !== +getUserId())
            .map(({ userId: id, fields: { FIRST_NAME, LAST_NAME } }) => ({
              id,
              alias: `${FIRST_NAME}+${LAST_NAME}`,
              disabled: !compatible.includes(id!),
            }))}
          selected={selected}
          setSelected={setSelected}
        />
      )}

      <div className="w-full mt-6 justify-end flex">
        <CancelSaveButton
          onCancel={() => setOpen(false)}
          onSave={handleSubmit}
          disabled={!selected.length}
          buttonText={t('component.modal.addAdmin.invite')}
        />
      </div>
    </ModalLayout>
  );
}
