import { store } from '../redux/store';
import httpService from './httpService';
import { BusinessInvite } from './model/inviteService.model';
import { addInviteFields, setInviteLink } from '../redux/slices/applicationSlice';
import { getSelectedBusiness } from '../hooks/business/useSelectedBusiness';
import { isMoments } from '../constants';

/*
 * Invite service, contains all logic for invite related actions
 */
const inviteService = {
  /**
   * Gets the invite fields from the server or from the store
   * @returns {Promise<BusinessInvite>} - The invite fields
   */
  fetchInvite: async (): Promise<BusinessInvite> => {
    const inviteFields = getSelectedBusiness()?.inviteFields;
    if (inviteFields) {
      if (
        isMoments &&
        (!inviteFields.linkExpiry || new Date(inviteFields.linkExpiry) < new Date())
      ) {
        store.dispatch(setInviteLink(undefined));
        return { ...inviteFields, friendlyId: undefined, linkExpiry: undefined };
      }

      return inviteFields;
    }

    const { data } = await httpService.get<BusinessInvite>('/business/invite');
    store.dispatch(addInviteFields(data));

    return data;
  },

  /**
   * Creates an invite url on the backend
   * @param {string[]} requiredFields - The required fields for the invite
   * @param {string[]} customFields - The custom fields for the invite
   * @returns {Promise<string>} - The invite url
   */
  createInvite: async (invite: BusinessInvite): Promise<BusinessInvite> => {
    const { data: fields } = await httpService.post<BusinessInvite>(
      '/business/invite/create',
      invite,
    );
    store.dispatch(addInviteFields(fields));

    return fields;
  },

  updateInvite: async (invite: BusinessInvite): Promise<BusinessInvite> => {
    const { friendlyId, linkExpiry } = getSelectedBusiness()?.inviteFields || {};
    const { data: fields } = await httpService.put<BusinessInvite>('/business/invite', invite);
    store.dispatch(
      addInviteFields({
        ...fields,
        friendlyId: friendlyId || fields.friendlyId,
        linkExpiry,
      }),
    );

    return fields;
  },

  createInviteLink: async () => {
    return httpService.post('/business/invite/link').then((res) => {
      store.dispatch(setInviteLink(res.data));
    });
  },

  inviteConnections: async (userIds: string[]) => {
    return httpService.post('/business/invite/connections', { userIds });
  },

  getNotInvitedConnections: async (userIds: number[]): Promise<number[]> => {
    return httpService.post('/business/invite/connected', { userIds }).then((result) => {
      return result.data;
    });
  },
};

export default inviteService;
