import { twMerge } from 'tailwind-merge';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AiOutlinePlus } from 'react-icons/ai';
import { Discount } from '../../../types/misc';
import Button from '../buttons/Button';
import orderService from '../../../services/orderService';

interface DiscountInputProps {
  discount: Discount | undefined;
  setDiscount: (discount: Discount | undefined) => void;
  className?: string;
}

export default function DiscountInput({
  discount,
  setDiscount,
  className = '',
}: DiscountInputProps): JSX.Element {
  const [code, setCode] = useState<string>('');
  const { t } = useTranslation();

  const handleSubmit = () => {
    orderService
      .getDiscount(code)
      .then((amount) => {
        setDiscount({ code, amount });
        toast.success(t('toast.success.codeApplied'));
      })
      .catch(() => {
        toast.error(t('toast.error.codeInvalid'));
      })
      .finally(() => {
        setCode('');
      });
  };

  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      <div className="flex flex-col">
        <label htmlFor="discount" className="font-medium  pr-2 mb-1">
          {t('component.discount.code')}
        </label>
        <input
          type="text"
          className="!border-primary"
          onChange={(e) => setCode(e.target.value)}
          value={code}
        />
      </div>

      <div className={`flex items-center ${discount ? 'justify-between' : 'justify-end'} `}>
        {discount && (
          <div className="flex px-3 py-1 gap-2 rounded-full items-center justify-center text-sm text-primary-900 bg-primary-300">
            {`${discount.code}: ${discount.amount * 100}%`}
            <AiOutlinePlus
              className="w-5 h-5 icon rotate-45 cursor-pointer text-primary-900"
              onClick={() => setDiscount(undefined)}
            />
          </div>
        )}
        <Button variant="primary" onClick={handleSubmit}>
          {t('component.discount.button')}
        </Button>
      </div>
    </div>
  );
}
