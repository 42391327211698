import React from 'react';
import { twMerge } from 'tailwind-merge';
import Button from '../../../buttons/Button';
import HoverButton from '../../../buttons/HoverButton';
import { BUTTON_STYLE, BUTTON_STYLE_COLLAPSE, LABEL_STYLE, LABEL_STYLE_COLLAPSE } from './style';

interface ActionProps {
  icon: JSX.Element;
  name: string;
  onClick: () => void;
  className?: string;
  textClassName?: string;
  open?: boolean;
}

export default function Action({
  name,
  icon,
  onClick,
  className,
  textClassName = '',
  open = false,
}: ActionProps) {
  return (
    <HoverButton className="w-full">
      <HoverButton.Button>
        <Button
          key={name}
          className={twMerge(BUTTON_STYLE, !open && BUTTON_STYLE_COLLAPSE, className)}
          onClick={onClick}>
          {icon}
          <p className={twMerge(LABEL_STYLE, !open && LABEL_STYLE_COLLAPSE, textClassName)}>
            {name}
          </p>
        </Button>
      </HoverButton.Button>
      {!open && (
        <HoverButton.Div className="left-[150%] group-[.collapsed]:block hidden transition-50 rounded-[6px] top-[50%] -translate-y-[50%] px-3 py-2 bg-primary-900">
          <p className="text-sm whitespace-nowrap text-secondary-50">{name}</p>
          <div className="h-[15px]  w-[15px] rotate-45 bg-primary-900 absolute top-[50%] -translate-y-[50%] -left-1 rounded-[2px]" />
        </HoverButton.Div>
      )}
    </HoverButton>
  );
}
