import * as React from 'react';
import * as constants from '../../../constants'

export function SvgQuestionMark(props: React.SVGProps<SVGSVGElement>) {
  /* eslint-disable react/prop-types */
  const { fill } = props;
  return (
    <svg width="100%" height="100%" viewBox="0 0 95 154" fill="none" {...props}>
      <path
        d="M25.629 114.12c-10.235-2.003-18.397 2.981-20.094 11.629-2.314 11.82 7.817 25.035 21.08 27.632 10.235 2.009 18.396-2.981 20.094-11.63 2.34-11.964-7.967-25.061-21.08-27.631zM92.567 66.618c-5.451 27.82-23.694 26.94-40.021 27.779-13.972.746-20.487 2.242-23.73 7.29-1.542 2.4-5.143 2.062-6.13-.614-2.761-7.457-4.14-15.729-2.872-22.222C22.16 66.887 32.5 66.074 48.885 64.944c13.363-.975 25.46-2.345 27.013-10.275.964-4.902-2.972-8.516-12.338-10.353-17.618-3.454-34.019 3.616-41.108 15.211-1.47 2.405-5.026 2.178-6.118-.426a50.752 50.752 0 01-2.445-7.305s0-.006-.006-.016c-.275-.827-11.635-35.754 26.488-48.883C78.946-10.383 91.85 42.96 91.85 42.96h-.005c2.16 6.88 2.451 14.822.721 23.658z"
        fill={fill ?? constants.COLOR_SECONDARY_200}
      />
      <path
        d="M3.124 34.556l33.831-10.944a3.157 3.157 0 014.054 2.327c1.309 5.912 1.796 18.142-14.421 23.388-16.217 5.246-22.985-4.952-25.387-10.51a3.157 3.157 0 011.923-4.26zM9.513 20.828a3.168 3.168 0 11-6.03 1.951 3.168 3.168 0 016.03-1.95zM29.764 14.276a3.169 3.169 0 11-6.03 1.95 3.169 3.169 0 016.03-1.95z"
        fill={constants.COLOR_PRIMARY_900}
      />
    </svg>
  );
}
