/**
 * dropdown that shows the actions that can be performed on a connection
 * possible actions: assign tag, break the connection, email, ask details
 */

import React, { Fragment } from 'react';
import { FiMail } from 'react-icons/fi';
import { FaPencilAlt, FaEllipsisH } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi';
import { RiAdminFill } from 'react-icons/ri';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { FaHandshake, FaShareNodes } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import WarningModal from '../modals/WarningModal';
import { ConnectionType } from '../../../redux/slices/connectionsSlice';
import LocalContactModal from '../modals/LocalContactModal';
import localContactService from '../../../services/localContactService';
import useConnection from '../../../hooks/business/useConnection';
import useAdmins from '../../../hooks/business/useAdmins';
import adminService from '../../../services/adminService';
import { AdminRole } from '../../../types/business';
import IntroductionModal from '../modals/IntroductionModal';
import { getUserId } from '../../../services/httpService';
import PersonalMomentConnectionModal from '../modals/PersonalMomentConnectionModal';
import LocalContactInvitationLinkModal from '../modals/LocalContactInvitationLinkModal';
import { isCommunity } from '../../../constants';
import SharedWithCommunityModal from '../modals/SharedWithCommunityModal';

interface ConnectionActionsProps {
  connectionId: string;
  connectionType: ConnectionType;
  handleDelete: (id: string, connectionType: ConnectionType) => Promise<void>;
}

export default function ConnectionActions({
  connectionId,
  connectionType,
  handleDelete,
}: ConnectionActionsProps): JSX.Element {
  const { t } = useTranslation();
  const connection = useConnection(connectionId, connectionType);
  const admins = useAdmins();

  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [localContactDialog, setLocalContactDialog] = React.useState(false);
  const [introductionDialog, setIntroductionDialog] = React.useState(false);
  const [personalMomentConnectionDialog, setPersonalMomentConnectionDialog] = React.useState(false);
  const [localContactInvitationLinkDialog, setLocalContactInvitationLinkDialog] =
    React.useState(false);
  const [sharedWithCommunityDialog, setSharedWithCommunityDialog] = React.useState(false);

  const inviteContact = async (): Promise<void> => {
    await localContactService.inviteContacts([connectionId]);
    toast.success('Invitation sent!');
  };

  const inviteAdmin = async (role: AdminRole): Promise<void> => {
    if (!connection?.userId) return;
    await adminService.inviteByUserIds([connection?.userId], role);
    toast.success(t('toast.success.inviteSent_one'));
  };
  return (
    <>
      <Menu
        as="div"
        className="relative mx-auto origin-right"
        data-testid={`Connection-Actions-${connectionId}`}>
        <Menu.Button
          data-testid={`${connectionId}-actions`}
          className="p-2 flex items-center text-left">
          <FaEllipsisH className="h-6 w-6 font-bold icon" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-14 -top-16 z-10 mt-2 w-fit origin-top-right max-h-48 h-fit rounded-md bg-secondary-50 shadow-lg ring-1 ring-black ring-opacity-5">
            {connectionType === ConnectionType.B2C_CONNECTION &&
              !admins
                .map((a) => a.userId)
                .filter((x) => x)
                .includes(connection?.userId) && (
                <Menu.Item>
                  {({ active }): JSX.Element => (
                    <button
                      type="button"
                      className={`${
                        active ? 'bg-secondary-200' : ''
                      } px-4 py-2 text-sm font-bold rounded-b-md flex items-center p-1 w-full`}
                      onClick={() => inviteAdmin(AdminRole.OWNER)}>
                      <RiAdminFill className="w-5 h-5 mr-2 icon" />
                      <span className="whitespace-nowrap inline">
                        {t('component.actions.connection.admin')}
                      </span>
                    </button>
                  )}
                </Menu.Item>
              )}

            {connectionType === ConnectionType.B2C_CONNECTION &&
              connection?.userId !== +getUserId() && (
                <Menu.Item>
                  {({ active }): JSX.Element => (
                    <button
                      type="button"
                      className={`${
                        active ? 'bg-secondary-200' : ''
                      } px-4 py-2 text-sm font-bold rounded-b-md flex items-center p-1 w-full`}
                      onClick={(): void => setIntroductionDialog(true)}>
                      <FaHandshake className="w-5 h-5 mr-2 icon" />
                      <span className="whitespace-nowrap inline">
                        {t('component.actions.connection.introduction')}
                      </span>
                    </button>
                  )}
                </Menu.Item>
              )}

            {connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION && (
              <Menu.Item>
                {({ active }): JSX.Element => (
                  <button
                    type="button"
                    className={`${
                      active ? 'bg-secondary-200' : ''
                    } px-4 py-2 text-sm font-bold rounded-b-md flex items-center p-1 w-full`}
                    onClick={(): void => setPersonalMomentConnectionDialog(true)}>
                    <FaPencilAlt className="w-5 h-5 mr-2 icon" />
                    <span className="whitespace-nowrap inline">
                      {t('component.actions.connection.edit')}
                    </span>
                  </button>
                )}
              </Menu.Item>
            )}

            {isCommunity && connection?.userId === getUserId() && (
              <Menu.Item>
                {({ active }): JSX.Element => (
                  <button
                    type="button"
                    className={`${
                      active ? 'bg-secondary-200' : ''
                    } px-4 py-2 text-sm font-bold rounded-b-md flex items-center p-1 w-full`}
                    onClick={(): void => setSharedWithCommunityDialog(true)}>
                    <FaPencilAlt className="w-5 h-5 mr-2 icon" />
                    <span className="whitespace-nowrap inline">
                      {t('component.actions.connection.edit')}
                    </span>
                  </button>
                )}
              </Menu.Item>
            )}

            {connectionType === ConnectionType.LOCAL_CONTACT && (
              <>
                <Menu.Item>
                  {({ active }): JSX.Element => (
                    <button
                      type="button"
                      className={`${
                        active ? 'bg-secondary-200' : ''
                      } px-4 py-2 text-sm font-bold rounded-b-md flex items-center p-1 w-full`}
                      onClick={(): void => setLocalContactDialog(true)}>
                      <FaPencilAlt className="w-5 h-5 mr-2 icon" />
                      <span className="whitespace-nowrap inline">
                        {t('component.actions.connection.edit')}
                      </span>
                    </button>
                  )}
                </Menu.Item>
                {connection?.fields.EMAIL && (
                  <Menu.Item>
                    {({ active }): JSX.Element => (
                      <button
                        type="button"
                        className={`${
                          active ? 'bg-secondary-200' : ''
                        } px-4 py-2 text-sm font-bold rounded-b-md flex items-center p-1 w-full`}
                        onClick={inviteContact}>
                        <FiMail className="h-5 w-5 icon  mr-2" />
                        <span className="whitespace-nowrap inline">
                          {t('component.actions.connection.sendInvite')}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }): JSX.Element => (
                    <button
                      type="button"
                      className={`${
                        active ? 'bg-secondary-200' : ''
                      } px-4 py-2 text-sm font-bold rounded-b-md flex items-center p-1 w-full`}
                      onClick={(): void => setLocalContactInvitationLinkDialog(true)}>
                      <FaShareNodes className="w-5 h-5 mr-2 icon" />
                      <span className="whitespace-nowrap inline">
                        {t('component.actions.connection.getInvite')}
                      </span>
                    </button>
                  )}
                </Menu.Item>
              </>
            )}

            <Menu.Item>
              {({ active }): JSX.Element => (
                <button
                  type="button"
                  data-testid={`${connectionId}-break-connection`}
                  className={`${
                    active ? 'bg-secondary-200' : ''
                  } px-4 py-2 text-sm text-error font-semibold rounded-b-md flex items-center p-1 w-full`}
                  onClick={(): void => setDeleteDialog(true)}>
                  <HiTrash className="w-5 h-5 mr-2 icon" />
                  <span className="whitespace-nowrap inline">
                    {connectionType === ConnectionType.LOCAL_CONTACT
                      ? t('component.actions.connection.deleteContact')
                      : t('component.actions.connection.deleteConnection')}
                  </span>
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <WarningModal
        open={deleteDialog}
        setOpen={setDeleteDialog}
        onClose={(): Promise<void> => handleDelete(connectionId, connectionType)}
        title={
          connectionType === ConnectionType.LOCAL_CONTACT ||
          connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION
            ? t('warning.deleteLocalContact.title')
            : t('warning.deleteConnection.title')
        }
        saveClassName="bg-error"
        description={
          connectionType === ConnectionType.LOCAL_CONTACT ||
          connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION
            ? t('warning.deleteLocalContact.description')
            : t('warning.deleteConnection.description')
        }
        button={
          connectionType === ConnectionType.LOCAL_CONTACT ||
          connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION
            ? t('warning.deleteLocalContact.title')
            : t('warning.deleteConnection.title')
        }
      />
      {connectionType === ConnectionType.LOCAL_CONTACT && (
        <>
          <LocalContactModal
            open={localContactDialog}
            setOpen={setLocalContactDialog}
            connectionId={connectionId}
          />
          <LocalContactInvitationLinkModal
            open={localContactInvitationLinkDialog}
            setOpen={setLocalContactInvitationLinkDialog}
            connectionId={connectionId}
          />
        </>
      )}
      {connectionType === ConnectionType.B2C_CONNECTION && (
        <IntroductionModal
          open={introductionDialog}
          setOpen={setIntroductionDialog}
          alias={`${connection?.fields.FIRST_NAME}+${connection?.fields.LAST_NAME}`}
          userId={connection?.userId!}
        />
      )}
      {connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION && (
        <PersonalMomentConnectionModal
          connection={connection}
          open={personalMomentConnectionDialog}
          setOpen={setPersonalMomentConnectionDialog}
        />
      )}
      {isCommunity && connection?.userId === getUserId() && (
        <SharedWithCommunityModal
          open={sharedWithCommunityDialog}
          setOpen={setSharedWithCommunityDialog}
        />
      )}
    </>
  );
}
