import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { InvoiceData } from '../../../types/payments';
import Button from '../../components/buttons/Button';
import InvoicesTable from '../../components/tables/InvoicesTable';
import { subscriptionService } from '../../../services/subscriptionService';

export default function InvoicesPage(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.invoices' });
  const [invoices, setInvoices] = React.useState<InvoiceData[]>([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    subscriptionService.getLastInvoices(Number.MAX_SAFE_INTEGER)
      .then(setInvoices)
      .catch(() => toast.error(t('failed')))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <div>
        <div className="flex flex-col gap-2">
          <Button className="text-sm p-0"
                  onClick={() => navigate('/subscriptions/manage')}><FaChevronLeft /> {t('toManagePage')}</Button>
          <div className="flex gap-4 items-center">
            <h2 className="font-serif text-2xl">{t('title')}</h2>
          </div>
          <p className="text-gray-500 w-1/3">{t('subtitle')}</p>
        </div>
      </div>
      <InvoicesTable invoices={invoices} loading={loading} />
    </>
  );
}
