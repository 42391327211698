import React from 'react';
import { API_URL } from '../../../constants';

interface Props {
  path: string;
  alt?: string;
  className?: string;
}

export default function ApiImage({ path, alt, className }: Props): JSX.Element {
  const uri =
    path?.startsWith('uploads') || path?.startsWith('./uploads')
      ? `${API_URL}/${path.replace('./', '').replace('\\', '/')}`
      : path;

  return <img src={uri} alt={alt} className={className} />;
}
