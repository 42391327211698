import React from 'react';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';

export default function AboutPage(): JSX.Element {
  const { t } = useBusinessTranslation();
  return (
    <div className="w-full flex flex-1 flex-col items-center min-h-[calc(100vh-202px)] py-10">
      <div className="w-full max-w-5xl">
        <h2 className="text-4xl font-medium mb-2">{t('page.about.about.title')}</h2>
        <p>{t('page.about.about.text')}</p>
        <h2 className="text-4xl font-medium mt-8 mb-2">{t('page.about.why.title')}</h2>
        <p>{t('page.about.why.text')}</p>
        <h2 className="text-2xl font-medium mt-4">{t('page.about.privacy.title')}</h2>
        <p>{t('page.about.privacy.text')}</p>

        <h2 className="text-2xl font-medium mt-4">{t('page.about.life.title')}</h2>
        <p>{t('page.about.life.text')}</p>
        <h2 className="text-4xl font-medium mt-8 mb-2">{t('page.about.contact.title')}</h2>
        <p>
          {t('page.about.contact.text')}
          <a
            href="mailto:?to=info@veraconnect.nl"
            target="_blank"
            rel="noreferrer"
            className="underline font-medium">
            info@veraconnect.nl
          </a>
          .
        </p>
      </div>
    </div>
  );
}
