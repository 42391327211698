import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FaBars } from 'react-icons/fa6';
import Logo from '../../icons/Logo';
import SettingsPanel from './components/SettingsPanel';
import useProfilePicture from '../../../../hooks/account/useProfilePicture';
import userService from '../../../../services/userService';
import LogoLetter from '../../icons/LogoLetter';
import SideBarIcon from '../../icons/SideBarIcon';
import useSelectedBusiness from '../../../../hooks/business/useSelectedBusiness';
import ProfilePicture from '../../misc/ProfilePicture';
import useAuth from '../../../../hooks/account/useAuth';
import BusinessButton from './components/BusinessButton';
import DropdownPanel from './components/DropdownPanel';
import { RootState } from '../../../../redux/reducers';

interface ApplicationLayoutProps {
  children?: React.ReactNode;
  collapsed?: boolean;
}

export default function ApplicationLayout({
  children,
  collapsed: preCollapsed = false,
}: ApplicationLayoutProps): JSX.Element {
  const profilePicture = useProfilePicture();
  const location = useLocation();
  const business = useSelectedBusiness();
  const auth = useAuth();
  const { onboardingStep } = useSelector((state: RootState) => state.application);

  const [collapsed, setCollapsed] = useState(preCollapsed);
  const [closed, setClosed] = useState(true);

  useEffect(() => {
    if (onboardingStep === undefined) return;
    setCollapsed(false);
  }, [onboardingStep]);

  useEffect(() => {
    if (!profilePicture) {
      userService.getProfilePicture();
    }
  }, [profilePicture]);

  useEffect(() => setClosed(true), [location.pathname]);

  return (
    <div className="h-full min-h-screen lg:justify-between bg-primary-900 p-0 lg:p-4 w-full flex flex-col lg:flex-row">
      <div
        className={classNames(
          'flex flex-col flex-shrink-0 w-full lg:sticky top-4 h-fit z-50 group transition-all duration-200  py-4',
          {
            collapsed,
            open: !collapsed,
            'lg:w-14': collapsed,
            'lg:w-[300px] px-4 pr-8 items-start': !collapsed,
          },
        )}>
        <div className="flex justify-between w-full items-center">
          <Link to={business ? '/overview' : '/'} className="w-fit">
            {collapsed ? (
              <>
                <LogoLetter dark className="min-h-[58px] hidden lg:block " />
                <Logo dark className="lg:hidden" />
              </>
            ) : (
              <Logo dark className="lg:min-h-[80px] lg:min-w-[230]" />
            )}
          </Link>
          <FaBars
            className="w-6 h-6 lg:hidden text-secondary-50 cursor-pointer"
            onClick={() => setClosed(!closed)}
          />
        </div>
        <div
          className={classNames(
            'flex flex-col w-full transition-all overflow-hidden lg:group-[.collapsed]:overflow-visible lg:max-h-[calc(100vh-150px)] lg:group-[.open]:overflow-scroll no-scrollbar',
            {
              'max-h-[0px] ease-[cubic-bezier(0,1,0,1)] duration-500': closed,
              'max-h-[2000px] ease-[ease-in-out] duration-1000': !closed,
            },
          )}>
          {business && (
            <>
              <div className="w-full flex flex-col gap-2 pt-4 mt-4 border-t items-center justify-center border-primary-300">
                <DropdownPanel />
              </div>
            </>
          )}
          <div className="pt-4 mt-4 border-t border-primary-300 flex flex-col items-center justify-center w-full gap-1">
            <SettingsPanel />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'bg-secondary-50 w-full min-h-[calc(100vh-72px)] rounded-t-[20px] lg:rounded-[20px] px-6 pb-4 lg:mb-2 flex flex-col',
          {
            'lg:w-[calc(100%-300px)]': !collapsed,
            'lg:w-[calc(100%-72px)]': collapsed,
          },
        )}>
        <div
          className={classNames(
            'w-full flex justify-between mb-4 border-b border-primary-300 sticky top-0 z-[50] bg-secondary-50 py-4',
            { 'blur-sm opacity-80': onboardingStep !== undefined },
          )}>
          <div className="flex gap-2 items-center">
            <button
              type="button"
              onClick={() => setCollapsed(!collapsed)}
              className="rounded-full hidden bg-secondary-200 hover:bg-opacity-80 flex-shrink-0 h-fit w-fit p-2 cursor-pointer lg:flex justify-center items-center">
              <SideBarIcon className="h-5 w-5" />
            </button>
            <BusinessButton />
          </div>
          {location.pathname !== '/profile' && auth && (
            <Link to="/profile">
              <ProfilePicture
                picture={profilePicture}
                alias={`${auth.firstName}+${auth.lastName}`}
                className="h-10 w-10 rounded-lg hover:bg-opacity-80"
              />
            </Link>
          )}
        </div>
        <div className="flex flex-col w-full h-full flex-1">{children || <Outlet />}</div>
      </div>
    </div>
  );
}
