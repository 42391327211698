import React from 'react';

import classNames from 'classnames';
import useAuth from '../../../hooks/account/useAuth';
import ApiImage from '../misc/ApiImage';

interface PanelWithImageLayoutProps {
  children: React.ReactNode;
  border?: boolean;
  image: string;
}

function PanelWithImageLayout({
  children,
  border = false,
  image,
}: PanelWithImageLayoutProps): JSX.Element {
  const auth = useAuth();
  const header = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Header,
  );

  return (
    <div className="flex-col my-10 md:flex-row w-full flex flex-1 h-full justify-between max-w-7xl mx-auto gap-10">
      <div className="md:flex hidden bg-primary-300 min-h-[400px] md:min-h-[600px] place-items-center justify-center items-center h-full py-24 sticky top-[96px] z-10 overflow-hidden rounded-3xl w-[65%]">
        <img
          src={`${process.env.PUBLIC_URL}/assets/figures/phone.svg`}
          alt="Vera Phone"
          className="w-[40%]"
        />
      </div>
      <div className="w-full md:w-[43%] md:min-w-[420px] min-h-[400px] md:min-h-[600px] bg-secondary-50 h-fit rounded-3x flex flex-col gap-4 pt-4 ">
        <div className="w-full ">{header}</div>
        <div className="h-full flex flex-col w-full px-4 md:px-0 rounded-b-3xl">
          {React.Children.toArray(children).filter(
            (child) => React.isValidElement(child) && child.type !== Header,
          )}
        </div>
      </div>
    </div>
  );
}

interface HeaderProps {
  children: React.ReactNode;
}

function Header({ children }: HeaderProps): JSX.Element {
  return (
    <div className="bg-secondary-50 rounded-3xl md:rounded-b-none w-full h-fit gap-2.5 flex flex-col px-4 md:p-0">
      {children}
    </div>
  );
}

PanelWithImageLayout.Header = Header;

export default PanelWithImageLayout;
