import classNames from 'classnames';
import React from 'react';

interface VerticalStepIndicatorProps {
  children: React.ReactNode;
  className?: string;
  stepIndex: number;
  setStepIndex: (index: number) => void;
}

function VerticalStepIndicator({
  children,
  className = '',
  stepIndex,
  setStepIndex,
}: VerticalStepIndicatorProps): JSX.Element {
  const items = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === Item,
  );
  return (
    <ul className={className}>
      {items.map((item, index) => (
        <li
          onClick={() => setStepIndex(index)}
          className={`relative cursor-pointer pl-6 ${index === items.length - 1 ? '' : 'pb-2'}`}>
          {item}
          <div
            className={`z-40 rounded-full h-4 w-4 top-1 -left-[7px] absolute ${
              stepIndex < index ? 'bg-secondary-200' : 'bg-secondary'
            } flex items-center justify-center`}>
            {index === stepIndex && <div className="rounded-full h-2 w-2 bg-secondary-50 z-40" />}
          </div>
          <div
            className={classNames('absolute -left-[0px] w-[3px] top-[6px] h-[calc(100%+5px)]', {
              'bg-secondary': stepIndex > index,
              'bg-secondary-200': stepIndex <= index,
              hidden: index === items.length - 1,
            })}
          />
        </li>
      ))}
    </ul>
  );
}

interface ItemProps {
  children: React.ReactNode;
}
function Item({ children }: ItemProps): JSX.Element {
  return <>{children}</>;
}

VerticalStepIndicator.Item = Item;

export default VerticalStepIndicator;
