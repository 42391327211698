/* eslint-disable no-plusplus */
import countries from 'i18n-iso-countries';
import nl from 'i18n-iso-countries/langs/nl.json';
import en from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(nl);
countries.registerLocale(en);

type CountryUtil = typeof countries & {
  recognizeCountryCode: (country: string) => string;
  translateCountry: (country: string) => string;
};

const recognizeCountryCode = (country: string): string => {
  if (!country) return '';
  for (let i = 0; i < countries.langs().length; i++) {
    const lang = countries.langs()[i];
    const countryName = countries.getSimpleAlpha2Code(country.trim(), lang);
    if (countryName) {
      return countryName;
    }
  }
  return '';
};

const translateCountry = (country: string): string => {
  if (!country) return '';
  for (let i = 0; i < countries.langs().length; i++) {
    const lang = countries.langs()[i];
    const countryName = countries.getSimpleAlpha2Code(country.trim(), lang);
    if (countryName) {
      return countries.getName(countryName, 'en') || '';
    }
  }
  return '';
};

export default { ...countries, recognizeCountryCode, translateCountry } as CountryUtil;
