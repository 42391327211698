import { twMerge } from 'tailwind-merge';
import React from 'react';
import { FaSearch } from 'react-icons/fa';

interface SearchBarProps {
  search: string;
  setSearch: (search: string) => void;
  className?: string;
  inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'className' | 'type'>;
}

/*
 * A search bar component.
 *
 * @param {() => void} onChange - The function to execute on change.
 * @returns {JSX.Element} - The search bar component.
 */
export default function SearchBar({
  search,
  setSearch,
  className = '',
  inputProps,
}: SearchBarProps): JSX.Element {
  return (
    <div data-testid="searchbar" className={twMerge('relative flex h-10 w-full', className)}>
      <div className="absolute inset-y-0 top-2.5 left-0 flex items-center pl-3">
        <FaSearch data-testid="magnify" className="h-5 w-5 text-primary icon" />
      </div>
      <input
        type="search"
        className="pl-10"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        {...inputProps}
      />
    </div>
  );
}
