import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Button from './Button';

interface WhatsappButtonProps {
  text: string;
  className?: string;
}

/*
 * A button component with a share icon.
 *
 * @param {() => void} onClick - The function to execute on click.
 * @param {string} text - The text to display on the button.
 * @param {string} className - The class name for the button.
 * @returns {JSX.Element} - The button component.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function WhatsappButton({ text, className }: WhatsappButtonProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Button
      variant="primary"
      onClick={() => window.open(`https://api.whatsapp.com/send?text=${text}`)}>
      <FaWhatsapp className="h-5 w-5" />
      <span className="w-max text-secondary-50">{t('component.whatsapp')}</span>
    </Button>
  );
}
