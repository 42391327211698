import React from 'react';
import { twMerge } from 'tailwind-merge';
import HoverButton from '../buttons/HoverButton';
import QuestionCircleSvg from '../icons/QuestionCircleLight';

interface QuestionCircleProps {
  children: React.ReactNode;
  className?: string;
  arrowClassName?: string;
  buttonTextColor?: string;
  buttonBgColor?: string;
}

export default function QuestionCircle({
  children,
  className,
  arrowClassName,
  buttonTextColor,
  buttonBgColor,
}: QuestionCircleProps): JSX.Element {
  return (
    <HoverButton>
      <HoverButton.Button>
        <QuestionCircleSvg textColor={buttonTextColor} bgColor={buttonBgColor} />
      </HoverButton.Button>
      <HoverButton.Div
        className={twMerge(
          'bg-primary-900 rounded-[6px] text-secondary-50 text-sm px-3 py-2',
          className,
        )}>
        <div
          className={twMerge(
            'bg-primary-900 rounded-[3px] h-[15px] w-[15px] absolute',
            arrowClassName || 'hidden',
          )}
        />
        {children}
      </HoverButton.Div>
    </HoverButton>
  );
}
