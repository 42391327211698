import React from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { businessInvitePropertyTypeIcons, customFieldTypeIcons } from '../../../utils/iconUtils';
import { businessInvitePropertyTypeToString, customFieldTypeToString } from '../../../utils/stringUtils';
import { CustomFieldType } from '../../../services/model/inviteService.model';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';

type SelectExportedFieldsProps = {
  selectedFields: (string | number)[];
  setSelectedFields: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export default function SelectExportedFields(
  {
    selectedFields,
    setSelectedFields,
    className,
    ...props
  }: SelectExportedFieldsProps): JSX.Element {
  const business = useSelectedBusiness();

  const toggleSelected = (field: string | number) => {
    setSelectedFields((prev) => {
      if (prev.includes(field)) {
        return prev.filter((f) => f !== field);
      }
      return [...prev, field];
    });
  };

  if (!business) {
    return <></>;
  }

  const profileFields = [
    ...business.inviteFields?.mandatoryFields ?? [],
    ...business.inviteFields?.optionalFields ?? [],
  ];

  const customFields = [
    ...business.inviteFields?.customFields ?? [],
  ];

  return (
    <div className={twMerge('flex flex-col gap-2', className)} {...props}>
      {profileFields.map((field) => (
        <Field
          key={field}
          selected={selectedFields.includes(field)}
          toggleSelected={() => toggleSelected(field)}
          icon={businessInvitePropertyTypeIcons[field as keyof typeof businessInvitePropertyTypeIcons]}
          label={businessInvitePropertyTypeToString(field)}
        />
      ))}
      {customFields.map((field) => (
        field.customFieldId !== undefined ? (
          <CustomField
            key={field.customFieldId}
            selected={selectedFields.includes(field.customFieldId)}
            toggleSelected={() => toggleSelected(field.customFieldId as number)}
            label={customFieldTypeToString(field)}
            type={field.type}
          />
        ) : null
      ))}
    </div>
  );
}

type FieldProps = {
  selected: boolean;
  toggleSelected: () => void;
  icon: JSX.Element;
  label: string;
  children?: JSX.Element;
}

function Field({ selected, toggleSelected, icon, label, children }: FieldProps): JSX.Element {
  return (
    <div className="flex justify-between items-center">
      <div
        className={classNames(
          'flex items-center justify-between cursor-pointer w-full transition-all rounded-lg px-2 py-[3px] border border-secondary-200',
          {
            'bg-secondary-200': selected,
          },
        )}
        onClick={toggleSelected}
      >
        <div className="flex items-center w-full gap-2">
          <div className="bg-secondary text-secondary-50 rounded-[6px] flex items-center justify-center p-1">
            {icon}
          </div>
          <p className="text-sm whitespace-nowrap py-2 first-letter:uppercase">{label}</p>
          {children}
        </div>
        <input
          type="checkbox"
          checked={selected}
          className="rounded-full border-secondary-200 m-1"
        />
      </div>
    </div>
  );
}

type CustomFieldProps = {
  selected: boolean;
  toggleSelected: () => void;
  label: string;
  type: CustomFieldType;
  children?: JSX.Element;
}

function CustomField({ selected, toggleSelected, label, type, children }: CustomFieldProps): JSX.Element {
  return (
    <div className="flex justify-between items-center">
      <div
        className={classNames(
          'flex items-center justify-between cursor-pointer w-full transition-all rounded-lg px-2 py-[3px] border border-secondary-200',
          {
            'bg-secondary-200': selected,
          },
        )}
        onClick={toggleSelected}
      >
        <div className="flex items-center w-full gap-2">
          <div className="bg-secondary text-secondary-50 rounded-[6px] flex items-center justify-center p-1">
            {customFieldTypeIcons[type]}
          </div>
          <p className="text-sm whitespace-nowrap py-2 first-letter:uppercase">{label}</p>
          {children}
        </div>
        <input
          type="checkbox"
          checked={selected}
          className="rounded-full border-secondary-200 m-1"
        />
      </div>
    </div>
  );
}
